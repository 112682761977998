import React, { useState } from "react";
import Navbar from "./Navbar";
import QALinks from "./QALinks";

const MyLeads = () => {
  const initialData = [
    {
      uid: 1,
      agentName: "John Doe",
      leadId: "L123",
      dialerId: "D567",
      verifier: "Alice",
      recording: "https://example.com/recording1",
      greeting: "Yes",
      pitch: "Yes",
      age: "25",
      smoker: "No",
      health: "Good",
      beneficiary: "Spouse",
      account: "Active",
      plan: "Premium",
      transferDetails: "No",
      xferConsent: "Yes",
      rebuttals: "Handled well",
      numRebuttals: 1,
      refusals: 0,
      qaStatus: "Passed",
      comments: "Excellent lead handling",
    },
    {
      uid: 2,
      agentName: "Jane Smith",
      leadId: "L456",
      dialerId: "D890",
      verifier: "Bob",
      recording: "https://example.com/recording2",
      greeting: "No",
      pitch: "Yes",
      age: "30",
      smoker: "Yes",
      health: "Average",
      beneficiary: "Brother",
      account: "Inactive",
      plan: "Basic",
      transferDetails: "Yes",
      xferConsent: "No",
      rebuttals: "Needs improvement",
      numRebuttals: 3,
      refusals: 2,
      qaStatus: "Pending",
      comments: "Needs more training on rebuttals",
    },
  ];

  const [data] = useState(initialData);

  return (
    <div>
      <Navbar />
      <QALinks />
      <div className="w-11/12 max-w-5xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg mt-8">
        <h1 className="text-2xl text-center font-bold text-[#222e47] mb-6">
          My Leads
        </h1>
        <div className="overflow-auto mb-6">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-[#222e47] text-white text-sm font-semibold">
                <th className="py-2 px-4">UID</th>
                <th className="py-2 px-4">Agent Name</th>
                <th className="py-2 px-4">Lead ID</th>
                <th className="py-2 px-4">Dialer ID</th>
                <th className="py-2 px-4">Verifier</th>
                <th className="py-2 px-4">Recording</th>
                <th className="py-2 px-4">Greeting</th>
                <th className="py-2 px-4">Pitch</th>
                <th className="py-2 px-4">Age</th>
                <th className="py-2 px-4">Smoker</th>
                <th className="py-2 px-4">Health</th>
                <th className="py-2 px-4">Beneficiary</th>
                <th className="py-2 px-4">Account</th>
                <th className="py-2 px-4">Plan</th>
                <th className="py-2 px-4">Transfer Details</th>
                <th className="py-2 px-4">Xfer Consent</th>
                <th className="py-2 px-4">Rebuttals</th>
                <th className="py-2 px-4">No. of Rebuttals</th>
                <th className="py-2 px-4">No. of Refusals</th>
                <th className="py-2 px-4">QA Status</th>
                <th className="py-2 px-4">Comments</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <tr
                  key={row.uid}
                  className="hover:bg-gray-200 border-b border-gray-300"
                >
                  <td className="py-2 px-4 text-center">{row.uid}</td>
                  <td className="py-2 px-4 text-center">{row.agentName}</td>
                  <td className="py-2 px-4 text-center">{row.leadId}</td>
                  <td className="py-2 px-4 text-center">{row.dialerId}</td>
                  <td className="py-2 px-4 text-center">{row.verifier}</td>
                  <td className="py-2 px-4 text-center">
                    <a
                      href={row.recording}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      Recording Link
                    </a>
                  </td>
                  <td className="py-2 px-4 text-center">{row.greeting}</td>
                  <td className="py-2 px-4 text-center">{row.pitch}</td>
                  <td className="py-2 px-4 text-center">{row.age}</td>
                  <td className="py-2 px-4 text-center">{row.smoker}</td>
                  <td className="py-2 px-4 text-center">{row.health}</td>
                  <td className="py-2 px-4 text-center">{row.beneficiary}</td>
                  <td className="py-2 px-4 text-center">{row.account}</td>
                  <td className="py-2 px-4 text-center">{row.plan}</td>
                  <td className="py-2 px-4 text-center">
                    {row.transferDetails}
                  </td>
                  <td className="py-2 px-4 text-center">{row.xferConsent}</td>
                  <td className="py-2 px-4 text-center">{row.rebuttals}</td>
                  <td className="py-2 px-4 text-center">{row.numRebuttals}</td>
                  <td className="py-2 px-4 text-center">{row.refusals}</td>
                  <td className="py-2 px-4 text-center">{row.qaStatus}</td>
                  <td className="py-2 px-4 text-center">{row.comments}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MyLeads;
