import React, { useState } from 'react';
import QALinks from './QALinks';
import './QA.css';
import Navbar from './Navbar';

const QA = () => {
  const initialData = [
    { id: 1, leadId: 'L001', dialerId: 'D001', recording: 'Link 1', qaDate: '2023-10-01', qaStatus: 'Passed', comments: 'Good' },
    { id: 2, leadId: 'L002', dialerId: 'D002', recording: 'Link 2', qaDate: '2023-10-02', qaStatus: 'Failed', comments: 'Improvement needed' },
    { id: 3, leadId: 'L003', dialerId: 'D003', recording: 'Link 3', qaDate: '2023-10-03', qaStatus: 'Pending', comments: 'Awaiting review' },
    { id: 4, leadId: 'L003', dialerId: 'D003', recording: 'Link 3', qaDate: '2023-10-03', qaStatus: 'Pending', comments: 'Awaiting review' },
    { id: 5, leadId: 'L003', dialerId: 'D003', recording: 'Link 3', qaDate: '2023-10-03', qaStatus: 'Pending', comments: 'Awaiting review' },
    { id: 6, leadId: 'L003', dialerId: 'D003', recording: 'Link 3', qaDate: '2023-10-03', qaStatus: 'Pending', comments: 'Awaiting review' },
    { id: 7, leadId: 'L003', dialerId: 'D003', recording: 'Link 3', qaDate: '2023-10-03', qaStatus: 'Pending', comments: 'Awaiting review' },
    { id: 8, leadId: 'L003', dialerId: 'D003', recording: 'Link 3', qaDate: '2023-10-03', qaStatus: 'Pending', comments: 'Awaiting review' },
    { id: 9, leadId: 'L003', dialerId: 'D003', recording: 'Link 3', qaDate: '2023-10-03', qaStatus: 'Pending', comments: 'Awaiting review' },
    { id: 10, leadId: 'L003', dialerId: 'D003', recording: 'Link 3', qaDate: '2023-10-03', qaStatus: 'Pending', comments: 'Awaiting review' },
    { id: 11, leadId: 'L003', dialerId: 'D003', recording: 'Link 3', qaDate: '2023-10-03', qaStatus: 'Pending', comments: 'Awaiting review' },
    { id: 12, leadId: 'L003', dialerId: 'D003', recording: 'Link 3', qaDate: '2023-10-03', qaStatus: 'Pending', comments: 'Awaiting review' },
    { id: 13, leadId: 'L003', dialerId: 'D003', recording: 'Link 3', qaDate: '2023-10-03', qaStatus: 'Pending', comments: 'Awaiting review' },
    { id: 14, leadId: 'L003', dialerId: 'D003', recording: 'Link 3', qaDate: '2023-10-03', qaStatus: 'Pending', comments: 'Awaiting review' },
    { id: 15, leadId: 'L003', dialerId: 'D003', recording: 'Link 3', qaDate: '2023-10-03', qaStatus: 'Pending', comments: 'Awaiting review' },
    
    
    // Add more data as needed
  ];

  const [data, setData] = useState(initialData);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig) return;
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <>
    <Navbar/>
      <QALinks />
    <div className="qa-container">
      <h2>QA Records</h2>
      <table className="qa-table">
        <thead>
          <tr>
            <th onClick={() => requestSort('id')} className={getClassNamesFor('id')}>ID</th>
            <th onClick={() => requestSort('leadId')} className={getClassNamesFor('leadId')}>Lead ID</th>
            <th onClick={() => requestSort('dialerId')} className={getClassNamesFor('dialerId')}>Dialer ID</th>
            <th>Recording</th>
            <th onClick={() => requestSort('qaDate')} className={getClassNamesFor('qaDate')}>QA Date</th>
            <th onClick={() => requestSort('qaStatus')} className={getClassNamesFor('qaStatus')}>QA Status</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>{row.leadId}</td>
              <td>{row.dialerId}</td>
              <td><a href="#" target="_blank" rel="noopener noreferrer">{row.recording}</a></td>
              <td>{row.qaDate}</td>
              <td>{row.qaStatus}</td>
              <td>{row.comments}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    </>
  );
};

export default QA;
