import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaTowerBroadcast } from 'react-icons/fa6';

const TeamManagementLinks = () => {
  return (
    <div className="flex flex-wrap justify-center gap-4 mt-4">
      <NavLink
        to="/assignlead"
        className={({ isActive }) =>
          `flex items-center gap-2 px-6 py-3 rounded-full shadow-md transition ${
            isActive
              ? 'bg-[#222e47] text-white font-bold shadow-lg'
              : 'text-[#222e47] bg-white hover:bg-[#222e47] hover:text-white'
          }`
        }
      >
        <FaTowerBroadcast />
        <span>Leaderboard</span>
      </NavLink>
      <NavLink
        to="/teamreport"
        className={({ isActive }) =>
          `flex items-center gap-2 px-6 py-3 rounded-full shadow-md transition ${
            isActive
              ? 'bg-[#222e47] text-white font-bold shadow-lg'
              : 'text-[#222e47] bg-white hover:bg-[#222e47] hover:text-white'
          }`
        }
      >
        <FaTowerBroadcast />
        <span>Get Team Reports</span>
      </NavLink>
      <NavLink
        to="/assignagent"
        className={({ isActive }) =>
          `flex items-center gap-2 px-6 py-3 rounded-full shadow-md transition ${
            isActive
              ? 'bg-[#222e47] text-white font-bold shadow-lg'
              : 'text-[#222e47] bg-white hover:bg-[#222e47] hover:text-white'
          }`
        }
      >
        <FaTowerBroadcast />
        <span>Assign Agent</span>
      </NavLink>
      <NavLink
        to="/removeagent"
        className={({ isActive }) =>
          `flex items-center gap-2 px-6 py-3 rounded-full shadow-md transition ${
            isActive
              ? 'bg-[#222e47] text-white font-bold shadow-lg'
              : 'text-[#222e47] bg-white hover:bg-[#222e47] hover:text-white'
          }`
        }
      >
        <FaTowerBroadcast />
        <span>Remove Agent</span>
      </NavLink>
      <NavLink
        to="/agentreport"
        className={({ isActive }) =>
          `flex items-center gap-2 px-6 py-3 rounded-full shadow-md transition ${
            isActive
              ? 'bg-[#222e47] text-white font-bold shadow-lg'
              : 'text-[#222e47] bg-white hover:bg-[#222e47] hover:text-white'
          }`
        }
      >
        <FaTowerBroadcast />
        <span>Agent Report</span>
      </NavLink>
    </div>
  );
};

export default TeamManagementLinks;
