import React from "react";
import "./DialerNav.css";
import { Link } from "react-router-dom";
import Navbar from "../Pages/Navbar";
import videobg from "./videobg.mp4"

const DialerNav = () => {
  return (
    <div className="dialer-container">
      {/* Background Video */}
      <video autoPlay loop muted className="background-video">
        <source src={videobg} type="video/mp4" />
        {/* Your browser does not support the video tag. */}
      </video>

      {/* Content Overlay */}
      <div className="content-overlay">
        <Navbar />
        <div className="ddo">
          <div className="nav-div">
            <Link to="/dialer2/agent4" className="dLink">
              Dialer2
            </Link>
          </div>
          <div className="nav-div">
            <Link to="/dialer4/agent4" className="dLink">
              Dialer4
            </Link>
          </div>
          <div className="nav-div">
            <Link to="/dialer6/agent4" className="dLink">
              Dialer6
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialerNav;
