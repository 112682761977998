import React from "react";
import { Route, Routes } from "react-router-dom";
import AvatarVoice from "../TestVoice/AvatarVoice";
import VoiceBar4 from "../TestVoice/VoiceBar4";
import James from "../TestVoice/James";
import A105 from "../TestVoice/105";
import Helli from "../TestVoice/Helli";
import Henna from "../TestVoice/Henna";
import Cindy from "../TestVoice/Cindy";
import Selvey from "../TestVoice/Selvey";


const Dialer4 = () => {
  return (
      <div style={{ display: "flex", height: "100vh"}}>
        {/* Left Side */}
        <div style={{ flex: 1, borderRight: "1px solid #ddd", zIndex:4 }}>
          <iframe
            src="https://jsons4.dialerhosting.com/agc/vicidial.php?relogin=YES"
            title="Left Iframe"
            style={{ width: "100%", height: "100%", border: "none" }}
          />
        </div>

          <div style={{zIndex:3, width:100}}>
          <VoiceBar4 />
          </div>
        {/* Right Side */}
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          {/* Navbar */}

          {/* Content Area */}
          <div style={{ padding: "20px", overflow: "auto", flex: 1 }}>
            <Routes>
              <Route path="/agent4" element={<AvatarVoice />} />
              <Route path="/james" element={<James />} />
              <Route path="/105" element={<A105 />} />
              <Route path="/helli" element={<Helli />} />
              <Route path="/henna" element={<Henna />} />
              <Route path="/cindy" element={<Cindy />} />
              <Route path="/selvey" element={<Selvey />} />
            </Routes>
          </div>
        </div>
      </div>
  );
};

export default Dialer4;
