import React from 'react';
import { Link } from "react-router-dom";
import { FaTowerBroadcast } from "react-icons/fa6";

const AvatarLinks = () => {
  return (
    <div className="flex gap-6 justify-center mt-12">
      <Link to='/allxfers' className="flex items-center justify-center gap-2 text-[#222e47] bg-white rounded-full px-8 py-5 font-bold w-60 shadow-lg hover:bg-[#222e47] hover:text-white transition">
        <FaTowerBroadcast />
        <span>All Xfers</span>
      </Link>
      <Link to='/createlead' className="flex items-center justify-center gap-2 text-[#222e47] bg-white rounded-full px-8 py-5 font-bold w-60 shadow-lg hover:bg-[#222e47] hover:text-white transition">
        <FaTowerBroadcast />
        <span>Create Xfer</span>
      </Link>
      <Link className="flex items-center justify-center gap-2 text-[#222e47] bg-white rounded-full px-8 py-5 font-bold w-60 shadow-lg hover:bg-[#222e47] hover:text-white transition">
        <FaTowerBroadcast />
        <span>Leaderboard</span>
      </Link>
      <Link className="flex items-center justify-center gap-2 text-[#222e47] bg-white rounded-full px-8 py-5 font-bold w-60 shadow-lg hover:bg-[#222e47] hover:text-white transition">
        <FaTowerBroadcast />
        <span>Leaderboard Daily</span>
      </Link>
      <Link className="flex items-center justify-center gap-2 text-[#222e47] bg-white rounded-full px-8 py-5 font-bold w-64 shadow-lg hover:bg-[#222e47] hover:text-white transition">
        <FaTowerBroadcast />
        <span>Leaderboard Monthly</span>
      </Link>
    </div>
  );
};

export default AvatarLinks;
