// James.js

import React, { useRef, useState } from 'react';
import './James.css';

// Import all sound files from JamesVoice folder
import _10to15MINS from './JamesVoice/10_to_15_MINS.wav';
import AddCoverage from './JamesVoice/Add_Coverage.mp3';
import Affordability2 from './JamesVoice/Affordability 2.mp3';
import Affordability from './JamesVoice/Affordibilty.mp3';
import Age from './JamesVoice/Age.mp3';
import Age2 from './JamesVoice/Age_2.mp3';
import Age3 from './JamesVoice/Age_3.mp3';
import AgentName from './JamesVoice/Agent Name.mp3';
import AlreadyHavePlan from './JamesVoice/AlreadyHavePlan.mp3';
import AreYouThere from './JamesVoice/Are_you_there.mp3';
import BackGroundNoise from './JamesVoice/BackGround_Noise.mp3';
import Beneficiary from './JamesVoice/Beneficiary.mp3';
import Busy from './JamesVoice/Busy.mp3';
import CallAbout from './JamesVoice/Call_About.mp3';
import CallBack1 from './JamesVoice/CALL_BACK_1.wav';
import CalledBefore from './JamesVoice/Called_Before.mp3';
import CheckAndSave from './JamesVoice/Check_and_Save.mp3';
import CheckAndSave2 from './JamesVoice/Check_and_save2.mp3';
import CloserBusy from './JamesVoice/CLOSER_BUSY.mp3';
import CompanyName from './JamesVoice/Company Name.mp3';
import Cost from './JamesVoice/Cost.mp3';
import Cost2 from './JamesVoice/Cost_2.mp3';
import Cost3 from './JamesVoice/Cost_3.mp3';
import CvgForLoved from './JamesVoice/Cvg For Loved.mp3';
import Discount from './JamesVoice/Discount.mp3';
import Discount2 from './JamesVoice/Discount_2.mp3';
import DNC from './JamesVoice/DNC.mp3';
import Email from './JamesVoice/Email.mp3';
import GladToHear from './JamesVoice/Glad to Hear.mp3';
import Goodbye from './JamesVoice/Goodbye.mp3';
import Great from './JamesVoice/Great.mp3';
import Greetings from './JamesVoice/Greetings.mp3';
import Health1 from './JamesVoice/health 1.mp3';
import Health2 from './JamesVoice/Health 2.mp3';
import HealthCrossale from './JamesVoice/Health_Crosssale.mp3';
import HearMe from './JamesVoice/Hear_Me.mp3';
import Hello1 from './JamesVoice/Hello 1.mp3';
import Hello2 from './JamesVoice/Hello_2.mp3';
import HoldForMe from './JamesVoice/Hold_for_Me.mp3';
import IsThatOk from './JamesVoice/IsThatOk.mp3';
import JustASecond from './JamesVoice/Just_a_second.mp3';
import Laugh from './JamesVoice/Laugh.mp3';
import Location from './JamesVoice/Location.mp3';
import LovedOneAge from './JamesVoice/Loved_One_Age.mp3';
import LovedOnceAge from './JamesVoice/Loved_once_age (3rd Col).mp3';
import MedQ from './JamesVoice/Med Q.mp3';
import Name from './JamesVoice/NAME.mp3';
import No from './JamesVoice/No.mp3';
import NoMaam from './JamesVoice/No Maam.mp3';
import NoProblem from './JamesVoice/No_Problem.mp3';
import NoSir from './JamesVoice/No_Sir.mp3';
import NotInterested1 from './JamesVoice/Not_intrested1.mp3';
import NotInterested2 from './JamesVoice/NOT_INTERESTED_2.mp3';
import Okay from './JamesVoice/Okay.mp3';
import Pitch1 from './JamesVoice/Ptich_1.mp3';
import Pitch2 from './JamesVoice/Pitch 2.mp3';
import Plan from './JamesVoice/PLAN.wav';
import Real1 from './JamesVoice/Real_1.mp3';
import Real2 from './JamesVoice/Real_2.mp3';
import Repeat from './JamesVoice/Repeat.mp3';
import Rescue from './JamesVoice/Rescue.mp3';
import Smoker from './JamesVoice/Smoker.mp3';
import Smoker2 from './JamesVoice/Smoker2.mp3';
import SorryToHear from './JamesVoice/Sorry_to_hear.mp3';
import State from './JamesVoice/State.mp3';
import ThanksAsking from './JamesVoice/Thanks_asking.mp3';
import Thankyou01 from './JamesVoice/Thankyou_01.mp3';
import Thankyou02 from './JamesVoice/Thankyou_02.mp3';
import Transfer from './JamesVoice/Tranfer.mp3';
import TransferHold from './JamesVoice/Transfer Hold.mp3';
import TransferIntro from './JamesVoice/Transfer Intro.mp3';
import TransferDetail from './JamesVoice/Transfer_Detail.mp3';
import VerifierBusy from './JamesVoice/Verifier_Busy.mp3';
import Weather from './JamesVoice/Weather.mp3';
import Yes from './JamesVoice/Yes.mp3';
import YesMaam from './JamesVoice/Yes_Maam.mp3';
import YesSir from './JamesVoice/Yes_Sir.mp3';
import trsnfer2 from './JamesVoice/Transfer_2.mp3'
import VoiceBar from './VoiceBar';

const James = () => {
  const [isPaused, setIsPaused] = useState(false);
  const audioRef = useRef(new Audio());

  const playVoice = (audioFile) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    audioRef.current = new Audio(audioFile);
    audioRef.current.play();
    setIsPaused(false);
  };

  const pauseVoice = () => {
    if (audioRef.current && !isPaused) {
      audioRef.current.pause();
      setIsPaused(true);
    }
  };

  const resumeVoice = () => {
    if (audioRef.current && isPaused) {
      audioRef.current.play();
      setIsPaused(false);
    }
  };

  const stopVoice = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  // Define button data based on the screenshot layout
  const buttonDataColumns = [
    [
      { label: 'Hello 1', color: 'green', audioSrc: Hello1 },
      { label: 'Hello 2', color: 'green', audioSrc: Hello2 },
      { label: 'Greetings', color: 'green', audioSrc: Greetings },
      { label: 'Pitch 1', color: 'green', audioSrc: Pitch1 },
      { label: 'Pitch 2', color: 'green', audioSrc: Pitch2 },
      { label: 'Age', color: 'green', audioSrc: Age },
      { label: 'Age 2', color: 'green', audioSrc: Age2 },
      { label: 'Age 3', color: 'green', audioSrc: Age3 },
      { label: 'Plan', color: 'green', audioSrc: Plan },
      { label: 'Beneficiary', color: 'green', audioSrc: Beneficiary },
      { label: 'Health 1', color: 'green', audioSrc: Health1 },
      { label: 'Health 2', color: 'green', audioSrc: Health2 },
      { label: 'Transfer Detail', color: 'green', audioSrc: TransferDetail },
      { label: 'Transfer Hold', color: 'green', audioSrc: TransferHold },
      { label: 'Just a Second', color: 'green', audioSrc: JustASecond },
      { label: 'Yes Sir', color: 'green', audioSrc: YesSir },
      { label: '10 to 15 MINS', color: 'green', audioSrc: _10to15MINS },
      { label: 'Weather', color: 'green', audioSrc: Weather },
      { label: 'Loved one Age', color: 'green', audioSrc: LovedOneAge },
      { label: 'Health Crossale', color: 'green', audioSrc: HealthCrossale },
    ],
    [
      { label: 'Thanks Asking', color: '#222e47', audioSrc: ThanksAsking },
      { label: 'Glad to hear', color: '#222e47', audioSrc: GladToHear },
      { label: 'Sorry to hear', color: '#222e47', audioSrc: SorryToHear },
      { label: 'Great', color: '#222e47', audioSrc: Great },
      { label: 'Yes', color: '#222e47', audioSrc: Yes },
      { label: 'No', color: '#222e47', audioSrc: No },
      { label: 'Real 1', color: '#222e47', audioSrc: Real1 },
      { label: 'Real 2', color: '#222e47', audioSrc: Real2 },
      { label: 'AlreadyHavePlan', color: '#222e47', audioSrc: AlreadyHavePlan },
      { label: 'Rescue', color: '#222e47', audioSrc: Rescue },
      { label: 'Laugh', color: '#222e47', audioSrc: Laugh },
      { label: 'Busy', color: '#222e47', audioSrc: Busy },
      { label: 'DNC', color: 'red', audioSrc: DNC },
      { label: 'Transfer', color: '#222e47', audioSrc: Transfer },
      { label: 'Goodbye', color: '#222e47', audioSrc: Goodbye },
      { label: 'Yes Ma\'am', color: '#222e47', audioSrc: YesMaam },
      { label: 'Discount 2', color: '#222e47', audioSrc: Discount2 },
      { label: 'Verifier Busy', color: '#222e47', audioSrc: VerifierBusy },
      { label: 'Transfer Intro', color: 'red', audioSrc: TransferIntro },
      { label: 'Med Q', color: 'rgb(255, 0, 89)', audioSrc: MedQ },
    ],
    [
      { label: 'Cost', color: 'rgb(0, 134, 216)', audioSrc: Cost },
      { label: 'Cost 2', color: 'rgb(0, 134, 216)', audioSrc: Cost2 },
      { label: 'Background Noise', color: 'red', audioSrc: BackGroundNoise },
      { label: 'Not Interested 1', color: 'rgb(0, 134, 216)', audioSrc: NotInterested1 },
      { label: 'Not Interested 2', color: 'rgb(0, 134, 216)', audioSrc: NotInterested2 },
      { label: 'Affordability', color: 'rgb(0, 134, 216)', audioSrc: Affordability },
      { label: 'Call About', color: 'rgb(0, 134, 216)', audioSrc: CallAbout },
      { label: 'Are you there', color: 'rgb(0, 134, 216)', audioSrc: AreYouThere },
      { label: 'Add coverage', color: 'rgb(0, 134, 216)', audioSrc: AddCoverage },
      { label: 'Repeat', color: 'rgb(0, 134, 216)', audioSrc: Repeat },
      { label: 'Smoker', color: 'rgb(0, 134, 216)', audioSrc: Smoker },
      { label: 'Smoker 2', color: 'rgb(0, 134, 216)', audioSrc: Smoker2 },
      { label: 'Cvg for Loved', color: 'rgb(0, 134, 216)', audioSrc: CvgForLoved },
      { label: 'Hold for me', color: 'rgb(0, 134, 216)', audioSrc: HoldForMe },
      { label: 'Call Back 1', color: 'green', audioSrc: CallBack1 },
      { label: 'No Sir', color: 'rgb(0, 134, 216)', audioSrc: NoSir },
      { label: 'Discount', color: 'rgb(0, 134, 216)', audioSrc: Discount },
      { label: 'Thankyou 01', color: 'rgb(0, 134, 216)', audioSrc: Thankyou01 },
      { label: 'Not Interested 2', color: 'rgb(0, 134, 216)', audioSrc: NotInterested2 },
      { label: 'Loved one', color: 'green', audioSrc: LovedOneAge },
    ],
    [
      { label: 'Location', color: 'rgb(255, 0, 89)', audioSrc: Location },
      { label: 'Agent Name', color: 'rgb(255, 0, 89)', audioSrc: AgentName },
      { label: 'Company Name', color: 'rgb(255, 0, 89)', audioSrc: CompanyName },
      { label: 'Email', color: 'rgb(255, 0, 89)', audioSrc: Email },
      { label: 'State', color: 'rgb(255, 0, 89)', audioSrc: State },
      { label: 'Hear Me', color: 'rgb(255, 0, 89)', audioSrc: HearMe },
      { label: 'Okay', color: 'rgb(255, 0, 89)', audioSrc: Okay },
      { label: 'No Problem', color: 'rgb(255, 0, 89)', audioSrc: NoProblem },
      { label: 'Is that OK', color: 'rgb(255, 0, 89)', audioSrc: IsThatOk },
      { label: 'Loved One Age', color: 'rgb(255, 0, 89)', audioSrc: LovedOnceAge },
      { label: 'Closer Busy', color: 'rgb(255, 0, 89)', audioSrc: CloserBusy },
      { label: 'Check & Save', color: 'rgb(255, 0, 89)', audioSrc: CheckAndSave },
      { label: 'Check & Save 2', color: 'rgb(255, 0, 89)', audioSrc: CheckAndSave2 },
      { label: 'Transfer 2', color: 'rgb(255, 0, 89)', audioSrc: trsnfer2 },
      { label: 'Called Before', color: 'rgb(255, 0, 89)', audioSrc: CalledBefore },
      { label: 'No Maam', color: 'rgb(255, 0, 89)', audioSrc: NoMaam },
      { label: 'Cost 3', color: 'rgb(255, 0, 89)', audioSrc: Cost3 },
      { label: 'Thankyou 02', color: 'rgb(255, 0, 89)', audioSrc: Thankyou02 },
      { label: 'Affordability 2', color: 'rgb(255, 0, 89)', audioSrc: Affordability2 },
      { label: 'Name', color: 'green', audioSrc: Name },
    ],
  ];

  return (
    <div className="outerdiv" >
      {/* <div style={{ zIndex: 2 }}>
        <VoiceBar/>
      </div> */}
      <div className="avatar-voice-container">
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
          <button className="stop-button" onClick={resumeVoice} style={{ backgroundColor: 'green' }}>
            RESUME
          </button>
          <button className="stop-button" onClick={pauseVoice}>
            PAUSE
          </button>
          <button className="stop-button" onClick={stopVoice} style={{ backgroundColor: 'red' }}>
            STOP
          </button>
        </div>

        <div className="button-grid">
          {buttonDataColumns.map((column, colIndex) => (
            <div key={colIndex} className="button-column">
              {column.map((button, index) => (
                <button
                  key={index}
                  className="voice-button"
                  style={{ backgroundColor: button.color ,fontSize:'small'}}
                  onClick={() => playVoice(button.audioSrc)}
                >
                  {button.label}
                </button>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default James;
