import React, { useState } from 'react';
import Navbar from './Navbar';
import AvatarLinks from './AvatarLinks';

const AllXfers = () => {
  const data = [
    { id: 1, agentName: "John Doe", leadId: "L123", dialerId: "D567", recordingLink: "https://example.com/recording1", isGreetings: "", isAge: "", isSmoker: "", isHealth: "", isBeneficiary: "", isAccount: "", isPlan: "", isTransferDetails: "", isXferDetails: "", isXferConsent: "", rebuttals: " ", qaComments: " ", qaStatus: "Pending", useOfRebuttals: "" },
    { id: 2, agentName: "Jane Smith", leadId: "L456", dialerId: "D890", recordingLink: "https://example.com/recording2", isGreetings: "", isAge: "", isSmoker: "", isHealth: "", isBeneficiary: "", isAccount: "", isPlan: "", isTransferDetails: "", isXferDetails: "", isXferConsent: "", rebuttals: " ", qaComments: "", qaStatus: "Pending", useOfRebuttals: "" },
    { id: 3, agentName: "Alice Johnson", leadId: "L789", dialerId: "D123", recordingLink: "https://example.com/recording3", isGreetings: "", isAge: "", isSmoker: "", isHealth: "", isBeneficiary: "", isAccount: "", isPlan: "", isTransferDetails: "", isXferDetails: "", isXferConsent: "", rebuttals: " ", qaComments: "", qaStatus: "Pending", useOfRebuttals: "" },
    { id: 4, agentName: "Bob Brown", leadId: "L012", dialerId: "D345", recordingLink: "https://example.com/recording4", isGreetings: "", isAge: "", isSmoker: "", isHealth: "", isBeneficiary: "", isAccount: "", isPlan: "", isTransferDetails: "", isXferDetails: "", isXferConsent: "", rebuttals: "", qaComments: "", qaStatus: "Pending", useOfRebuttals: "" },
    { id: 5, agentName: "Bob Brown", leadId: "L012", dialerId: "D345", recordingLink: "https://example.com/recording4", isGreetings: "", isAge: "", isSmoker: "", isHealth: "", isBeneficiary: "", isAccount: "", isPlan: "", isTransferDetails: "", isXferDetails: "", isXferConsent: "", rebuttals: "", qaComments: "", qaStatus: "Pending", useOfRebuttals: "" },
    { id: 6, agentName: "Bob Brown", leadId: "L012", dialerId: "D345", recordingLink: "https://example.com/recording4", isGreetings: "", isAge: "", isSmoker: "", isHealth: "", isBeneficiary: "", isAccount: "", isPlan: "", isTransferDetails: "", isXferDetails: "", isXferConsent: "", rebuttals: "", qaComments: "", qaStatus: "Pending", useOfRebuttals: "" },
    { id: 7, agentName: "Bob Brown", leadId: "L012", dialerId: "D345", recordingLink: "https://example.com/recording4", isGreetings: "", isAge: "", isSmoker: "", isHealth: "", isBeneficiary: "", isAccount: "", isPlan: "", isTransferDetails: "", isXferDetails: "", isXferConsent: "", rebuttals: "", qaComments: "", qaStatus: "Pending", useOfRebuttals: "" },
    { id: 8, agentName: "Bob Brown", leadId: "L012", dialerId: "D345", recordingLink: "https://example.com/recording4", isGreetings: "", isAge: "", isSmoker: "", isHealth: "", isBeneficiary: "", isAccount: "", isPlan: "", isTransferDetails: "", isXferDetails: "", isXferConsent: "", rebuttals: "", qaComments: "", qaStatus: "Pending", useOfRebuttals: "" },
    
    // Add more rows as needed
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 4;
  const totalPages = Math.ceil(data.length / rowsPerPage);
  const currentRows = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div>
      <Navbar />
      <AvatarLinks />
      <div className="max-w-5xl mx-auto mt-6 overflow-x-auto rounded-lg shadow-md">
        <table className="w-full min-w-[1200px] border-collapse bg-white">
          <thead>
            <tr className="bg-[#222e47] text-white text-sm font-bold uppercase">
              <th className="py-3 px-5 text-left">ID</th>
              <th className="py-3 px-5 text-left">Agent Name</th>
              <th className="py-3 px-5 text-left">Lead ID</th>
              <th className="py-3 px-5 text-left">Dialer ID</th>
              <th className="py-3 px-5 text-left">Recording Link</th>
              <th className="py-3 px-5 text-left">Is Greetings</th>
              <th className="py-3 px-5 text-left">Is Age</th>
              <th className="py-3 px-5 text-left">Is Smoker</th>
              <th className="py-3 px-5 text-left">Is Health</th>
              <th className="py-3 px-5 text-left">Is Beneficiary</th>
              <th className="py-3 px-5 text-left">Is Account</th>
              <th className="py-3 px-5 text-left">Is Plan</th>
              <th className="py-3 px-5 text-left">Is Transfer Details</th>
              <th className="py-3 px-5 text-left">Is Xfer Details</th>
              <th className="py-3 px-5 text-left">Is Xfer Consent</th>
              <th className="py-3 px-5 text-left">Rebuttals</th>
              <th className="py-3 px-5 text-left">QA Comments</th>
              <th className="py-3 px-5 text-left">QA Status</th>
              <th className="py-3 px-5 text-left">Use of Rebuttals</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((row) => (
              <tr
                key={row.id}
                className="even:bg-gray-100 hover:bg-gray-200 text-sm"
              >
                <td className="py-3 px-5">{row.id}</td>
                <td className="py-3 px-5">{row.agentName}</td>
                <td className="py-3 px-5">{row.leadId}</td>
                <td className="py-3 px-5">{row.dialerId}</td>
                <td className="py-3 px-5">
                  <a
                    href={row.recordingLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    Link
                  </a>
                </td>
                <td className="py-3 px-5">{row.isGreetings}</td>
                <td className="py-3 px-5">{row.isAge}</td>
                <td className="py-3 px-5">{row.isSmoker}</td>
                <td className="py-3 px-5">{row.isHealth}</td>
                <td className="py-3 px-5">{row.isBeneficiary}</td>
                <td className="py-3 px-5">{row.isAccount}</td>
                <td className="py-3 px-5">{row.isPlan}</td>
                <td className="py-3 px-5">{row.isTransferDetails}</td>
                <td className="py-3 px-5">{row.isXferDetails}</td>
                <td className="py-3 px-5">{row.isXferConsent}</td>
                <td className="py-3 px-5">{row.rebuttals}</td>
                <td className="py-3 px-5">{row.qaComments}</td>
                <td className="py-3 px-5">{row.qaStatus}</td>
                <td className="py-3 px-5">{row.useOfRebuttals}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center items-center mt-6 space-x-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={`px-4 py-2 text-white font-medium rounded w-10 ${
            currentPage === 1
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-[#222e47] hover:bg-[#1b2438]'
          }`}
        >
          {"<"}
        </button>
        <span className="text-sm font-medium w-48 text-center">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`px-4 py-2 text-white font-medium rounded w-10 ${
            currentPage === totalPages
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-[#222e47] hover:bg-[#1b2438]'
          }`}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default AllXfers;
