import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import TeamManagementLinks from "./TeamManagementLinks";

const TeamReport = () => {
  const [data, setData] = useState([]);
  const startDate = new Date("2024-10-14");
  const endDate = new Date("2024-11-15");

  const agents = [
    "Sulaiman Safi",
    "Muhammad Saad",
    "Jamal Khan",
    "Awais Ghouri",
    "Tayyab Ali",
    "Tahir Ali",
    "Asad Ullah",
    "Ammar Kyani",
    "Kashan Ur Rehman",
    "Khawaja Soban",
    "Muneeb Ahmed",
  ];

  const generateDates = (start, end) => {
    let dates = [];
    let currentDate = new Date(start);
    while (currentDate <= end) {
      dates.push(currentDate.toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const generateDummyData = () => {
    return agents.map((agent) => {
      let agentData = { agent };
      generateDates(startDate, endDate).forEach((date) => {
        agentData[date] = Math.floor(Math.random() * 25);
      });
      agentData["Total"] = Object.values(agentData).reduce(
        (sum, value) => (typeof value === "number" ? sum + value : sum),
        0
      );
      return agentData;
    });
  };

  const calculateDailyTotals = () => {
    let totals = {};
    generateDates(startDate, endDate).forEach((date) => {
      totals[date] = data.reduce((sum, agentData) => sum + agentData[date], 0);
    });
    totals["Total"] = data.reduce(
      (sum, agentData) => sum + agentData["Total"],
      0
    );
    return totals;
  };

  useEffect(() => {
    setData(generateDummyData());
  }, []);

  const dates = generateDates(startDate, endDate);
  const dailyTotals = calculateDailyTotals();

  return (
    <>
      <Navbar />
      <TeamManagementLinks />
      <div className="p-6 font-sans text-gray-800 max-w-full">
        <div className="flex flex-wrap gap-4 items-center mb-6">
          <label className="flex flex-col gap-1 min-w-[150px]">
            <span className="text-sm font-medium">Select Team:</span>
            <select className="p-2 rounded border border-gray-300 text-sm">
              <option>All Teams</option>
            </select>
          </label>

          <label className="flex flex-col gap-1 min-w-[150px]">
            <span className="text-sm font-medium">Select QA Status:</span>
            <select className="p-2 rounded border border-gray-300 text-sm">
              <option>Total</option>
            </select>
          </label>

          <label className="flex flex-col gap-1 min-w-[150px]">
            <span className="text-sm font-medium">Start Date:</span>
            <input
              type="date"
              defaultValue="2024-10-14"
              className="p-2 rounded border border-gray-300 text-sm"
            />
          </label>

          <label className="flex flex-col gap-1 min-w-[150px]">
            <span className="text-sm font-medium">End Date:</span>
            <input
              type="date"
              defaultValue="2024-11-15"
              className="p-2 rounded border border-gray-300 text-sm"
            />
          </label>

          <button className="p-3 bg-[#222e47] text-white rounded font-bold hover:bg-[#1b2438] transition">
            Filter
          </button>
          <button className="p-3 bg-[#378d3c] text-white rounded font-bold hover:bg-[#2f6d2e] transition">
            Export to Excel
          </button>
        </div>

        <div className="overflow-auto max-h-[400px] border border-gray-300 rounded-md mt-4">
          <table className="w-full border-collapse text-sm">
            <thead>
              <tr className="bg-[#222e47] text-white sticky top-0 z-10">
                <th className="py-2 px-4">TEAM</th>
                <th className="py-2 px-4">AGENT</th>
                {dates.map((date) => (
                  <th key={date} className="py-2 px-4">
                    {new Date(date).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    })}
                  </th>
                ))}
                <th className="py-2 px-4">Total</th>
              </tr>
              <tr className="bg-gray-100">
                <th colSpan="2" className="py-2 px-4 font-medium">
                  DAILY TOTALS
                </th>
                {dates.map((date) => (
                  <th key={date} className="py-2 px-4">
                    {dailyTotals[date]}
                  </th>
                ))}
                <th className="py-2 px-4 font-medium">{dailyTotals["Total"]}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((agentData, index) => (
                <tr
                  key={index}
                  className="even:bg-gray-50 hover:bg-gray-100 border-b border-gray-300"
                >
                  <td className="py-2 px-4 text-center">ÜñíţÖÞþÅßĻÈ</td>
                  <td className="py-2 px-4 text-center">{agentData.agent}</td>
                  {dates.map((date) => (
                    <td key={date} className="py-2 px-4 text-center">
                      {agentData[date]}
                    </td>
                  ))}
                  <td className="py-2 px-4 text-center font-medium">
                    {agentData["Total"]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TeamReport;
