import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Pages/Navbar';
import Dashboard from './Pages/Dashboard';
import Leaderboard from './Pages/Leaderboard';
import LeadForm from './Pages/LeadForm';
import Avatar from './Pages/Avatar';
import AllXfers from './Pages/AllXfers';
import CreateLead from './Pages/CreateLead';
import QA from './Pages/QA';
import AssignLead from './Pages/AssignLead';
import MyLeads from './Pages/MyLeads';
import AgentStats from './Pages/AgentStats';
import ReQALead from './Pages/ReQALead';
import UploadRecording from './Pages/UploadRecording';
import ShrinkageLead from './Pages/ShrinkageLead';
import SearchAgentStats from './Pages/SearchAgentStats';
import TeamsManagement from './Pages/TeamsManagement';
import TeamReport from './Pages/TeamReport';
import CreateTeam from './Pages/CreateTeam';
import AssignAgent from './Pages/AssignAgent';
import RemoveAgent from './Pages/RemoveAgent';
import AgentsReport from './Pages/AgentsReport';
import VoiceButton from './TestVoice/VoiceButton';
import AvatarVoice from './TestVoice/AvatarVoice';
import Split from './SplitscreenTesting/split';
import James from './TestVoice/James';
import Cindy from './TestVoice/Cindy';
import TCPACheck from './TCPAChk/TCPACheck';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Selvey from './TestVoice/Selvey';
import AuthScreen from './Auth/AuthScreen';
import ManageUser from './Pages/ManageUser';
import A105 from './TestVoice/105';
import Helli from './TestVoice/Helli';
import Henna from './TestVoice/Henna';
import VoiceBar from './TestVoice/VoiceBar';
import Dialer4 from './SplitscreenTesting/Dialer4';
import Dialer6 from './SplitscreenTesting/Dialer6';
import DialerNav from './SplitscreenTesting/DialerNav';
import Permissions from './Pages/Permissions';
import Roles from './Pages/Roles';
import Termination from './Pages/Termination';


function App() {
  return (
    <Router>
      <div className="app">
        {/* <Navbar/> */}
        <div className="content">
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/teammngt" element={<TeamsManagement />} />
            {/* <Route path="/" element={<Cindy />} /> */}
            {/* <Route path="/" element={<Login />} /> */}
            <Route path="/manageuser" element={<ManageUser />} />
            {/* <Route path="/" element={<AuthScreen />} />  */}
            <Route path="/selveyN" element={<Selvey />} />
            <Route path="/hennaN" element={<Henna />} />
            <Route path="/agent4N" element={<AvatarVoice />} />
            <Route path="/cindyN" element={<Cindy />} />
            <Route path="/105N" element={<A105 />} />
            <Route path="/helliN" element={<Helli />} />
            <Route path="/jamesN" element={<James />} />
            {/* <Route path="/henna" element={<Henna />} /> */}
            {/* <Route path="/" element={<VoiceBar />} /> */}
            <Route path="/tcpa" element={<TCPACheck />} />
            {/* <Route path="/" element={<Roles />} /> */}
            <Route path="/dialer2/*" element={<Split />} />
            <Route path="/dialer4/*" element={<Dialer4 />} />
            <Route path="/dialer6/*" element={<Dialer6 />} />
            <Route path="/permissions*" element={<Permissions />} />
            {/* <Route path="/leadform" element={<LeadForm />} /> */}
            <Route path="/leaderboard" element={<Leaderboard />} />
            {/* <Route path="/" element={<Avatar />} /> */}
            <Route path="/" element={<DialerNav />} />
            {/* <Route path="/" element={<Permissions />} /> */}
            {/* <Route path="/" element={<UploadRecording />} /> */}
            {/* <Route path="/" element={<ShrinkageLead />} /> */}
            {/* <Route path="/" element={<SearchAgentStats />} /> */}
            <Route path="/allxfers" element={<AllXfers />} />
            <Route path="/createlead" element={<CreateLead />} />
            <Route path="/assignlead" element={<AssignLead />} />
            <Route path="/mylead" element={<MyLeads />} />
            <Route path="/agentstats" element={<AgentStats />} />
            <Route path="/reqalead" element={<ReQALead />} />
            <Route path="/qa" element={<QA />} />
            <Route path="/teamreport" element={<TeamReport />} />
            <Route path="/createteam" element={<CreateTeam />} />
            <Route path="/assignagent" element={<AssignAgent />} />
            <Route path="/removeagent" element={<RemoveAgent />} />
            <Route path="/agentreport" element={<AgentsReport />} />
            <Route path="/register" element={<Register />} />
            <Route path="/termination" element={<Termination />} />



          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;