// import React from "react";
// import "./VoiceBar.css";
// import { Link, useLocation } from "react-router-dom";

// const VoiceBar = () => {
//   const location = useLocation();

//   return (
//     <nav className="maindivbar">
//       <Link
//         to="/agent4"
//         className={`linkbar ${location.pathname === "/agent4" ? "active" : ""}`}
//       >
//         Agent 4
//       </Link>
//       <Link
//         to="/james"
//         className={`linkbar ${location.pathname === "/james" ? "active" : ""}`}
//       >
//         James
//       </Link>
//       <Link
//         to="/105"
//         className={`linkbar ${location.pathname === "/105" ? "active" : ""}`}
//       >
//         David Miler
//       </Link>
//       <Link
//         to="/helli"
//         className={`linkbar ${location.pathname === "/helli" ? "active" : ""}`}
//       >
//         Helli Wilson
//       </Link>
//       <Link
//         to="/henna"
//         className={`linkbar ${location.pathname === "/henna" ? "active" : ""}`}
//       >
//         Henna Walker
//       </Link>
//       <Link
//         to="/cindy"
//         className={`linkbar ${location.pathname === "/cindy" ? "active" : ""}`}
//       >
//         Cindy Jones
//       </Link>
//       {/* <Link to='/' className={`linkbar ${location.pathname === '/' ? 'active' : ''}`}>Hazel Moss</Link> */}
//     </nav>
//   );
// };

// export default VoiceBar;














import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./VoiceBar.css";

const VoiceBar = () => {
  const location = useLocation();

  return (
    <nav className="maindivbar">
      <Link
        to="/dialer2/agent4"
        className={`linkbar ${location.pathname === "/dialer2/agent4" ? "active" : ""}`}
      >
        Agent 4
      </Link>
      <Link
        to="/dialer2/james"
        className={`linkbar ${location.pathname === "/dialer2/james" ? "active" : ""}`}
      >
        James
      </Link>
      <Link
        to="/dialer2/105"
        className={`linkbar ${location.pathname === "/dialer2/105" ? "active" : ""}`}
      >
        David Miller
      </Link>
      <Link
        to="/dialer2/helli"
        className={`linkbar ${location.pathname === "/dialer2/helli" ? "active" : ""}`}
      >
        Helli Wilson
      </Link>
      <Link
        to="/dialer2/henna"
        className={`linkbar ${location.pathname === "/dialer2/henna" ? "active" : ""}`}
      >
        Henna Walker
      </Link>
      <Link
        to="/dialer2/cindy"
        className={`linkbar ${location.pathname === "/dialer2/cindy" ? "active" : ""}`}
      >
        Cindy Jones
      </Link>
      <Link
        to="/dialer2/selvey"
        className={`linkbar ${location.pathname === "/dialer2/selvey" ? "active" : ""}`}
      >
        Selvey
      </Link>
    </nav>
  );
};

export default VoiceBar;
