// Cindy.js

import React, { useRef, useState } from "react";
import "./Cindy.css";

// Import all sound files from CindyVoice folder
import AddCoverage from "./CindyVoice/Add_coverage.mp3";
import Affordability2 from "./CindyVoice/Affordability 2.mp3";
import Affordability from "./CindyVoice/Affordability.mp3";
import Age from "./CindyVoice/Age.opus";
import Age2 from "./CindyVoice/Age_2.opus";
import AgentName from "./CindyVoice/Agent_NAME.mp3";
import AlreadyHavePlan from "./CindyVoice/Already_have_plan.opus";
import AreYouThere from "./CindyVoice/Are_you_there.mp3";
import BackgroundNoise from "./CindyVoice/Background_Noise.mp3";
import Beneficiary from "./CindyVoice/Beneficiary.opus";
import Benefit from "./CindyVoice/Benefit.mp3";
import Busy from "./CindyVoice/Busy.mp3";
import CallAbout from "./CindyVoice/Call_about.mp3";
import CallBack from "./CindyVoice/Call_back.mp3";
import CalledBefore from "./CindyVoice/Called_before.mp3";
import CheckSaveAccount from "./CindyVoice/Check save Account.mp3";
import CompanyName from "./CindyVoice/Company_name.mp3";
import Cost from "./CindyVoice/Cost.mp3";
import Cost2 from "./CindyVoice/Cost-2.mp3";
import Cost3 from "./CindyVoice/Cost-3.mp3";
import CustomersName from "./CindyVoice/Customers Name.mp3";
import CvgForLoved1 from "./CindyVoice/Cvg_for_loved 1.mp3";
import DNC from "./CindyVoice/DNC.mp3";
import Email from "./CindyVoice/Email.mp3";
import GladToHear from "./CindyVoice/Glad_to_hear.mp3";
import Great from "./CindyVoice/Great.mp3";
import Greetings from "./CindyVoice/Greetings.opus";
import GreetingsNew from "./CindyVoice/Greetings-New.mp3";
import Health1 from "./CindyVoice/Health 1.mp3";
import HearMe from "./CindyVoice/Hear_me.mp3";
import Hello1 from "./CindyVoice/Hello 1.mp3";
import HoldForMe from "./CindyVoice/Hold for me.mp3";
import IsThatOk from "./CindyVoice/Is_that_ok.mp3";
import Laugh from "./CindyVoice/laugh.opus";
import Location from "./CindyVoice/Location.mp3";
import No from "./CindyVoice/No.mp3";
import NoProblem from "./CindyVoice/No_problem.mp3";
import NotInterested1 from "./CindyVoice/Not_interested-1.mp3";
import NotInterested2 from "./CindyVoice/Not_interested-2.mp3";
import NotInterested3 from "./CindyVoice/Not_interested-3.mp3";
import Okay from "./CindyVoice/Okay.mp3";
import Pitch1 from "./CindyVoice/Pitch 1.mp3";
import Pitch2 from "./CindyVoice/Pitch-2.mp3";
import Pitch3 from "./CindyVoice/Pitch-3.mp3";
import Plan from "./CindyVoice/Plan.mp3";
import Plan2 from "./CindyVoice/Plan-2.mp3";
import Real1 from "./CindyVoice/Real1.mp3";
import Real2 from "./CindyVoice/Real-2.mp3";
import Repeat from "./CindyVoice/Repeat.mp3";
import Rescue from "./CindyVoice/rescue.mp3";
import Smoker from "./CindyVoice/Smoker.mp3";
import Smoker2 from "./CindyVoice/Smoker-2.mp3";
import SorryToHear from "./CindyVoice/Sorry_to_hear.mp3";
import State from "./CindyVoice/State.mp3";
import Thanks4Asking from "./CindyVoice/Thanks_4 asking.mp3";
import TransferDetail from "./CindyVoice/Transfer detail.mp3";
import TransferHold from "./CindyVoice/Transfer hold.mp3";
import Transfer from "./CindyVoice/Transfer.mp3";
import Yes from "./CindyVoice/Yes.mp3";
import VoiceBar from "./VoiceBar";

const Cindy = () => {
  const [isPaused, setIsPaused] = useState(false);
  const audioRef = useRef(new Audio());

  const playVoice = (audioFile) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    audioRef.current = new Audio(audioFile);
    audioRef.current.play();
    setIsPaused(false);
  };

  const pauseVoice = () => {
    if (audioRef.current && !isPaused) {
      audioRef.current.pause();
      setIsPaused(true);
    }
  };

  const resumeVoice = () => {
    if (audioRef.current && isPaused) {
      audioRef.current.play();
      setIsPaused(false);
    }
  };

  const stopVoice = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  const buttonDataColumns = [
    [
      { label: "Hello 1", color: "#b75c30", audioSrc: Hello1 },
      { label: "Greetings", color: "#b75c30", audioSrc: Greetings },
      { label: "Greetings New", color: "#b75c30", audioSrc: GreetingsNew },
      { label: "Pitch 1", color: "#b75c30", audioSrc: Pitch1 },
      { label: "Pitch 2", color: "#b75c30", audioSrc: Pitch2 },
      { label: "Age", color: "#b75c30", audioSrc: Age },
      { label: "Age 2", color: "#b75c30", audioSrc: Age2 },
      { label: "Plan", color: "#b75c30", audioSrc: Plan },
      { label: "Plan 2", color: "#b75c30", audioSrc: Plan2 },
      { label: "Beneficiary", color: "#b75c30", audioSrc: Beneficiary },
      { label: "Health 1", color: "#b75c30", audioSrc: Health1 },
      { label: "Pitch 3", color: "#b75c30", audioSrc: Pitch3 },
      { label: "Transfer Detail", color: "#b75c30", audioSrc: TransferDetail },
      { label: "Transfer Hold", color: "#b75c30", audioSrc: TransferHold },
      { label: "Transfer", color: "#b75c30", audioSrc: Transfer },
    ],
    [
      { label: "Thanks 4 Asking", color: "#226465", audioSrc: Thanks4Asking },
      { label: "Glad to hear", color: "#226465", audioSrc: GladToHear },
      { label: "Sorry to Hear", color: "#226465", audioSrc: SorryToHear },
      { label: "Great", color: "#226465", audioSrc: Great },
      { label: "Yes", color: "#226465", audioSrc: Yes },
      { label: "No", color: "#226465", audioSrc: No },
      { label: "Real 1", color: "#226465", audioSrc: Real1 },
      { label: "Real 2", color: "#226465", audioSrc: Real2 },
      {
        label: "Already Plan",
        color: "#226465",
        audioSrc: AlreadyHavePlan,
      },
      { label: "Rescue", color: "#226465", audioSrc: Rescue },
      { label: "Laugh", color: "#226465", audioSrc: Laugh },
      { label: "Busy", color: "#226465", audioSrc: Busy },
      { label: "DNC", color: "red", audioSrc: DNC },
      { label: "Hold for Me", color: "#226465", audioSrc: HoldForMe },
      { label: "Cvg For Loved 1", color: "#226465", audioSrc: CvgForLoved1 },
    ],
    [
      { label: "Cost", color: "#333d45", audioSrc: Cost },
      { label: "Cost 2", color: "#333d45", audioSrc: Cost2 },
      { label: "Cost 3", color: "#333d45", audioSrc: Cost3 },
      { label: "BG Noise", color: "red", audioSrc: BackgroundNoise },
      { label: "Call About", color: "#333d45", audioSrc: CallAbout },
      { label: "Not Interested 3", color: "#333d45", audioSrc: NotInterested3 },
      { label: "Not Interested 1", color: "#333d45", audioSrc: NotInterested1 },
      { label: "Not Interested 2", color: "#333d45", audioSrc: NotInterested2 },
      { label: "Add Coverage", color: "#333d45", audioSrc: AddCoverage },
      { label: "Affordability", color: "#333d45", audioSrc: Affordability },
      { label: "Affordability 2", color: "#333d45", audioSrc: Affordability2 },
      { label: "Repeat", color: "#333d45", audioSrc: Repeat },
      { label: "Smoker", color: "#333d45", audioSrc: Smoker },
      { label: "Smoker 2", color: "#333d45", audioSrc: Smoker2 },
      { label: "Benefit", color: "#333d45", audioSrc: Benefit },
    ],
    [
      { label: "Location", color: "#08a472", audioSrc: Location },
      { label: "Customer's Name", color: "#08a472", audioSrc: CustomersName },
      { label: "Email", color: "#08a472", audioSrc: Email },
      { label: "Agent's Name", color: "#08a472", audioSrc: AgentName },
      { label: "Company's Name", color: "#08a472", audioSrc: CompanyName },
      { label: "Okay", color: "#08a472", audioSrc: Okay },
      { label: "No Problem", color: "#08a472", audioSrc: NoProblem },
      { label: "Is That OK", color: "#08a472", audioSrc: IsThatOk },
      { label: "Called Before", color: "#08a472", audioSrc: CalledBefore },
      { label: "Hear Me", color: "#08a472", audioSrc: HearMe },
      { label: "Are you There?", color: "#08a472", audioSrc: AreYouThere },
      { label: "State", color: "#08a472", audioSrc: State },
      { label: "Call Back", color: "#08a472", audioSrc: CallBack },
      {
        label: "Bank Account",
        color: "#08a472",
        audioSrc: CheckSaveAccount,
      },
      { label: "DNC", color: "red", audioSrc: DNC },
    ],
  ];

  return (
    <div className="outerdiv">
        {/* <div style={{ zIndex: 2 }}>
          <VoiceBar/>
        </div> */}
      <div style={{ zIndex: 2 }}>
        <div className="avatar-voice-container">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <button
              className="stop-button"
              onClick={resumeVoice}
              style={{ backgroundColor: "green" }}
            >
              RESUME
            </button>
            <button className="stop-button" onClick={pauseVoice}>
              PAUSE
            </button>
            <button
              className="stop-button"
              onClick={stopVoice}
              style={{ backgroundColor: "red" }}
            >
              STOP
            </button>
          </div>

          <div className="button-grid">
            {buttonDataColumns.map((column, colIndex) => (
              <div key={colIndex} className="button-column">
                {column.map((button, index) => (
                  <button
                    key={index}
                    className="voice-button"
                    style={{ backgroundColor: button.color }}
                    onClick={() => playVoice(button.audioSrc)}
                  >
                    {button.label}
                  </button>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cindy;
