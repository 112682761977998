// import React, { useState, useEffect } from "react";
// import { FaTrash } from "react-icons/fa";
// import Navbar from "./Navbar";

// const Permissions = () => {
//   const [permissions, setPermissions] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [newPermissionName, setNewPermissionName] = useState("");
//   const [roles, setRoles] = useState([]);
//   const [selectedRoles, setSelectedRoles] = useState([]);
//   const token = localStorage.getItem("token") || sessionStorage.getItem("token");

//   useEffect(() => {
//     const fetchPermissions = async (page = 1) => {
//       setLoading(true);
//       try {
//         const response = await fetch(
//           `https://beta.jsonscommunications.com/api/auth/permissions?page=${page}`,
//           {
//             headers: {
//               Accept: "application/json",
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         if (response.ok) {
//           const data = await response.json();
//           setPermissions(data.data);
//           setTotalPages(data.last_page); // Update total pages
//         } else {
//           console.error("Failed to fetch permissions:", response.status);
//         }
//       } catch (error) {
//         console.error("Error fetching permissions:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchPermissions(currentPage);
//   }, [currentPage]);

//   useEffect(() => {
//     const fetchRoles = async () => {
//       try {
//         const response = await fetch(
//           "https://beta.jsonscommunications.com/api/auth/roles",
//           {
//             headers: {
//               Accept: "application/json",
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         if (response.ok) {
//           const data = await response.json();
//           setRoles(data.data);
//         } else {
//           console.error("Failed to fetch roles:", response.status);
//         }
//       } catch (error) {
//         console.error("Error fetching roles:", error);
//       }
//     };

//     fetchRoles();
//   }, []);

//   const handleDelete = async (permissionId) => {
//     if (!window.confirm("Are you sure you want to delete this permission?")) {
//       return;
//     }
//     try {
//       const response = await fetch(
//         `https://beta.jsonscommunications.com/api/auth/permissions/${permissionId}`,
//         {
//           method: "DELETE",
//           headers: {
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (response.ok) {
//         setPermissions(permissions.filter((item) => item.id !== permissionId));
//       } else {
//         console.error("Failed to delete permission:", response.status);
//       }
//     } catch (error) {
//       console.error("Error deleting permission:", error);
//     }
//   };

//   const handleCreate = () => {
//     setIsModalOpen(true);
//   };

//   const handleModalClose = () => {
//     setIsModalOpen(false);
//     setNewPermissionName("");
//     setSelectedRoles([]);
//   };

//   const handleModalCreate = async () => {
//     try {
//       const formData = new URLSearchParams();
//       formData.append("name", newPermissionName);

//       const response = await fetch(
//         "https://beta.jsonscommunications.com/api/auth/permissions",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/x-www-form-urlencoded",
//             Authorization: `Bearer ${token}`,
//           },
//           body: formData.toString(),
//         }
//       );

//       if (response.ok) {
//         const newPermission = await response.json();
//         setPermissions([...permissions, newPermission]);
//         handleModalClose();
//       } else {
//         console.error("Failed to create permission:", response.status);
//       }
//     } catch (error) {
//       console.error("Error creating permission:", error);
//     }
//   };

//   const handlePageChange = (pageNumber) => {
//     if (pageNumber >= 1 && pageNumber <= totalPages) {
//       setCurrentPage(pageNumber);
//     }
//   };

//   const renderPaginationButtons = () => {
//     const maxVisibleButtons = 5;
//     let startPage = Math.max(1, currentPage - Math.floor(maxVisibleButtons / 2));
//     let endPage = startPage + maxVisibleButtons - 1;

//     if (endPage > totalPages) {
//       endPage = totalPages;
//       startPage = Math.max(1, endPage - maxVisibleButtons + 1);
//     }

//     const buttons = [];

//     if (currentPage > 1) {
//       buttons.push(
//         <button
//           key="prev"
//           onClick={() => handlePageChange(currentPage - 1)}
//           style={buttonStyle("#222e47")}
//         >
//           Previous
//         </button>
//       );
//     } else {
//       buttons.push(
//         <button key="prev" disabled style={buttonStyle("#ccc")}>Previous</button>
//       );
//     }

//     for (let i = startPage; i <= endPage; i++) {
//       buttons.push(
//         <button
//           key={i}
//           onClick={() => handlePageChange(i)}
//           style={buttonStyle(i === currentPage ? "#000" : "#222e47")}
//         >
//           {i}
//         </button>
//       );
//     }

//     if (currentPage < totalPages) {
//       buttons.push(
//         <button
//           key="next"
//           onClick={() => handlePageChange(currentPage + 1)}
//           style={buttonStyle("#222e47")}
//         >
//           Next
//         </button>
//       );
//     } else {
//       buttons.push(
//         <button key="next" disabled style={buttonStyle("#ccc")}>Next</button>
//       );
//     }

//     return buttons;
//   };

//   const buttonStyle = (bgColor) => ({
//     padding: "8px 12px",
//     backgroundColor: bgColor,
//     color: "#fff",
//     border: "none",
//     borderRadius: "4px",
//     cursor: bgColor === "#ccc" ? "not-allowed" : "pointer",
//     margin: "0 5px",
//   });

//   const capitalizeFirstLetter = (string) => {
//     return string.replace(/\b\w/g, (char) => char.toUpperCase());
//   };

//   return (
//     <div>
//       <Navbar />
//       <div
//         style={{
//           padding: "50px 20px",
//           fontFamily: "Arial, sans-serif",
//           maxWidth: "1000px",
//           margin: "0 auto",
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             marginBottom: "20px",
//           }}
//         >
//           <h1 style={{ margin: 0 }}>Manage Permissions</h1>
//           <button
//             onClick={handleCreate}
//             style={{
//               backgroundColor: "#222e47",
//               color: "#fff",
//               padding: "10px 20px",
//               border: "none",
//               borderRadius: "5px",
//               cursor: "pointer",
//               width: 150,
//             }}
//           >
//             + Create
//           </button>
//         </div>
//         {loading ? (
//           <p>Loading permissions...</p>
//         ) : (
//           <>
//             <table
//               style={{
//                 width: "100%",
//                 borderCollapse: "collapse",
//                 textAlign: "left",
//                 boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
//               }}
//             >
//               <thead>
//                 <tr>
//                   <th
//                     style={{
//                       padding: "10px",
//                       borderBottom: "1px solid #ddd",
//                       backgroundColor: "#222e47",
//                       color: "#fff",
//                     }}
//                   >
//                     Permissions
//                   </th>
//                   <th
//                     style={{
//                       padding: "10px",
//                       borderBottom: "1px solid #ddd",
//                       backgroundColor: "#222e47",
//                       textAlign: "center",
//                       color: "#fff",
//                       width: "100px",
//                     }}
//                   >
//                     Action
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {permissions.map((permission) => (
//                   <tr key={permission.id}>
//                     <td
//                       style={{
//                         padding: "10px",
//                         borderBottom: "1px solid #ddd",
//                         textAlign: "left",
//                       }}
//                     >
//                       {capitalizeFirstLetter(permission.name)}
//                     </td>
//                     <td
//                       style={{
//                         padding: "10px",
//                         borderBottom: "1px solid #ddd",
//                         textAlign: "center",
//                       }}
//                     >
//                       <button
//                         onClick={() => handleDelete(permission.id)}
//                         style={{
//                           backgroundColor: "transparent",
//                           color: "#222e47",
//                           padding: "5px 10px",
//                           border: "none",
//                           borderRadius: "5px",
//                           cursor: "pointer",
//                         }}
//                       >
//                         <FaTrash />
//                       </button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>

//             {/* Pagination Controls */}
//             <div
//               className="pagination"
//               style={{ marginTop: "20px", textAlign: "center" }}
//             >
//               {renderPaginationButtons()}
//             </div>
//           </>
//         )}
//       </div>

//       {/* Create Permission Modal */}
//       {isModalOpen && (
//         <div style={modalOverlayStyle}>
//           <div style={modalContentStyle}>
//             <h2 style={{ marginBottom: "20px" }}>Create New Permission</h2>
//             <div style={{ marginBottom: "15px" }}>
//               <label style={labelStyle}>Name</label>
//               <input
//                 type="text"
//                 value={newPermissionName}
//                 onChange={(e) => setNewPermissionName(e.target.value)}
//                 style={inputStyle}
//               />
//             </div>
//             <div style={{ marginBottom: "15px" }}>
//               <label style={labelStyle}>Assign Permission to Roles</label>
//               <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
//                 {roles.map((role) => (
//                   <label key={role.id} style={{ display: "flex", alignItems: "center" }}>
//                     <input
//                       type="checkbox"
//                       value={role.id}
//                       checked={selectedRoles.includes(role.id)}
//                       onChange={(e) => {
//                         const roleId = parseInt(e.target.value);
//                         setSelectedRoles((prev) =>
//                           e.target.checked
//                             ? [...prev, roleId]
//                             : prev.filter((id) => id !== roleId)
//                         );
//                       }}
//                     />
//                     {capitalizeFirstLetter(role.name)}
//                   </label>
//                 ))}
//               </div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
//               <button onClick={handleModalClose} style={{cursor:"pointer", backgroundColor:'#ccc', color:'#222e47'}} disabled={false}>Cancel</button>
//               <button onClick={handleModalCreate} style={buttonStyle("#222e47")}>Create</button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const modalOverlayStyle = {
//   position: "fixed",
//   top: 0,
//   left: 0,
//   right: 0,
//   bottom: 0,
//   backgroundColor: "rgba(0, 0, 0, 0.5)",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   zIndex: 1000,
// };

// const modalContentStyle = {
//   backgroundColor: "#fff",
//   padding: "30px",
//   borderRadius: "8px",
//   width: "400px",
//   maxWidth: "90%",
//   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
// };

// const labelStyle = {
//   display: "block",
//   marginBottom: "8px",
//   color: "#333",
//   fontWeight: "bold",
// };

// const inputStyle = {
//   width: "100%",
//   padding: "10px",
//   borderRadius: "4px",
//   border: "1px solid #ddd",
// };

// export default Permissions;
















import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import Navbar from "./Navbar";
import "./Permissions.css"; // Import the CSS file

const Permissions = () => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPermissionName, setNewPermissionName] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const token = localStorage.getItem("token") || sessionStorage.getItem("token");

  useEffect(() => {
    const fetchPermissions = async (page = 1) => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://beta.jsonscommunications.com/api/auth/permissions?page=${page}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setPermissions(data.data);
          setTotalPages(data.last_page); // Update total pages
        } else {
          console.error("Failed to fetch permissions:", response.status);
        }
      } catch (error) {
        console.error("Error fetching permissions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(
          "https://beta.jsonscommunications.com/api/auth/roles",
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setRoles(data.data);
        } else {
          console.error("Failed to fetch roles:", response.status);
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);

  const handleDelete = async (permissionId) => {
    if (!window.confirm("Are you sure you want to delete this permission?")) {
      return;
    }
    try {
      const response = await fetch(
        `https://beta.jsonscommunications.com/api/auth/permissions/${permissionId}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        setPermissions(permissions.filter((item) => item.id !== permissionId));
      } else {
        console.error("Failed to delete permission:", response.status);
      }
    } catch (error) {
      console.error("Error deleting permission:", error);
    }
  };

  const handleCreate = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setNewPermissionName("");
    setSelectedRoles([]);
  };

  const handleModalCreate = async () => {
    try {
      const formData = new URLSearchParams();
      formData.append("name", newPermissionName);

      const response = await fetch(
        "https://beta.jsonscommunications.com/api/auth/permissions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
          body: formData.toString(),
        }
      );

      if (response.ok) {
        const newPermission = await response.json();
        setPermissions([...permissions, newPermission]);
        handleModalClose();
      } else {
        console.error("Failed to create permission:", response.status);
      }
    } catch (error) {
      console.error("Error creating permission:", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderPaginationButtons = () => {
    const maxVisibleButtons = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisibleButtons / 2));
    let endPage = startPage + maxVisibleButtons - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxVisibleButtons + 1);
    }

    const buttons = [];

    if (currentPage > 1) {
      buttons.push(
        <button key="prev" onClick={() => handlePageChange(currentPage - 1)} className="pagination-button">
          Previous
        </button>
      );
    } else {
      buttons.push(
        <button key="prev" disabled className="pagination-button disabled">
          Previous
        </button>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`pagination-button ${i === currentPage ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    if (currentPage < totalPages) {
      buttons.push(
        <button key="next" onClick={() => handlePageChange(currentPage + 1)} className="pagination-button">
          Next
        </button>
      );
    } else {
      buttons.push(
        <button key="next" disabled className="pagination-button disabled">
          Next
        </button>
      );
    }

    return buttons;
  };

  const capitalizeFirstLetter = (string) => {
    return string.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="header">
          <h1>Manage Permissions</h1>
          <button onClick={handleCreate}>+ Create</button>
        </div>
        {loading ? (
          <p>Loading permissions...</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>Permissions</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {permissions.map((permission) => (
                  <tr key={permission.id}>
                    <td>{capitalizeFirstLetter(permission.name)}</td>
                    <td>
                      <button onClick={() => handleDelete(permission.id)}>
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="pagination">{renderPaginationButtons()}</div>
          </>
        )}
      </div>

      {/* Create Permission Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Create New Permission</h2>
            <div>
              <label>Name</label>
              <input
                type="text"
                value={newPermissionName}
                onChange={(e) => setNewPermissionName(e.target.value)}
              />
            </div>
            <div>
              <label>Assign Permission to Roles</label>
              <div className="roles-container">
                {roles.map((role) => (
                  <label key={role.id}>
                    <input
                      type="checkbox"
                      value={role.id}
                      checked={selectedRoles.includes(role.id)}
                      onChange={(e) => {
                        const roleId = parseInt(e.target.value);
                        setSelectedRoles((prev) =>
                          e.target.checked
                            ? [...prev, roleId]
                            : prev.filter((id) => id !== roleId)
                        );
                      }}
                    />
                    <span style={{marginLeft:10}}>
                    {capitalizeFirstLetter(role.name)}
                    </span>
                  </label>
                ))}
              </div>
            </div>
            <div className="actions">
              <button onClick={handleModalClose} className="cancel-btn">
                Cancel
              </button>
              <button onClick={handleModalCreate} className="create-btn">
                Create
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Permissions;
