import React, { useRef, useState } from 'react';
import './Helli.css';

// Corrected imports with updated path './Helli Wilson 110/'

import addCoverage from './Helli Wilson 110/add_coverage.mp3';
import affordability from './Helli Wilson 110/Affordibility.mp3';
import age1 from './Helli Wilson 110/Age_1.mp3';
import age2 from './Helli Wilson 110/Age_2.mp3';
import alreadyCovered from './Helli Wilson 110/Already_covg.mp3';
import anyQuestion from './Helli Wilson 110/Any_question.mp3';
import areYouThere from './Helli Wilson 110/are_you_there.mp3';
import areYouReal1 from './Helli Wilson 110/Are_you_real_1.mp3';
import areYouReal2 from './Helli Wilson 110/Are_you_real_2.mp3';
import bank1 from './Helli Wilson 110/Bank1.mp3';
import bank2 from './Helli Wilson 110/Bank2.mp3';
import beneficiary1 from './Helli Wilson 110/Beneficiary_1.mp3';
import beneficiary2 from './Helli Wilson 110/Beneficiary_2.mp3';
import busy from './Helli Wilson 110/Busy.mp3';
import canYouHear1 from './Helli Wilson 110/Can_you_hear_1.mp3';
import canYouHear2 from './Helli Wilson 110/Can_you_hear_2.mp3';
import closerBusy from './Helli Wilson 110/Closer_Busy.mp3';
import company from './Helli Wilson 110/Company.mp3';
import cost from './Helli Wilson 110/Cost.mp3';
import coverageForLovedOne from './Helli Wilson 110/cvg_for_loved_once.mp3';
import disclaimer from './Helli Wilson 110/Disclaimer.mp3';
import dnc from './Helli Wilson 110/DNC.mp3';
import doYouHaveCoverage from './Helli Wilson 110/Do_you_have_Covrg.mp3';
import excellent from './Helli Wilson 110/Excellent.mp3';
import great from './Helli Wilson 110/Great.mp3';
import greeting from './Helli Wilson 110/Greeting.mp3';
import health1 from './Helli Wilson 110/health 1.mp3';
import health2 from './Helli Wilson 110/Health_2.mp3';
import hello from './Helli Wilson 110/Hello.mp3';
import holdOn1 from './Helli Wilson 110/Hold_on_1.mp3';
import holdOn2 from './Helli Wilson 110/Hold_on_2.mp3';
import intro2 from './Helli Wilson 110/Intro_2.mp3';
import laughs from './Helli Wilson 110/Laughs.mp3';
import name from './Helli Wilson 110/Name.mp3';
import no from './Helli Wilson 110/NO.mp3';
import notInterested2 from './Helli Wilson 110/not_interested_2.mp3';
import okay from './Helli Wilson 110/Okay.mp3';
import pitch1 from './Helli Wilson 110/Pitch_1.mp3';
import pitch2 from './Helli Wilson 110/Pitch_2.mp3';
import pitch3 from './Helli Wilson 110/Pitch_3.mp3';
import plans from './Helli Wilson 110/Plans_.mp3';
import repeat from './Helli Wilson 110/Repeat.mp3';
import rescue from './Helli Wilson 110/Rescue.mp3';
import smoker from './Helli Wilson 110/Smoker.mp3';
import thanksAsking from './Helli Wilson 110/Thanks_Asking.mp3';
import transfer1 from './Helli Wilson 110/Transfer_1.mp3';
import transfer2 from './Helli Wilson 110/Transfer_2.mp3';
import transferDetail from './Helli Wilson 110/Transfer_detail.mp3';
import understand from './Helli Wilson 110/understand.mp3';
import waiting from './Helli Wilson 110/waiting.mp3';
import whatsTheCost from './Helli Wilson 110/whats_the_cost.mp3';
import whereYouLocated from './Helli Wilson 110/Where_you_located.mp3';
import wonderful from './Helli Wilson 110/Wonderful.mp3';
import yes from './Helli Wilson 110/Yes.mp3';
import VoiceBar from './VoiceBar';



const Helli = () => {
  const [isPaused, setIsPaused] = useState(false);
  const audioRef = useRef(new Audio());

  const playVoice = (audioFile) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    audioRef.current = new Audio(audioFile);
    audioRef.current.play();
    setIsPaused(false);
  };

  const pauseVoice = () => {
    if (audioRef.current && !isPaused) {
      audioRef.current.pause();
      setIsPaused(true);
    }
  };

  const resumeVoice = () => {
    if (audioRef.current && isPaused) {
      audioRef.current.play();
      setIsPaused(false);
    }
  };

  const stopVoice = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  const buttonDataColumns = [
    [// Derick Miller
      { label: 'Hello', color: '#b75c30', audioSrc: hello },
      { label: 'Pitch1', color: '#b75c30', audioSrc: pitch1 },
      { label: 'Age 2', color: '#b75c30', audioSrc: age2 },
      { label: 'Health 2', color: '#b75c30', audioSrc: health2 },
      { label: 'Do You have cove', color: '#b75c30', audioSrc: doYouHaveCoverage },
      { label: 'Affordibility', color: '#b75c30', audioSrc: affordability },
      { label: 'Thanks Asking', color: '#b75c30', audioSrc: thanksAsking },
      { label: 'Can you hear 2', color: '#b75c30', audioSrc: canYouHear2 },
      { label: 'No', color: '#b75c30', audioSrc: no },
      { label: 'Not interested 2', color: '#b75c30', audioSrc: notInterested2 },
      { label: 'Laughs', color: '#b75c30', audioSrc: laughs },
      { label: 'Hold on 1', color: '#b75c30', audioSrc: holdOn1 },
      { label: 'Transfer Detail', color: '#b75c30', audioSrc: transferDetail },
      { label: 'Are you real 2', color: '#b75c30', audioSrc: areYouReal2 },
    //   { label: 'No Problem', color: '#b75c30', audioSrc: noProblem },
    ],
    [// James Anderson
      { label: 'Greeting', color: '#226465', audioSrc: greeting },
      { label: 'Pitch 2', color: '#226465', audioSrc: pitch2 },
      { label: 'Beneficiary 1', color: '#226465', audioSrc: beneficiary1 },
      { label: 'Add Coverage', color: '#226465', audioSrc: addCoverage },
      { label: 'Can you hear 1', color: '#226465', audioSrc: canYouHear1 },
      { label: 'Cvg for love one', color: '#226465', audioSrc: coverageForLovedOne },
      { label: 'Great', color: '#226465', audioSrc: great },
      { label: 'Smoker', color: '#226465', audioSrc: smoker },
      { label: 'Are you there', color: '#226465', audioSrc: areYouThere },
      { label: 'DNC', color: 'red', audioSrc: dnc },
      { label: 'Okay', color: '#226465', audioSrc: okay },
      { label: 'Hold on 2', color: '#226465', audioSrc: holdOn2 },
      { label: 'Waiting', color: '#226465', audioSrc: waiting },
      { label: 'Name', color: '#226465', audioSrc: name },
      { label: 'Rescue', color: '#226465', audioSrc: rescue },
    ],
    [// Robert Wolf
      { label: 'Intro 2', color: '#333d45', audioSrc: intro2 },
      { label: 'Pitch 3', color: '#333d45', audioSrc: pitch3 },
      { label: 'Beneficiary 2', color: '#333d45', audioSrc: beneficiary2 },
      { label: 'Busy', color: '#333d45', audioSrc: busy },
      { label: 'Bank 1', color: '#333d45', audioSrc: bank1 },
      { label: 'Any question', color: '#333d45', audioSrc: anyQuestion },
      { label: 'Wonderful', color: '#333d45', audioSrc: wonderful },
      { label: 'Where U Located', color: '#333d45', audioSrc: whereYouLocated },
      { label: 'Understand', color: '#333d45', audioSrc: understand },
      { label: 'Excellent', color: '#333d45', audioSrc: excellent },
      { label: 'Whats the cost', color: '#333d45', audioSrc: whatsTheCost },
      { label: 'Transfer 1', color: '#333d45', audioSrc: transfer1 },
      { label: 'Disclaimer', color: 'red', audioSrc: disclaimer },
      { label: 'Company', color: '#333d45', audioSrc: company },
    ],
    [// Mark Smith
    //   { label: 'Glad to hear', color: '#08a472', audioSrc: gladToHear },
      { label: 'Age 1', color: '#08a472', audioSrc: age1 },
      { label: 'Health 1', color: '#08a472', audioSrc: health1 },
      { label: 'Already Coverage', color: '#08a472', audioSrc: alreadyCovered },
      { label: 'Bank 2', color: '#08a472', audioSrc: bank2 },
    //   { label: 'Sorry to hear', color: '#08a472', audioSrc: sorryToHear },
      { label: 'Plans', color: '#08a472', audioSrc: plans },
      { label: 'Yes', color: '#08a472', audioSrc: yes },
    //   { label: 'Not intersted 1', color: '#08a472', audioSrc: notInterested1 },
      { label: 'Cost', color: '#08a472', audioSrc: cost },
      { label: 'Closer Busy', color: '#08a472', audioSrc: closerBusy },
      { label: 'Transfer 2', color: '#08a472', audioSrc: transfer2 },
      { label: 'Are you real 1', color: '#08a472', audioSrc: areYouReal1 },
      { label: 'Repeat', color: '#08a472', audioSrc: repeat },
    ],
  ];
  
  

  return (
    <div className="outerdiv">
      {/* <div style={{zIndex:2}}>
        <VoiceBar/>
      </div> */}
      <div className="avatar-voice-container">
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
          <button className="stop-button" onClick={resumeVoice} style={{ backgroundColor: 'green' }}>
            RESUME
          </button>
          <button className="stop-button" onClick={pauseVoice}>
            PAUSE
          </button>
          <button className="stop-button" onClick={stopVoice} style={{ backgroundColor: 'red' }}>
            STOP
          </button>
        </div>

        <div className="button-grid">
          {buttonDataColumns.map((column, colIndex) => (
            <div key={colIndex} className="button-column">
              {column.map((button, index) => (
                <button
                  key={index}
                  className="voice-button"
                  style={{ backgroundColor: button.color }}
                  onClick={() => playVoice(button.audioSrc)}
                >
                  {button.label}
                </button>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Helli;