import React, { useState } from 'react';
import Navbar from './Navbar';
import './ReQALead.css';
import QALinks from './QALinks';

const ReQALead = () => {
  // Sample data to simulate searching
  const initialLeadData = {
    rangeId: '163521',
    recording: 'https://example.com/recording.mp3',
    leadId: 'L45678',
    phoneNumber: '1234567890',
    dialerId: 'D567890',
    center: 'Main Center',
    isGreetings: 'Yes',
    isTransferDetails: 'Yes',
    isFirstCallAsked: 'Yes',
    isTransferConsent: 'Yes',
    age: '30',
    isSmoker: 'No',
    useRebuttals: 'Yes',
    numberOfRefusals: 2,
    isHealth: 'Yes',
    isBanking: 'Yes',
    isBeneficiary: 'Yes',
    isAccount: 'Yes',
    qaStatus: 'Approved',
    qaComments: 'Good performance',
  };

  const [leadId, setLeadId] = useState('');
  const [leadData, setLeadData] = useState(null);

  const handleSearch = () => {
    // For now, setting sample lead data. In real scenarios, you could fetch it from an API
    if (leadId) {
      setLeadData(initialLeadData);
    }
  };

  return (
    <div>
      <Navbar />
      <QALinks/>
      <div className="reqa-lead-content">
        <h1>Search Lead</h1>
        <div className="lead-search-form">
          <label htmlFor="lead-id">Lead ID</label>
          <input
            id="lead-id"
            type="text"
            value={leadId}
            onChange={(e) => setLeadId(e.target.value)}
            placeholder="Enter Lead ID"
          />
          <button onClick={handleSearch} className="search-button">
            Search
          </button>
        </div>

        {/* Display lead details after search */}
        {leadData && (
          <div className="lead-details-form">
            <div className="form-group">
              <label>Range ID</label>
              <input type="text" value={leadData.rangeId} readOnly />
            </div>
            <div className="form-group">
              <label>Recording</label>
              <audio controls>
                <source src={leadData.recording} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
              <a href={leadData.recording} target="_blank" rel="noopener noreferrer">Open Recording</a>
            </div>
            <div className="form-group">
              <label>Lead ID</label>
              <input type="text" value={leadData.leadId} readOnly />
            </div>
            <div className="form-group">
              <label>Phone Number</label>
              <input type="text" value={leadData.phoneNumber} readOnly />
            </div>
            <div className="form-group">
              <label>Dialer ID</label>
              <input type="text" value={leadData.dialerId} readOnly />
            </div>
            <div className="form-group">
              <label>Center</label>
              <input type="text" value={leadData.center} readOnly />
            </div>

            {/* Dropdown fields */}
            <div className="form-group">
              <label>Is Greetings?</label>
              <select value={leadData.isGreetings}>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="form-group">
              <label>Is Transfer Details?</label>
              <select value={leadData.isTransferDetails}>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="form-group">
              <label>Is First Call Asked?</label>
              <select value={leadData.isFirstCallAsked}>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="form-group">
              <label>Is Transfer Consent?</label>
              <select value={leadData.isTransferConsent}>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="form-group">
              <label>Age</label>
              <input type="text" value={leadData.age} readOnly />
            </div>
            <div className="form-group">
              <label>Is Smoker?</label>
              <select value={leadData.isSmoker}>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="form-group">
              <label>Use of Rebuttals</label>
              <select value={leadData.useRebuttals}>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="form-group">
              <label>Number of Refusals</label>
              <input type="number" value={leadData.numberOfRefusals} readOnly />
            </div>
            <div className="form-group">
              <label>Is Health?</label>
              <select value={leadData.isHealth}>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="form-group">
              <label>Is Banking?</label>
              <select value={leadData.isBanking}>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="form-group">
              <label>Is Beneficiary?</label>
              <select value={leadData.isBeneficiary}>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="form-group">
              <label>Is Account?</label>
              <select value={leadData.isAccount}>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="form-group">
              <label>QA Status</label>
              <select value={leadData.qaStatus}>
                <option>Approved</option>
                <option>Rejected</option>
                <option>Pending</option>
              </select>
            </div>
            <div className="form-group">
              <label>QA Comments</label>
              <input type="text" value={leadData.qaComments} readOnly />
            </div>

            <button className="update-button">Update</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReQALead;
