// import React from "react";
import { Link } from "react-router-dom";
import { FaCalendar, FaPhone, FaPlus, FaRobot } from "react-icons/fa";

const Leaderboardbar = () => {
  return (
    <div className="flex gap-4 justify-center p-4">
      <Link className="flex items-center justify-center gap-2 text-[#222e47] bg-white rounded-full px-4 py-3 font-medium shadow-md w-48 hover:shadow-lg">
        <FaPlus />
        <span>Create Lead</span>
      </Link>
      <Link className="flex items-center justify-center gap-2 text-[#222e47] bg-white rounded-full px-4 py-3 font-medium shadow-md w-48 hover:shadow-lg">
        <FaRobot />
        <span>Avatar Section</span>
      </Link>
      <Link className="flex items-center justify-center gap-2 text-[#222e47] bg-white rounded-full px-4 py-3 font-medium shadow-md w-48 hover:shadow-lg">
        <FaPhone />
        <span>Dialer</span>
      </Link>
    </div>
  );
};

export default Leaderboardbar;
