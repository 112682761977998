import React, { useState } from 'react';
import Navbar from './Navbar';
import TeamManagementLinks from './TeamManagementLinks';
import './RemoveAgent.css';

const initialAgents = [
  { id: 1, name: 'Suliman Safi', team: 'ÚńíŤŐþÅßĹÈ' },
  { id: 2, name: 'Muhammad Saad', team: 'ÚńíŤŐþÅßĹÈ' },
  { id: 3, name: 'Jamal Khan', team: 'ÚńíŤŐþÅßĹÈ' },
  { id: 4, name: 'Awais Ghouri', team: 'ÚńíŤŐþÅßĹÈ' },
  { id: 5, name: 'Tayyab Ali', team: 'ÚńíŤŐþÅßĹÈ' },
  { id: 6, name: 'Tahir Ali', team: 'ÚńíŤŐþÅßĹÈ' },
  { id: 7, name: 'ASAD ULLAH', team: 'ÚńíŤŐþÅßĹÈ' },
  { id: 8, name: 'Ammar Kyani', team: 'ÚńíŤŐþÅßĹÈ' },
  { id: 9, name: 'Kashan Ur Rehman', team: 'ÚńíŤŐþÅßĹÈ' },
  { id: 10, name: 'Muneeb Ahmed', team: 'ÚńíŤŐþÅßĹÈ' },
  { id: 11, name: 'Kashif Mehmood', team: 'ÚńíŤŐþÅßĹÈ' },
];

const RemoveAgent = () => {
  const [agents, setAgents] = useState(initialAgents);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSelectAgent = (id) => {
    setSelectedAgents((prev) =>
      prev.includes(id) ? prev.filter((agentId) => agentId !== id) : [...prev, id]
    );
  };

  const handleRemoveAgents = () => {
    setAgents((prev) => prev.filter((agent) => !selectedAgents.includes(agent.id)));
    setSelectedAgents([]); // Clear selected agents after removal
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredAgents = agents.filter((agent) =>
    agent.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Navbar />
      <TeamManagementLinks />
      <div className="remove-agent-container">
        <h2>Remove Agents from Teams</h2>
        <input
          type="text"
          placeholder="Search Agent"
          className="search-bar"
          value={searchTerm}
          onChange={handleSearch}
        />
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Agent Name</th>
                <th>Team</th>
                <th>Remove Agent</th>
              </tr>
            </thead>
            <tbody>
              {filteredAgents.map((agent) => (
                <tr key={agent.id}>
                  <td>{agent.name}</td>
                  <td>{agent.team}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedAgents.includes(agent.id)}
                      onChange={() => handleSelectAgent(agent.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button className="remove-button" onClick={handleRemoveAgents}>
          Remove Selected Agents
        </button>
      </div>
    </div>
  );
};

export default RemoveAgent;
