// import React, { useState } from "react";
// import "./Register.css";
// import { FaUser, FaEnvelope, FaLock, FaInfoCircle } from "react-icons/fa";

// const Register = () => {
//   const [email, setEmail] = useState("");
//   const [type, setType] = useState("");
//   const [password, setPassword] = useState("");
//   const [passwordConfirmation, setPasswordConfirmation] = useState("");
//   const [name, setName] = useState("");

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle registration logic here
//     console.log("Email:", email);
//     console.log("Type:", type);
//     console.log("Password:", password);
//     console.log("Password Confirmation:", passwordConfirmation);
//     console.log("Name:", name);
//   };

//   return (
//     <div className="register-container">
//       <div className="register-box">
//         <h2>Register</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="input-group">
//             <input
//               type="email"
//               required
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//             <label>Email</label>
//             <b className="icon">
//               <FaEnvelope />
//             </b>
//           </div>
//           <div className="input-group">
//             <input
//               type="text"
//               required
//               value={type}
//               onChange={(e) => setType(e.target.value)}
//             />
//             <label>Type</label>
//             <b className="icon">
//               <FaInfoCircle />
//             </b>
//           </div>
//           <div className="input-group">
//             <input
//               type="password"
//               required
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//             />
//             <label>Password</label>
//             <b className="icon">
//               <FaLock />
//             </b>
//           </div>
//           <div className="input-group">
//             <input
//               type="password"
//               required
//               value={passwordConfirmation}
//               onChange={(e) => setPasswordConfirmation(e.target.value)}
//             />
//             <label>Confirm Password</label>
//             <b className="icon">
//               <FaLock />
//             </b>
//           </div>
//           <div className="input-group">
//             <input
//               type="text"
//               required
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//             />
//             <label>Name</label>
//             <b className="icon">
//               <FaUser />
//             </b>
//           </div>
//           <button type="submit">Register</button>
//           <p>
//             Already have an account? <a href="#">Login</a>
//           </p>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Register;


















//-------------------------------->API integrated code below
import React, { useState } from "react";
import "./Register.css";
import { FaUser, FaEnvelope, FaLock, FaInfoCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const Register = () => {
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch("https://beta.jsonscommunications.com/api/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
          type,
          password_confirmation: passwordConfirmation,
          name,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Registration failed. Please try again.");
      }

      setSuccess("Registration successful!");
      console.log("Registration successful:", data);

      // Clear form fields
      setEmail("");
      setType("");
      setPassword("");
      setPasswordConfirmation("");
      setName("");
      
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="register-body">
      <div className="register-container">
        <div className="register-box">
          <h2>Register</h2>
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <input
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>Email</label>
              <b className="icon">
                <FaEnvelope />
              </b>
            </div>
            <div className="input-group">
              <input
                type="text"
                required
                value={type}
                onChange={(e) => setType(e.target.value)}
              />
              <label>Type</label>
              <b className="icon">
                <FaInfoCircle />
              </b>
            </div>
            <div className="input-group">
              <input
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label>Password</label>
              <b className="icon">
                <FaLock />
              </b>
            </div>
            <div className="input-group">
              <input
                type="password"
                required
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
              <label>Confirm Password</label>
              <b className="icon">
                <FaLock />
              </b>
            </div>
            <div className="input-group">
              <input
                type="text"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label>Name</label>
              <b className="icon">
                <FaUser />
              </b>
            </div>
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
            <button type="submit" disabled={loading}>
              {loading ? "Registering..." : "Register"}
            </button>
            <p>
              Already have an account? <Link to="/login">Login</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
