import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./VoiceBar.css";

const VoiceBar6 = () => {
  const location = useLocation();

  return (
    <nav className="maindivbar">
      <Link
        to="/dialer6/agent4"
        className={`linkbar ${location.pathname === "/dialer6/agent4" ? "active" : ""}`}
      >
        Agent 4
      </Link>
      <Link
        to="/dialer6/james"
        className={`linkbar ${location.pathname === "/dialer6/james" ? "active" : ""}`}
      >
        James
      </Link>
      <Link
        to="/dialer6/105"
        className={`linkbar ${location.pathname === "/dialer6/105" ? "active" : ""}`}
      >
        David Miller
      </Link>
      <Link
        to="/dialer6/helli"
        className={`linkbar ${location.pathname === "/dialer6/helli" ? "active" : ""}`}
      >
        Helli Wilson
      </Link>
      <Link
        to="/dialer6/henna"
        className={`linkbar ${location.pathname === "/dialer6/henna" ? "active" : ""}`}
      >
        Henna Walker
      </Link>
      <Link
        to="/dialer6/cindy"
        className={`linkbar ${location.pathname === "/dialer6/cindy" ? "active" : ""}`}
      >
        Cindy Jones
      </Link>
      <Link
        to="/dialer6/selvey"
        className={`linkbar ${location.pathname === "/dialer6/selvey" ? "active" : ""}`}
      >
        Selvey
      </Link>
    </nav>
  );
};

export default VoiceBar6;
