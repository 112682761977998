// import React, { useState } from 'react';
// import './TCPACheck.css';

// const TCPACheck = () => {
//     const [phoneNumber, setPhoneNumber] = useState('');
//     const [types, setTypes] = useState(['tcpa', 'dnc', 'troll', 'dnc_complainers', 'federal_dnc', 'state_dnc']);
//     const [results, setResults] = useState(null);
//     const [error, setError] = useState(null);

//     const searchPhoneNumber = async (phoneNumber, types) => {
//         const API_URL = 'https://crmx.live/tcpa-api';
//         try {
//             const response = await fetch(`${API_URL}`, {
//                 method: 'POST',
//                 headers: {
//                     // 'Content-Type': 'application/json',
//                     'Accept': 'application/json',
//                 },
//                 body: JSON.stringify({ phone_number: phoneNumber, types }),
//             });

//             if (!response.ok) {
//                 throw new Error('Failed to retrieve data from the server');
//             }

//             const data = await response.json();
//             console.log('response', response)
//             return data.results; // Return the results directly
//         } catch (error) {
//             throw new Error(error.message || 'Failed to retrieve data from the server');
//         }
//     };

//     const handleTypeChange = (e) => {
//         const { value, checked } = e.target;
//         setTypes((prevTypes) =>
//             checked ? [...prevTypes, value] : prevTypes.filter((type) => type !== value)
//         );
//     };

//     const handleSearch = async (e) => {
//         e.preventDefault();
//         setError(null);
//         setResults(null);

//         try {
//             if (!types.length) {
//                 throw new Error('Please select at least one type.');
//             }
//             const data = await searchPhoneNumber(phoneNumber, types);
//             setResults(data);
//         } catch (err) {
//             setError(err.message);
//         }
//     };

//     return (
//         <div className="containert">
//             <h2 className="titlet">TCPA Litigator List API Phone Number Search</h2>
//             <form onSubmit={handleSearch} className="form">
//                 <div className="form-groupt">
//                     <label className="form-labelt">Phone Number</label>
//                     <input
//                         type="text"
//                         className="form-controlt"
//                         value={phoneNumber}
//                         onChange={(e) => setPhoneNumber(e.target.value)}
//                         placeholder="Enter phone number"
//                         required
//                     />
//                 </div>
//                 {/* <div className="form-group">
//                     <label className="form-label">Select Types</label>
//                     <div className="checkbox-group">
//                         {['tcpa', 'dnc', 'troll', 'dnc_complainers', 'federal_dnc', 'state_dnc'].map((type) => (
//                             <div key={type} className="check">
//                                 <input
//                                     type="checkbox"
//                                     id={type}
//                                     value={type}
//                                     onChange={handleTypeChange}
//                                 />
//                                 <label htmlFor={type}>{type.replace('_', ' ').toUpperCase()}</label>
//                             </div>
//                         ))}
//                     </div>
//                 </div> */}
//                 <button type="submit" className="btnt">Search</button>
//             </form>
//             {error && <div className="alertt alert-danger">{error}</div>}
//             {results && (
//                 <div className="resultst">
//                     <table className="resultst-table">
//                         <thead>
//                             <tr>
//                                 <th colSpan="2" className="tablet-title">RESULTS</th>
//                             </tr>
//                             <tr>
//                                 <th>Status</th>
//                                 <th>Is Bad Number</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             <tr>
//                                 <td>{results.status}</td>
//                                 <td>{results.is_bad_number ? 'Yes' : 'No'}</td>
//                             </tr>
//                         </tbody>
//                     </table>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default TCPACheck;

























import React, { useState } from "react";
import videobg from "../SplitscreenTesting/videobg.mp4";

const TCPACheck = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [types, setTypes] = useState([
    "tcpa",
    "dnc",
    "troll",
    "dnc_complainers",
    "federal_dnc",
    "state_dnc",
  ]);
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);

  const handleLogin = (e) => {
    e.preventDefault();
    if (username === "tcpa1" && password === "tcpaword") {
      setIsLoggedIn(true);
      setError(null);
    } else {
      setError("Invalid username or password.");
    }
  };

  const searchPhoneNumber = async (phoneNumber, types) => {
    const API_URL = "https://crmx.live/tcpa-api";
    try {
      const response = await fetch(`${API_URL}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({ phone_number: phoneNumber, types }),
      });

      if (!response.ok) {
        throw new Error("Failed to retrieve data from the server");
      }

      const data = await response.json();
      return data.results;
    } catch (error) {
      throw new Error(error.message || "Failed to retrieve data from the server");
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setError(null);
    setResults(null);

    try {
      if (!types.length) {
        throw new Error("Please select at least one type.");
      }
      const data = await searchPhoneNumber(phoneNumber, types);
      setResults(data);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="relative w-full h-screen overflow-hidden">
      {/* Background Video */}
      <video
        autoPlay
        loop
        muted
        className="absolute top-20 left-0 w-full h-full object-cover z-0"
      >
        <source src={videobg} type="video/mp4" />
      </video>

      {/* Content Overlay */}
      <div className="relative z-10 flex items-center justify-center h-ful p-10">
        {!isLoggedIn ? (
          <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
            <h2 className="text-xl font-bold text-center mb-4">Login</h2>
            <form onSubmit={handleLogin} className="space-y-4">
              <div>
                <label className="block font-medium mb-2">Username</label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Enter username"
                  required
                />
              </div>
              <div>
                <label className="block font-medium mb-2">Password</label>
                <input
                  type="password"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter password"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full py-2 bg-[#222e47] text-white rounded-md hover:bg-[#151e32] transition"
              >
                Login
              </button>
            </form>
            {error && (
              <div className="mt-4 text-red-500 text-center">{error}</div>
            )}
          </div>
        ) : (
          <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
            <h2 className="text-xl font-bold text-center mb-4">
              TCPA Litigator
            </h2>
            <form onSubmit={handleSearch} className="space-y-4">
              <div>
                <label className="block font-medium mb-2">Phone Number</label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-indigo-500"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter phone number"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full py-2 bg-[#222e47] text-white rounded-md hover:bg-[#151e32] transition"
              >
                Search
              </button>
            </form>
            {error && (
              <div className="mt-4 text-red-500 text-center">{error}</div>
            )}
            {results && (
              <div className="mt-6">
                <table className="w-full border border-gray-300 text-center">
                  <thead>
                    <tr>
                      <th
                        colSpan="2"
                        className="bg-indigo-600 text-white text-lg font-bold p-2"
                      >
                        RESULTS
                      </th>
                    </tr>
                    <tr>
                      <th className="p-2 border-b border-gray-300">Status</th>
                      <th className="p-2 border-b border-gray-300">
                        Is Bad Number
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-2">{results.status}</td>
                      <td className="p-2">
                        {results.is_bad_number ? "Yes" : "No"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TCPACheck;
