//-------------------------------->Code with token from API
// import React, { useState, useEffect } from 'react';
// import './ManageUser.css';
// import Navbar from './Navbar';

// const ManageUser = () => {
//   const [search, setSearch] = useState('');
//   const [entries, setEntries] = useState(10);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [activeDropdown, setActiveDropdown] = useState(null);
//   const [users, setUsers] = useState([]);
//   const [totalPages, setTotalPages] = useState(1);
//   const [loading, setLoading] = useState(false);

//   // Function to get token from localStorage or sessionStorage
//   const getToken = () => {
//     const token = localStorage.getItem("token") || sessionStorage.getItem("token");
//     return token ? token.trim() : null; // Ensure no extra whitespace
//   };

//   // Function to fetch users
//   const fetchUsers = async (page = 1) => {
//     setLoading(true);
//     const token = getToken(); // Retrieve the token from storage
//     if (!token) {
//       console.error('No token found');
//       setLoading(false);
//       return;
//     }

//     console.log('Using token in request:', token); // Debugging to confirm token in use

//     try {
//       const response = await fetch(`https://beta.jsonscommunications.com/api/auth/users?page=${page}`, {
//         method: "GET",
//         headers: {
//           Accept: 'application/json',
//           "Authorization": `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setUsers(data.users.data);
//         setTotalPages(data.users.last_page);
//       } else {
//         console.error(`Error fetching users: ${response.status}`);
//       }
//     } catch (error) {
//       console.error('Error fetching users:', error);
//     }
//     setLoading(false);
//   };

//   useEffect(() => {
//     const token = getToken(); // Retrieve token before fetchUsers
//     if (token) {
//       fetchUsers(currentPage);
//     }
//   }, [currentPage, entries]);

//   const handleSearch = (e) => {
//     setSearch(e.target.value);
//     setCurrentPage(1);
//   };

//   const handleEntriesChange = (e) => {
//     setEntries(Number(e.target.value));
//     setCurrentPage(1);
//   };

//   const handleDropdownToggle = (index) => {
//     setActiveDropdown(activeDropdown === index ? null : index);
//   };

//   const handlePageChange = (pageNumber) => {
//     if (pageNumber >= 1 && pageNumber <= totalPages) {
//       setCurrentPage(pageNumber);
//     }
//   };

//   const filteredUsers = users.filter((user) =>
//     user.name.toLowerCase().includes(search.toLowerCase())
//   );

//   const renderPaginationButtons = () => {
//     const maxVisibleButtons = 5;
//     let startPage = Math.max(1, currentPage - Math.floor(maxVisibleButtons / 2));
//     let endPage = startPage + maxVisibleButtons - 1;

//     if (endPage > totalPages) {
//       endPage = totalPages;
//       startPage = Math.max(1, endPage - maxVisibleButtons + 1);
//     }

//     const buttons = [];

//     if (currentPage > 1) {
//       buttons.push(
//         <button key="prev" onClick={() => handlePageChange(currentPage - 1)}>
//           Previous
//         </button>
//       );
//     } else {
//       buttons.push(
//         <button key="prev" disabled>
//           Previous
//         </button>
//       );
//     }

//     for (let i = startPage; i <= endPage; i++) {
//       buttons.push(
//         <span
//           key={i}
//           className={`page-number ${i === currentPage ? 'active' : ''}`}
//         >
//           {i}
//         </span>
//       );
//     }

//     if (currentPage < totalPages) {
//       buttons.push(
//         <button key="next" onClick={() => handlePageChange(currentPage + 1)}>
//           Next
//         </button>
//       );
//     } else {
//       buttons.push(
//         <button key="next" disabled>
//           Next
//         </button>
//       );
//     }

//     return buttons;
//   };

//   return (
//     <>
//       <Navbar />
//       <div className="manage-user-container">
//         <div className="header">
//           <div className="header-controls">
//             {/* <label>Show entries: </label>
//             <select value={entries} onChange={handleEntriesChange} className="entries-select">
//               <option value="5">5</option>
//               <option value="10">10</option>
//               <option value="20">20</option>
//             </select> */}
//           </div>
//           <input
//             type="text"
//             placeholder="Search..."
//             value={search}
//             onChange={handleSearch}
//             className="search-input"
//           />
//         </div>
//         {loading ? (
//           <div className="loading-indicator">Loading...</div>
//         ) : (
//           <table>
//             <thead>
//               <tr>
//                 <th>NAME</th>
//                 <th>EMAIL</th>
//                 <th>TYPE</th>
//                 <th>ACTIONS</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredUsers.map((user, index) => (
//                 <tr key={index}>
//                   <td>{user.name}</td>
//                   <td>{user.email}</td>
//                   <td>{user.type}</td>
//                   <td>
//                     <div className="dropdown">
//                       <button
//                         className="dots-button"
//                         onClick={() => handleDropdownToggle(index)}
//                       >
//                         &#x22EE;
//                       </button>
//                       {activeDropdown === index && (
//                         <div className="dropdown-menu">
//                           <button className="dropdown-item">Edit</button>
//                           <button className="dropdown-item">Delete</button>
//                           <button className="dropdown-item">Reset Password</button>
//                         </div>
//                       )}
//                     </div>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         )}

//         {/* Pagination Controls */}
//         <div className="pagination">
//           {renderPaginationButtons()}
//         </div>
//       </div>
//     </>
//   );
// };

// export default ManageUser;






















/////////////------------------->>>>>>>>Edit Dialouge
import React, { useState, useEffect } from "react";
import "./ManageUser.css";
import Navbar from "./Navbar";

const ManageUser = () => {
  const [search, setSearch] = useState("");
  const [entries, setEntries] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);


  // State for managing modal visibility and form data
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // Function to get token from localStorage or sessionStorage
  const getToken = () => {
    return localStorage.getItem("token") || sessionStorage.getItem("token");
  };

  // Function to fetch users
  const fetchUsers = async (page = 1) => {
    setLoading(true);
    const token = getToken();
    if (!token) {
      console.error("No token found");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `https://beta.jsonscommunications.com/api/auth/users?page=${page}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUsers(data.users.data);
        setTotalPages(data.users.last_page);
      } else {
        console.error(`Error fetching users: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const token = getToken();
    if (token) {
      fetchUsers(currentPage);
    }
  }, [currentPage, entries]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleEntriesChange = (e) => {
    setEntries(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleDropdownToggle = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(search.toLowerCase())
  );

  const renderPaginationButtons = () => {
    const maxVisibleButtons = 5;
    let startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisibleButtons / 2)
    );
    let endPage = startPage + maxVisibleButtons - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxVisibleButtons + 1);
    }

    const buttons = [];

    if (currentPage > 1) {
      buttons.push(
        <button key="prev" onClick={() => handlePageChange(currentPage - 1)}>
          Previous
        </button>
      );
    } else {
      buttons.push(
        <button key="prev" disabled>
          Previous
        </button>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <span
          key={i}
          className={`page-number ${i === currentPage ? "active" : ""}`}
        >
          {i}
        </span>
      );
    }

    if (currentPage < totalPages) {
      buttons.push(
        <button key="next" onClick={() => handlePageChange(currentPage + 1)}>
          Next
        </button>
      );
    } else {
      buttons.push(
        <button key="next" disabled>
          Next
        </button>
      );
    }

    return buttons;
  };

  // Handle edit button click
  const handleEdit = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  // Handle update user
  const handleUpdate = async () => {
    const token = getToken(); // Retrieve the token from storage

    if (!token) {
      console.error("No token found");
      return;
    }

    const url = `https://beta.jsonscommunications.com/api/auth/users/${selectedUser.id}`;

    console.log("URL IS ",url)

    const payload = new URLSearchParams();
    payload.append("name", selectedUser.name);
    payload.append("email", selectedUser.email);
    payload.append("role", selectedUser.type);

    try {
      setIsUpdating(true)
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          Accept:"application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        body: payload.toString(),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("User updated successfully:", data);
        setIsModalOpen(false);
        fetchUsers(currentPage); // Refresh user list after update
      } else {
        console.error(`Error updating user: ${response.status}`);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }finally{
      setIsUpdating(false)
    }
  };

  return (
    <>
      <Navbar />
      <div className="manage-user-container">
        <div className="header">
          {/* <div className="header-controls">
            <label>Show entries: </label>
            <select
              value={entries}
              onChange={handleEntriesChange}
              className="entries-select"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
            </select>
          </div> */}
          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={handleSearch}
            className="search-input"
          />
        </div>

        {loading ? (
          <div className="loading-indicator">Loading...</div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>TYPE</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user, index) => (
                <tr key={index}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.type}</td>
                  <td>
                    <div className="dropdown">
                      <button
                        className="dots-button"
                        onClick={() => handleDropdownToggle(index)}
                      >
                        &#x22EE;
                      </button>
                      {activeDropdown === index && (
                        <div className="dropdown-menu">
                          <button
                            className="dropdown-item"
                            onClick={() => handleEdit(user)}
                          >
                            Edit
                          </button>
                          <button className="dropdown-item">Delete</button>
                          <button className="dropdown-item">
                            Reset Password
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* Pagination Controls */}
        <div className="pagination">{renderPaginationButtons()}</div>

        {/* Edit User Modal */}
        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <h2>Edit User</h2>
              <div className="input-group">
                <label>Name</label>
                <input
                  type="text"
                  value={selectedUser.name}
                  onChange={(e) =>
                    setSelectedUser({ ...selectedUser, name: e.target.value })
                  }
                />
              </div>
              <div className="input-group">
                <label>Email</label>
                <input
                  type="email"
                  value={selectedUser.email}
                  onChange={(e) =>
                    setSelectedUser({ ...selectedUser, email: e.target.value })
                  }
                />
              </div>
              <div className="input-group">
                <label>User Role</label>
                <select
                  value={selectedUser.type}
                  onChange={(e) =>
                    setSelectedUser({ ...selectedUser, type: e.target.value })
                  }
                >
                  <option value="Accountant">Accountant</option>
                  <option value="Agent">Agent</option>
                  <option value="Avatar">Avatar</option>
                  <option value="Closer">Closer</option>
                  <option value="Dept. Manager">Dept. Manager</option>
                  <option value="Dialer Support">Dialer Support</option>
                  <option value="Director">Director</option>
                  <option value="HR">HR</option>
                  <option value="IT">IT</option>
                  <option value="Project Manager">Project Manager</option>
                  <option value="QA">QA</option>
                  <option value="QA Manager">QA Manager</option>
                  <option value="Team Lead">Team Lead</option>
                  <option value="Voice">Voice</option>
                </select>
              </div>
              <div className="modal-actions">
                <button onClick={() => setIsModalOpen(false)}>Cancel</button>
                <button onClick={handleUpdate} disabled={isUpdating}>{isUpdating ? "Updating..." : "Update"}</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ManageUser;
