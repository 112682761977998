import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import "./Dashboard.css";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from "recharts";

const Dashboard = () => {
  const initialHours = 10;
  const initialMinutes = 15;
  const initialSeconds = 0;
  const [timer, setTimer] = useState(initialHours * 3600 + initialMinutes * 60 + initialSeconds);
  const [isRunning, setIsRunning] = useState(false);
  const [buttonText, setButtonText] = useState("Clock In");

  useEffect(() => {
    let interval;
    if (isRunning && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRunning, timer]);

  const radius = 80;
  const circumference = 2 * Math.PI * radius;
  const progress = ((initialHours * 3600 + initialMinutes * 60 + initialSeconds - timer) / (initialHours * 3600 + initialMinutes * 60 + initialSeconds)) * circumference;

  const handleClick = () => {
    if (!isRunning) {
      setIsRunning(true);
      setButtonText(`${Math.floor(timer / 3600)}:${Math.floor((timer % 3600) / 60).toString().padStart(2, '0')}:${(timer % 60).toString().padStart(2, '0')}`);
    }
  };

  const data = [
    { month: "Jan", earnings: 3000, rejected: 500 },
    { month: "Feb", earnings: 4000, rejected: 600 },
    { month: "Mar", earnings: 2000, rejected: 300 },
    { month: "Apr", earnings: 5000, rejected: 700 },
    { month: "May", earnings: 7000, rejected: 800 },
    { month: "Jun", earnings: 6000, rejected: 500 },
    { month: "Jul", earnings: 8000, rejected: 900 },
    { month: "Aug", earnings: 9000, rejected: 700 },
    { month: "Sep", earnings: 6500, rejected: 400 },
    { month: "Oct", earnings: 8500, rejected: 600 },
    { month: "Nov", earnings: 9000, rejected: 800 },
    { month: "Dec", earnings: 7500, rejected: 500 },
  ];

  // Dummy campaign data
  const campaigns = [
    { id: 1, name: "Insurance", startDate: "2024-06-01", endDate: "2024-06-30" },
    { id: 2, name: "Medical", startDate: "2024-08-15", endDate: "2024-09-10" },
    { id: 3, name: "Black Friday Campaign", startDate: "2024-11-01", endDate: "2024-11-30" },
    { id: 4, name: "Holiday Special", startDate: "2024-12-01", endDate: "2024-12-31" },
  ];

  return (
    <div>
      <Navbar />
      <div className="dashboard-container">
        <div className="dashboard-cards">
          <div className="card">Earnings: <span>$198k</span></div>
          <div className="card">Balance: <span>$2.4k</span></div>
          <div className="card">Total Sales: <span>$89k</span></div>
        </div>

        <div className="dashboard-main">
          <div className="overview">
            <h3>Overview</h3>
            <ResponsiveContainer width="100%" height={250}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="earnings" fill="#222e47" name="Sales" />
                <Bar dataKey="rejected" fill="red" name="Rejected" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          
          <div className="timer-section">
            <span style={{fontWeight:"bold", fontSize:18, marginTop:-30, marginBottom:20, color:'#222e47'}}>OFFICE TIME</span>
            <div className="timer-wrapper" onClick={handleClick} style={{ cursor: "pointer" }}>
              <svg width="180" height="180">
                <circle
                  className="timer-background"
                  cx="90"
                  cy="90"
                  r={radius}
                  fill="transparent"
                  stroke="#e6e6e6"
                  strokeWidth="6"
                />
                <circle
                  className="timer-progress"
                  cx="90"
                  cy="90"
                  r={radius}
                  fill="transparent"
                  strokeWidth="6"
                  strokeDasharray={circumference}
                  strokeDashoffset={progress}
                  style={{
                    stroke: `url(#gradient)`,
                  }}
                />
                <defs>
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="red" />
                    {/* <stop offset="33%" stopColor="yellow" />
                    <stop offset="66%" stopColor="orange" /> */}
                    <stop offset="60%" stopColor="#222e47" />
                  </linearGradient>
                </defs>
              </svg>
              <div className="timer-text">
                {isRunning ? (timer > 0 ? `${Math.floor(timer / 3600)}:${Math.floor((timer % 3600) / 60).toString().padStart(2, '0')}:${(timer % 60).toString().padStart(2, '0')}` : "00:00:00") : buttonText}
              </div>
            </div>
          </div>
        </div>

        <div className="campaigns-section">
          <h3>Campaigns</h3>
          <div className="campaigns-list-wrapper">
            <table className="campaigns-list">
              <thead>
                <tr>
                  <th>Campaigns</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                </tr>
              </thead>
              <tbody>
                {campaigns.map((campaign) => (
                  <tr key={campaign.id}>
                    <td>{campaign.name}</td>
                    <td>{campaign.startDate}</td>
                    <td>{campaign.endDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
