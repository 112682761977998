import React, { useState } from 'react';
import Navbar from './Navbar';
import QALinks from './QALinks';
import './AgentStats.css';

const AgentStats = () => {
  const initialData = [
    { qaPerson: 'Ammad Hafeez', pending: 47, approved: 0, rejected: 0, onReview: 0, noRecording: 0, total: 47 },
    { qaPerson: 'Daniyal Hussain', pending: 100, approved: 0, rejected: 0, onReview: 0, noRecording: 0, total: 100 },
    { qaPerson: 'Raisa Malik', pending: 58, approved: 18, rejected: 24, onReview: 0, noRecording: 0, total: 100 },
  ];

  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filtered, setFiltered] = useState(false);

  const handleFilter = () => {
    if (startDate && endDate) {
      // For now, using initialData since there's no date-specific filtering logic.
      setData(initialData);
      setFiltered(true);
    }
  };

  return (
    <div>
      <Navbar />
      <QALinks />
      <div className="agent-stats-content">
        <h1>Agent Statistics</h1>
        <div className="filter-form">
          <div className="filter-input-group">
            <label htmlFor="start-date">Start Date:</label>
            <input
              id="start-date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="filter-input-group">
            <label htmlFor="end-date">End Date:</label>
            <input
              id="end-date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <button onClick={handleFilter} className="filter-button">Filter</button>
        </div>
        {filtered && data.length > 0 && (
          <>
            <div className="table-container">
              <table className="agent-stats-table">
                <thead>
                  <tr>
                    <th>QA Person</th>
                    <th>Pending</th>
                    <th>Approved</th>
                    <th>Rejected</th>
                    <th>On Review</th>
                    <th>No Recording</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      <td>{row.qaPerson}</td>
                      <td>{row.pending}</td>
                      <td>{row.approved}</td>
                      <td>{row.rejected}</td>
                      <td>{row.onReview}</td>
                      <td>{row.noRecording}</td>
                      <td>{row.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AgentStats;
