import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./VoiceBar.css";

const VoiceBar4 = () => {
  const location = useLocation();

  return (
    <nav className="maindivbar">
      <Link
        to="/dialer4/agent4"
        className={`linkbar ${location.pathname === "/dialer4/agent4" ? "active" : ""}`}
      >
        Agent 4
      </Link>
      <Link
        to="/dialer4/james"
        className={`linkbar ${location.pathname === "/dialer4/james" ? "active" : ""}`}
      >
        James
      </Link>
      <Link
        to="/dialer4/105"
        className={`linkbar ${location.pathname === "/dialer4/105" ? "active" : ""}`}
      >
        David Miller
      </Link>
      <Link
        to="/dialer4/helli"
        className={`linkbar ${location.pathname === "/dialer4/helli" ? "active" : ""}`}
      >
        Helli Wilson
      </Link>
      <Link
        to="/dialer4/henna"
        className={`linkbar ${location.pathname === "/dialer4/henna" ? "active" : ""}`}
      >
        Henna Walker
      </Link>
      <Link
        to="/dialer4/cindy"
        className={`linkbar ${location.pathname === "/dialer4/cindy" ? "active" : ""}`}
      >
        Cindy Jones
      </Link>
      <Link
        to="/dialer4/selvey"
        className={`linkbar ${location.pathname === "/dialer4/selvey" ? "active" : ""}`}
      >
        Selvey
      </Link>
    </nav>
  );
};

export default VoiceBar4;
