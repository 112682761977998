import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import TeamManagementLinks from './TeamManagementLinks';
import './CreateTeam.css';

const CreateTeam = () => {
  // State for dropdown data
  const [teamLeaders, setTeamLeaders] = useState([]);
  const [teamAgents, setTeamAgents] = useState([]);

  // Dummy data to simulate API response
  const dummyTeamLeaders = [
    { id: 1, name: 'Bill Becker' },
    { id: 2, name: 'Alice Johnson' },
    { id: 3, name: 'Michael Smith' }
  ];

  const dummyTeamAgents = [
    { id: 1, name: 'Usama Maqaddar (Voice) - Team: No Team' },
    { id: 2, name: 'Sarah Khan (Chat) - Team: A' },
    { id: 3, name: 'John Doe (Voice) - Team: B' },
    { id: 4, name: 'Emily Brown (Chat) - Team: C' }
  ];

  // Simulate API call with useEffect
  useEffect(() => {
    // Simulate fetching data from an API
    setTeamLeaders(dummyTeamLeaders);
    setTeamAgents(dummyTeamAgents);
  }, []);

  return (
    <div>
      <Navbar />
      <TeamManagementLinks />
      <div className="create-team-container">
        <h2>Create Team</h2>
        <form className="create-team-form">
          <label htmlFor="teamName">Team Name</label>
          <input type="text" id="teamName" placeholder="Enter team name" />

          <label htmlFor="teamLeader">Team Leader</label>
          <select id="teamLeader">
            {teamLeaders.map((leader) => (
              <option key={leader.id} value={leader.name}>
                {leader.name}
              </option>
            ))}
          </select>

          <label htmlFor="teamAgents">Team Agents</label>
          <select id="teamAgents">
            {teamAgents.map((agent) => (
              <option key={agent.id} value={agent.name}>
                {agent.name}
              </option>
            ))}
          </select>

          <button type="submit" className="create-team-button">Create Team</button>
        </form>
      </div>
    </div>
  );
};

export default CreateTeam;
