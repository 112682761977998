import React, { useRef, useState } from "react";
import "./Henna.css";

// Import all audio files
import Great from "./Henna/Great.wav";
import Okay from "./Henna/Okay.wav";
import Yes from "./Henna/Yes.wav";
import No from "./Henna/No.wav";
import NoProblem from "./Henna/NoProblem.wav";
import HearMe from "./Henna/HearMe.wav";
import Repeat from "./Henna/Repeat.wav";
import IsThatOkay from "./Henna/IsThatOkay.wav";
import Laugh from "./Henna/Laugh.wav";
import Callbackpitch from "./Henna/Callbackpitch.wav";
import Pitch2 from "./Henna/Pitch2.wav";
import Plan from "./Henna/Plan.wav";
import InfoFrom from "./Henna/InfoFrom.wav";
import Rescue1 from "./Henna/Rescue1.wav";
import AlreadyHvePlan from "./Henna/AlreadyHvePlan.wav";
import DNC from "./Henna/DNC.wav";
import Hello from "./Henna/Hello.wav";
import Greetings from "./Henna/Greetings.wav";
import Pitch1 from "./Henna/Pitch1.wav";
import HowOldAreYou from "./Henna/howoldareyou.wav";
import Cost01 from "./Henna/Cost01.wav";
import Beneficiary from "./Henna/Beneficiary.wav";
import Health from "./Henna/Health.wav";
import AccountDiscount from "./Henna/AccountDiscount.wav";
import PlanDetails from "./Henna/PlanDetails.wav";
import Smoker from "./Henna/Smoker.wav";
import Weather from "./Henna/weather.wav";
import TransferDetail from "./Henna/TransferDetail.wav";
import CanISaySomething from "./Henna/Can I saysomething.wav";
import TransferDetail2 from "./Henna/TransferDetail2.wav";
import GladToHereThat from "./Henna/GladToHereThat.wav";
import SorryToHear from "./Henna/SorryToHear.wav";
import ThanksForAsking from "./Henna/ThanksForAsking.wav";
import CallAbout from "./Henna/CallAbout.wav";
import Affordability from "./Henna/Affordability.wav";
import NotInterested from "./Henna/Not Interested.wav";
import NotInterested2 from "./Henna/Not interested2.wav";
import AlreadyHvePlan2 from "./Henna/AlreadyHvePlan2.wav";
import Age from "./Henna/Age.wav";
import AgeRebuttal from "./Henna/AgeRebuttal.wav";
import Discount01 from "./Henna/Discount01.wav";
import SendInMail from "./Henna/SendInMail.wav";
import Busy from "./Henna/Busy.wav";
import Location from "./Henna/Location.wav";
import AgentName from "./Henna/AgentName.wav";
import CompanyName from "./Henna/CompanyName.wav";
import SetCallBack from "./Henna/SetCallBack.wav";
import CalledBefore from "./Henna/CalledBefore.wav";
import RealPerson from "./Henna/RealPerson.wav";
import FewSeconds from "./Henna/FewSeconds.wav";
import HoldOn from "./Henna/HoldOn.wav";
import TransferHold from "./Henna/TransferHold.wav";
import AddCoverage from "./Henna/AddCoverage.wav";
import HealthRebuttal from "./Henna/HealthRebuttal.wav";
import Goodbye from "./Henna/Goodbye.wav";
import Greeting from "./Henna/Greeting.wav";
import SmokeDiscount from "./Henna/SmokeDiscount.wav";
import VoiceBar from "./VoiceBar";

const Henna = () => {
  const audioRef = useRef(new Audio());
  const [isPaused, setIsPaused] = useState(false);

  const playAudio = (audioSrc) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    audioRef.current = new Audio(audioSrc);
    audioRef.current.play();
    setIsPaused(false);
  };

  const pauseVoice = () => {
    if (audioRef.current && !audioRef.current.paused) {
      audioRef.current.pause();
      setIsPaused(true);
    }
  };

  const resumeVoice = () => {
    if (audioRef.current && isPaused) {
      audioRef.current.play();
      setIsPaused(false);
    }
  };

  const stopVoice = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPaused(false);
    }
  };

  const leftColumnButtons = [
    { label: "Great", audio: Great },
    { label: "Okay", audio: Okay },
    { label: "Yes", audio: Yes },
    { label: "No", audio: No },
    { label: "No Problem", audio: NoProblem },
    { label: "Hear Me", audio: HearMe },
    { label: "Repeat", audio: Repeat },
    { label: "Is That Okay", audio: IsThatOkay },
    { label: "Laugh", audio: Laugh },
    { label: "Callback pitch", audio: Callbackpitch },
    { label: "Pitch 2", audio: Pitch2 },
    { label: "Plan", audio: Plan },
    { label: "Info From", audio: InfoFrom },
    { label: "Rescue 1", audio: Rescue1 },
    { label: "Already Hve Plan", audio: AlreadyHvePlan },
    { label: "DNC", audio: DNC },
  ];

  const middleLeftColumnButtons = [
    { label: "Hello", audio: Hello },
    { label: "Greetings", audio: Greetings },
    { label: "Pitch 1", audio: Pitch1 },
    { label: "how old are you", audio: HowOldAreYou },
    { label: "Cost 01", audio: Cost01 },
    { label: "Beneficiary", audio: Beneficiary },
    { label: "Health", audio: Health },
    { label: "Account Discount", audio: AccountDiscount },
    { label: "Plan Details", audio: PlanDetails },
    { label: "Smoker", audio: Smoker },
    { label: "weather", audio: Weather },
    { label: "Transfer Detail", audio: TransferDetail },
  ];

  const middleRightColumnButtons = [
    { label: "Can I say smthn", audio: CanISaySomething },
    { label: "Transfer Detail 2", audio: TransferDetail2 },
    { label: "Glad To Here That", audio: GladToHereThat },
    { label: "Sorry To Hear", audio: SorryToHear },
    { label: "Thanks For Asking", audio: ThanksForAsking },
    { label: "Call About", audio: CallAbout },
    { label: "Affordability", audio: Affordability },
    { label: "Not Interested", audio: NotInterested },
    { label: "Not interested 2", audio: NotInterested2 },
    { label: "Already Hve Plan 2", audio: AlreadyHvePlan2 },
    { label: "Age", audio: Age },
    { label: "Age Rebuttal", audio: AgeRebuttal },
  ];

  const rightColumnButtons = [
    { label: "Discount 01", audio: Discount01 },
    { label: "Send In Mail", audio: SendInMail },
    { label: "Busy", audio: Busy },
    { label: "Location", audio: Location },
    { label: "Agent Name", audio: AgentName },
    { label: "Company Name", audio: CompanyName },
    { label: "Set Call Back", audio: SetCallBack },
    { label: "Called Before", audio: CalledBefore },
    { label: "Real Person", audio: RealPerson },
    { label: "Few Seconds", audio: FewSeconds },
    { label: "Hold On", audio: HoldOn },
    { label: "Transfer Hold", audio: TransferHold },
    { label: "Add Coverage", audio: AddCoverage },
    { label: "Health Rebuttal", audio: HealthRebuttal },
    { label: "Goodbye", audio: Goodbye },
    { label: "Greeting", audio: Greeting },
    { label: "Smoke Discount", audio: SmokeDiscount },
  ];

  return (
    <div className="henna-container">
      {/* <div style={{marginRight:50}}>
        <VoiceBar />
      </div> */}
      <div>
        <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <button
              className="stop-button"
              onClick={resumeVoice}
              style={{ backgroundColor: "green" }}
            >
              RESUME
            </button>
            <button className="stop-button" onClick={pauseVoice}>
              PAUSE
            </button>
            <button
              className="stop-button"
              onClick={stopVoice}
              style={{ backgroundColor: "red" }}
            >
              STOP
            </button>
          </div>
        <div className="grid">
          <div className="column">
            {leftColumnButtons.map(({ label, audio }) => (
              <button
                key={label}
                onClick={() => playAudio(audio)}
                className="audio-button blue"
              >
                {label}
              </button>
            ))}
          </div>
          <div className="column middle">
            {middleLeftColumnButtons.map(({ label, audio }) => (
              <button
                key={label}
                onClick={() => playAudio(audio)}
                className="audio-button gray"
              >
                {label}
              </button>
            ))}
          </div>
          <div className="column middle">
            {middleRightColumnButtons.map(({ label, audio }) => (
              <button
                key={label}
                onClick={() => playAudio(audio)}
                className="audio-button gray"
              >
                {label}
              </button>
            ))}
          </div>
          <div className="column">
            {rightColumnButtons.map(({ label, audio }) => (
              <button
                key={label}
                onClick={() => playAudio(audio)}
                className="audio-button blue"
              >
                {label}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Henna;
