import React, { useRef, useState } from 'react';
import './105.css';

// Import all sound files
// Derick Miler
import age from './105/Derick Miler/Age.mp3';
import beneficiary from './105/Derick Miler/Beneficiary.mp3';
import callBackPitchDerick from './105/Derick Miler/Call_back_pitch.mp3';
import closerPitch from './105/Derick Miler/Closer_pitch.mp3';
import decisionMaker from './105/Derick Miler/Decision_maker.mp3';
import derickMiller from './105/Derick Miler/Derick_Miller.mp3';
import derickMillerGree from './105/Derick Miler/DerickMilerGree.mp3';
import dncDerick from './105/Derick Miler/DNC.mp3';
import gladToHearDerick from './105/Derick Miler/Glad_to_hear.mp3';
import healthDerick from './105/Derick Miler/Health.mp3';
import helloDerick from './105/Derick Miler/Hello.mp3';
import pitch1 from './105/Derick Miler/Pitch_1.mp3';
import plan from './105/Derick Miler/Plan.mp3';
import planDetails from './105/Derick Miler/Plan_details.mp3';
import smoker1 from './105/Derick Miler/Smoker1.mp3';
import socialSecurity from './105/Derick Miler/Social_security.mp3';
import transferDetail1 from './105/Derick Miler/Transfer_detail 1.mp3';
import transferDetail2 from './105/Derick Miler/Transfer_detail_2.mp3';
import transferHold from './105/Derick Miler/Transfer_hold.mp3';
import transferIntro from './105/Derick Miler/Transfer_intro.mp3';
import weatherDerick from './105/Derick Miler/Weather.mp3';

// James Anderson
import accountDiscount from './105/James Anderson/Account_discount.mp3';
import banking from './105/James Anderson/Banking.mp3';
import callBackPitchJames from './105/James Anderson/call_back Pitch.mp3';
import canYouHearMe from './105/James Anderson/Can You Hear_me.mp3';
import canISaySomething from './105/James Anderson/Can_i_say_something.mp3';
import greatJames from './105/James Anderson/Great.mp3';
import helloJames from './105/James Anderson/Hello.mp3';
import isThatOkayJames from './105/James Anderson/Is_that_okay.mp3';
import jamesAnderson from './105/James Anderson/James_anderson.mp3';
import jamesAndersonGr from './105/James Anderson/James_AndersonGr.mp3';
import noJames from './105/James Anderson/No.mp3';
import noProblemJames from './105/James Anderson/No_problem.mp3';
import okay from './105/James Anderson/Okay.mp3';
import repeatJames from './105/James Anderson/Repeat.mp3';
import retirement from './105/James Anderson/Retirement.mp3';
import sorryToHear from './105/James Anderson/Soryy_to_hear.mp3';
import thanksForPatience from './105/James Anderson/Thanks 4 patience.mp3';
import thanksForTime from './105/James Anderson/Thanks for time.mp3';
import thanksForAsking from './105/James Anderson/Thanks_for_asking.mp3';
import thankYou from './105/James Anderson/Thankyou.mp3';
import yes from './105/James Anderson/Yess.mp3';

// Mark Smith
import tenToFifteenMins from './105/Mark Smith/10-15 mins.mp3';
import backgroundNoise from './105/Mark Smith/Backgrnd noise.mp3';
import busyMark from './105/Mark Smith/Busy.mp3';
import callBackPitchMark from './105/Mark Smith/Call_back Pitch.mp3';
import calledBefore from './105/Mark Smith/Called_before.mp3';
import directExpress from './105/Mark Smith/Direct_express.mp3';
import fewSeconds from './105/Mark Smith/Few_seconds.mp3';
import goodbye from './105/Mark Smith/Goodbye.mp3';
import helloMark from './105/Mark Smith/Hello.mp3';
import holdForMeMark from './105/Mark Smith/Hold_for_me.mp3';
import infoForm from './105/Mark Smith/Info_form.mp3';
import isThatOkayMark from './105/Mark Smith/Is_that_okay2.mp3';
import laugh from './105/Mark Smith/Laugh.mp3';
import location from './105/Mark Smith/Location.mp3';
import markSmithGreet from './105/Mark Smith/Mark_smith_greet.mp3';
import realPersonMark from './105/Mark Smith/Real_person.mp3';
import rescue from './105/Mark Smith/Rescue.mp3';
import scamCallerId from './105/Mark Smith/Scam_caller_id.mp3';
import sendInWriting from './105/Mark Smith/Send_in_writting.mp3';
import setCallBack from './105/Mark Smith/Set_call_back.mp3';
import marksmith from './105/Mark Smith/Mark_Smith.mp3'

// Robert Wolf
import addCoverage from './105/Robert Wolf/Add coverage.mp3';
import affordability from './105/Robert Wolf/Affordability.mp3';
import ageRebuttal from './105/Robert Wolf/Age_rebuttal.mp3';
import alreadyHavePlan from './105/Robert Wolf/Already_hav plan.mp3';
import callAbout from './105/Robert Wolf/Call_about.mp3';
import callBackPitchRobert from './105/Robert Wolf/Call_back Pitch.mp3';
import companyName from './105/Robert Wolf/Company_name.mp3';
import costRebuttal from './105/Robert Wolf/Cost Rebuttal.mp3';
import discount from './105/Robert Wolf/Discount.mp3';
import existingPolicy from './105/Robert Wolf/Existing_policy.mp3';
import funeralCost from './105/Robert Wolf/Funereal_cost.mp3';
import healthRebuttal from './105/Robert Wolf/Health_rebuttal.mp3';
import helloRobert from './105/Robert Wolf/Hello.mp3';
import lowPremiums from './105/Robert Wolf/Low Premiums.mp3';
import medication from './105/Robert Wolf/Medication.mp3';
import notInterested1 from './105/Robert Wolf/Not_interested.mp3';
import notInterested2 from './105/Robert Wolf/Not_interested2.mp3';
import policyTenure from './105/Robert Wolf/Policy_tenure.mp3';
import robertWolf from './105/Robert Wolf/Robert_wolf.mp3';
import robertWolfGreet from './105/Robert Wolf/Robert_WolfGreet.mp3';
import smokeDiscount from './105/Robert Wolf/Smoke_discount.mp3';
import VoiceBar from './VoiceBar';


const A105 = () => {
  const [isPaused, setIsPaused] = useState(false);
  const audioRef = useRef(new Audio());

  const playVoice = (audioFile) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    audioRef.current = new Audio(audioFile);
    audioRef.current.play();
    setIsPaused(false);
  };

  const pauseVoice = () => {
    if (audioRef.current && !isPaused) {
      audioRef.current.pause();
      setIsPaused(true);
    }
  };

  const resumeVoice = () => {
    if (audioRef.current && isPaused) {
      audioRef.current.play();
      setIsPaused(false);
    }
  };

  const stopVoice = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  const buttonDataColumns = [
    [// Derick Miller
      { label: 'Hello', color: '#b75c30', audioSrc: helloDerick },
      { label: 'DerickMilerGree', color: '#b75c30', audioSrc: derickMillerGree },
      { label: 'Call Back Pitch', color: '#b75c30', audioSrc: callBackPitchDerick },
      { label: 'Derick Miller', color: '#b75c30', audioSrc: derickMiller },
      { label: 'Pitch 1', color: '#b75c30', audioSrc: pitch1 },
      { label: 'Closer Pitch', color: '#b75c30', audioSrc: closerPitch },
      { label: 'Age', color: '#b75c30', audioSrc: age },
      { label: 'Plan', color: '#b75c30', audioSrc: plan },
      { label: 'Plan Details', color: '#b75c30', audioSrc: planDetails },
      { label: 'Beneficiary', color: '#b75c30', audioSrc: beneficiary },
      { label: 'Health', color: '#b75c30', audioSrc: healthDerick },
      { label: 'Smoker 1', color: '#b75c30', audioSrc: smoker1 },
      { label: 'Transfer Detail 1', color: '#b75c30', audioSrc: transferDetail1 },
      { label: 'Transfer Detail 2', color: '#b75c30', audioSrc: transferDetail2 },
      { label: 'Weather', color: '#b75c30', audioSrc: weatherDerick },
      { label: 'Transfer Hold', color: '#b75c30', audioSrc: transferHold },
      { label: 'Transfer intro', color: '#b75c30', audioSrc: transferIntro },
      { label: 'Decision Maker', color: '#b75c30', audioSrc: decisionMaker },
      { label: 'Glad 2 Hear', color: '#b75c30', audioSrc: gladToHearDerick },
      { label: 'DNC', color: 'red', audioSrc: dncDerick },
      { label: 'Social Security', color: '#b75c30', audioSrc: socialSecurity },
    ],
    [// James Anderson
      { label: 'Hello', color: '#226465', audioSrc: helloJames },
      { label: 'James Greeting', color: '#226465', audioSrc: jamesAndersonGr },
      { label: 'Call Back Pitch', color: '#226465', audioSrc: callBackPitchJames },
      { label: 'James Anderson', color: '#226465', audioSrc: jamesAnderson },
      { label: 'Thanks 4 Asking', color: '#226465', audioSrc: thanksForAsking },
      { label: 'Sorry 2 Hear', color: '#226465', audioSrc: sorryToHear },
      { label: 'OKay', color: '#226465', audioSrc: okay },
      { label: 'Great', color: '#226465', audioSrc: greatJames },
      { label: 'Yes', color: '#226465', audioSrc: yes },
      { label: 'No', color: '#226465', audioSrc: noJames },
      { label: 'No Problem', color: '#226465', audioSrc: noProblemJames },
      { label: 'Thank You', color: '#226465', audioSrc: thankYou },
      { label: 'Can u hear me', color: '#226465', audioSrc: canYouHearMe },
      { label: 'Repeat', color: '#226465', audioSrc: repeatJames },
      { label: 'Can i say smthng', color: '#226465', audioSrc: canISaySomething },
      { label: 'Is that OK', color: '#226465', audioSrc: isThatOkayJames },
      { label: 'Thanks4Patience', color: '#226465', audioSrc: thanksForPatience },
      { label: 'Banking', color: '#226465', audioSrc: banking },
      { label: 'Thanks 4 Time', color: '#226465', audioSrc: thanksForTime },
      { label: 'Account Discount', color: '#226465', audioSrc: accountDiscount },
      { label: 'Retirement', color: '#226465', audioSrc: retirement },
    ],
    [// Robert Wolf
      { label: 'Hello', color: '#333d45', audioSrc: helloRobert },
      { label: 'Robert Wolf Greet', color: '#333d45', audioSrc: robertWolfGreet },
      { label: 'Call Back Pitch', color: '#333d45', audioSrc: callBackPitchRobert },
      { label: 'Robert Wolf', color: '#333d45', audioSrc: robertWolf },
      { label: 'Call About', color: '#333d45', audioSrc: callAbout },
      { label: 'Funeral Cost', color: '#333d45', audioSrc: funeralCost },
      { label: 'Low Premium', color: '#333d45', audioSrc: lowPremiums },
      { label: 'Age Rebuttal', color: '#333d45', audioSrc: ageRebuttal },
      { label: 'Not Interested', color: '#333d45', audioSrc: notInterested1 },
      { label: 'Not Interested 2', color: '#333d45', audioSrc: notInterested2 },
      { label: 'Cost Rebuttal', color: '#333d45', audioSrc: costRebuttal },
      { label: 'Already Hv Plan', color: '#333d45', audioSrc: alreadyHavePlan },
      { label: 'Affordibility', color: '#333d45', audioSrc: affordability },
      { label: 'Discount', color: '#333d45', audioSrc: discount },
      { label: 'Smoker Discount', color: '#333d45', audioSrc: smokeDiscount },
      { label: 'Add Coverage', color: '#333d45', audioSrc: addCoverage },
      { label: 'Health Rebuttal', color: '#333d45', audioSrc: healthRebuttal },
      { label: 'Medication', color: '#333d45', audioSrc: medication },
      { label: 'Policy Tenure', color: '#333d45', audioSrc: policyTenure },
      { label: 'Company Name', color: '#333d45', audioSrc: companyName },
      { label: 'Existing Policy', color: '#333d45', audioSrc: existingPolicy },
    ],
    [// Mark Smith
      { label: 'Hello', color: '#08a472', audioSrc: helloMark },
      { label: 'Mark Smith Greet', color: '#08a472', audioSrc: markSmithGreet },
      { label: 'Call back Pitch', color: '#08a472', audioSrc: callBackPitchMark },
      { label: 'Mark Smith', color: '#08a472', audioSrc: marksmith },
      { label: 'Location', color: '#08a472', audioSrc: location },
      { label: 'Info Form', color: '#08a472', audioSrc: infoForm },
      { label: 'Scam Caller ID', color: '#08a472', audioSrc: scamCallerId },
      { label: 'Real Person', color: '#08a472', audioSrc: realPersonMark },
      { label: 'Rescue', color: '#08a472', audioSrc: rescue },
      { label: 'Busy', color: '#08a472', audioSrc: busyMark },
      { label: '10-15 mins', color: '#08a472', audioSrc: tenToFifteenMins },
      { label: 'Called Before', color: '#08a472', audioSrc: calledBefore },
      { label: 'Set Callback', color: '#08a472', audioSrc: setCallBack },
      { label: 'Few Seconds', color: '#08a472', audioSrc: fewSeconds },
      { label: 'Send In Writing', color: '#08a472', audioSrc: sendInWriting },
      { label: 'Hold For Me', color: '#08a472', audioSrc: holdForMeMark },
      { label: 'Good Bye', color: '#08a472', audioSrc: goodbye },
      { label: 'Direct Express', color: '#08a472', audioSrc: directExpress },
      { label: 'Is That OKAy 2', color: '#08a472', audioSrc: isThatOkayMark },
      { label: 'Laugh', color: '#08a472', audioSrc: laugh },
      { label: 'Background Noise', color: '#08a472', audioSrc: backgroundNoise },
    ],
  ];
  

  return (
    <div className="outerdiv">
      {/* <div style={{ zIndex: 2 }}>
        <VoiceBar/>
      </div> */}
      <div className="avatar-voice-container">
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
          <button className="stop-button" onClick={resumeVoice} style={{ backgroundColor: 'green' }}>
            RESUME
          </button>
          <button className="stop-button" onClick={pauseVoice}>
            PAUSE
          </button>
          <button className="stop-button" onClick={stopVoice} style={{ backgroundColor: 'red' }}>
            STOP
          </button>
        </div>

        <div className="button-grid">
          {buttonDataColumns.map((column, colIndex) => (
            <div key={colIndex} className="button-column">
              {column.map((button, index) => (
                <button
                  key={index}
                  className="voice-button"
                  style={{ backgroundColor: button.color }}
                  onClick={() => playVoice(button.audioSrc)}
                >
                  {button.label}
                </button>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default A105;
