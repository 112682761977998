import React, { useState, useEffect } from "react";
import TeamManagementLinks from "./TeamManagementLinks";
import Navbar from "./Navbar";
import { FaCirclePlus } from "react-icons/fa6";
import { Link } from "react-router-dom";

const TeamsManagement = () => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  const fetchTeams = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://beta.jsonscommunications.com/api/auth/teams",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            // Authorization:`Bearer ${token}`,
            Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2JldGEuanNvbnNjb21tdW5pY2F0aW9ucy5jb20vYXBpL2F1dGgvbG9naW4iLCJpYXQiOjE3MzI2NDI2ODQsImV4cCI6MTczMjY0NjI4NCwibmJmIjoxNzMyNjQyNjg0LCJqdGkiOiJZRFZlZkF6OHF1cEFoNXNwIiwic3ViIjoiNjU5IiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.Hr5FXuU8MF03Nlv_a3J_9oifKjs-IZHJmNFqgNTVn3s`
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setTeams(data);
      } else {
        console.error("Error fetching teams:", response.status);
      }
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  const handleChangeName = async (id, newName) => {
  const team = teams.find((team) => team.id === id);

  if (!team) return;

  try {
    const response = await fetch(
      `https://beta.jsonscommunications.com/api/auth/teams/${id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          // "Content-Type": "application/x-www-form-urlencoded",
            // Authorization:`Bearer ${token}`,
          Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2JldGEuanNvbnNjb21tdW5pY2F0aW9ucy5jb20vYXBpL2F1dGgvbG9naW4iLCJpYXQiOjE3MzI2NDI2ODQsImV4cCI6MTczMjY0NjI4NCwibmJmIjoxNzMyNjQyNjg0LCJqdGkiOiJZRFZlZkF6OHF1cEFoNXNwIiwic3ViIjoiNjU5IiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.Hr5FXuU8MF03Nlv_a3J_9oifKjs-IZHJmNFqgNTVn3s`,
        },
        body: new URLSearchParams({
          name: newName,
          leader_id: team.leader.id, // Sending the current leader ID as it's required
        }),
      }
    );

    if (response.ok) {
      const updatedTeam = await response.json();
      setTeams(
        teams.map((team) =>
          team.id === id ? { ...team, name: updatedTeam.name } : team
        )
      );
      alert("Team name updated successfully!");
    } else {
      alert("Failed to update team name. Please try again.");
    }
  } catch (error) {
    console.error("Error updating team name:", error);
    alert("An error occurred. Please try again.");
  }
};


  const handleChangeLeader = (id, newLeader) => {
    setTeams(
      teams.map((team) =>
        team.id === id
          ? { ...team, leader: { ...team.leader, name: newLeader } }
          : team
      )
    );
  };

  const handleDeleteTeam = (id) => {
    setTeams(teams.filter((team) => team.id !== id));
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Navbar />
      <TeamManagementLinks />
      <div className="flex-grow flex flex-col items-center justify-center p-6">
        <div className="flex items-center justify-between w-full max-w-5xl mb-6">
          <h1 className="text-xl font-bold text-center">Teams List</h1>
          <Link
            to="/createteam"
            className="flex items-center gap-2 text-[#222e47] hover:text-[#1b2438] transition"
          >
            <FaCirclePlus className="text-2xl animate-bounce" />
            <span>Create Team</span>
          </Link>
        </div>
        {loading ? (
          <p>Loading teams...</p>
        ) : (
          <div className="flex flex-wrap justify-center gap-6 w-full">
            {teams.map((team) => (
              <div
                key={team.id}
                className="bg-white p-6 border rounded-lg shadow hover:shadow-lg transform hover:scale-105 transition w-[22%] max-w-xs"
              >
                <h2 className="text-lg font-semibold mb-2">{team.name}</h2>
                <p className="mb-2">Leader: {team.leader?.name}</p>
                <input
                  type="text"
                  placeholder="Update Team Name"
                  className="w-full p-2 mb-4 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-indigo-300"
                  id={`name-${team.id}`}
                />
                <div className="w-full mb-4 border-2 border-[#222e47] rounded-full">
                  <button
                    className="rounded-full text-[#222e47] hover:bg-[#222e47] hover:text-white transition"
                    onClick={() =>
                      handleChangeName(
                        team.id,
                        document.getElementById(`name-${team.id}`).value
                      )
                    }
                  >
                    Change Name
                  </button>
                </div>
                <select
                  className="w-full p-2 mb-4 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-indigo-300"
                  id={`leader-${team.id}`}
                >
                  <option value={team.leader?.name}>{team.leader?.name}</option>
                  <option>Jane Doe</option>
                  <option>John Smith</option>
                </select>
                <div className="w-full mb-4 border-2 border-[#222e47] rounded-full">
                  <button
                    className="rounded-full text-[#222e47] hover:bg-[#222e47] hover:text-white transition"
                    onClick={() =>
                      handleChangeLeader(
                        team.id,
                        document.getElementById(`leader-${team.id}`).value
                      )
                    }
                  >
                    Change Leader
                  </button>
                </div>
                <div className="w-full mb-4 border-2 border-red-500 rounded-full">
                  <button
                    className="rounded-full text-red-500 hover:bg-red-500 hover:text-white transition"
                    onClick={() => handleDeleteTeam(team.id)}
                  >
                    Delete Team
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamsManagement;
