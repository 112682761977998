import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";

const Termination = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [terminations, setTerminations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingTerminations, setLoadingTerminations] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [terminationDate, setTerminationDate] = useState("");
  const [terminationType, setTerminationType] = useState("");
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getToken = () => {
    // Replace this with your token fetching logic
    return localStorage.getItem("token");
  };

  // Fetch Users
  const fetchUsers = async () => {
    setLoadingUsers(true);
    const token = getToken();
    if (!token) {
      console.error("No token found");
      setLoadingUsers(false);
      return;
    }

    try {
      const response = await fetch(
        `https://beta.jsonscommunications.com/api/auth/users?page=1`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            // Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2JldGEuanNvbnNjb21tdW5pY2F0aW9ucy5jb20vYXBpL2F1dGgvbG9naW4iLCJpYXQiOjE3MzI1NTgxOTUsImV4cCI6MTczMjU2MTc5NSwibmJmIjoxNzMyNTU4MTk1LCJqdGkiOiI5SzZreWtFaXp6NVJDbG1RIiwic3ViIjoiNjU5IiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.dEy-_ZM50mLsxj-vWj4hU9vUSq1fIOJqjBGiBERfY3Y`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUsers(data.users.data);
      } else {
        console.error(`Error fetching users: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
    setLoadingUsers(false);
  };

  // Fetch Terminations
  const fetchTerminations = async (page = 1) => {
    setLoadingTerminations(true);
    const token = getToken();
    if (!token) {
      console.error("No token found");
      setLoadingTerminations(false);
      return;
    }

    try {
      const response = await fetch(
        `https://beta.jsonscommunications.com/api/auth/terminations?page=${page}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            // Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2JldGEuanNvbnNjb21tdW5pY2F0aW9ucy5jb20vYXBpL2F1dGgvbG9naW4iLCJpYXQiOjE3MzI1NTgxOTUsImV4cCI6MTczMjU2MTc5NSwibmJmIjoxNzMyNTU4MTk1LCJqdGkiOiI5SzZreWtFaXp6NVJDbG1RIiwic3ViIjoiNjU5IiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.dEy-_ZM50mLsxj-vWj4hU9vUSq1fIOJqjBGiBERfY3Y`,

          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setTerminations(data.terminations.data);
        setCurrentPage(data.terminations.current_page);
        setTotalPages(data.terminations.last_page);
      } else {
        console.error(`Error fetching terminations: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching terminations:", error);
    }
    setLoadingTerminations(false);
  };

  useEffect(() => {
    fetchUsers();
    fetchTerminations();
  }, []);

  const handleCreateClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUser("");
    setTerminationDate("");
    setTerminationType("");
    setDescription("");
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const token = getToken();
    if (!token) {
      console.error("No token found");
      setIsSubmitting(false);
      return;
    }

    const payload = new URLSearchParams();
    payload.append("employee_id", selectedUser);
    payload.append("termination_date", terminationDate);
    payload.append("termination_type", terminationType);
    payload.append("description", description);

    try {
      const response = await fetch(
        `https://beta.jsonscommunications.com/api/auth/terminate-user`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
            // Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2JldGEuanNvbnNjb21tdW5pY2F0aW9ucy5jb20vYXBpL2F1dGgvbG9naW4iLCJpYXQiOjE3MzI1NTgxOTUsImV4cCI6MTczMjU2MTc5NSwibmJmIjoxNzMyNTU4MTk1LCJqdGkiOiI5SzZreWtFaXp6NVJDbG1RIiwic3ViIjoiNjU5IiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.dEy-_ZM50mLsxj-vWj4hU9vUSq1fIOJqjBGiBERfY3Y`,

          },
          body: payload,
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Termination created successfully:", data);
        fetchTerminations(); // Refresh the table after creation
        handleCloseModal();
      } else {
        console.error(`Error creating termination: ${response.status}`);
      }
    } catch (error) {
      console.error("Error creating termination:", error);
    }
    setIsSubmitting(false);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) fetchTerminations(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) fetchTerminations(currentPage - 1);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navbar */}
      <Navbar />

      {/* Content Wrapper */}
      <div className="p-6 md:p-10">
        {/* Table Controls */}
        <div className="flex justify-between items-center mb-4 w-full">
          {/* Search Bar */}
          <div className="flex items-center">
            <input
              type="text"
              placeholder="Search..."
              className="block w-56 p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
            />
          </div>

          {/* Create Button */}
          <div className="flex items-center">
            <button
              onClick={handleCreateClick}
              className="flex justify-center items-center px-6 py-2 rounded-full bg-[#222e47] text-white font-medium text-sm shadow hover:bg-[#1b2438] transition"
            >
              + Create
            </button>
          </div>
        </div>

        {/* Table */}
        <div className="overflow-hidden border border-gray-200 rounded-lg shadow-sm">
          <table className="min-w-full bg-white">
            <thead className="bg-[#222e47]">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Employee ID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Termination Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Termination Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              {loadingTerminations ? (
                <tr>
                  <td
                    colSpan="4"
                    className="px-6 py-4 text-center text-sm text-gray-500"
                  >
                    Loading...
                  </td>
                </tr>
              ) : terminations.length > 0 ? (
                terminations.map((termination) => (
                  <tr key={termination.id}>
                    <td className="px-6 py-4 text-sm text-gray-700">
                      {termination.employee_id}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-700">
                      {termination.termination_type}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-700">
                      {termination.termination_date}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-700">
                      {termination.description}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="4"
                    className="px-6 py-4 text-center text-sm text-gray-500"
                  >
                    No entries found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-gray-200 w-24 text-gray-700 font-medium rounded-lg hover:bg-gray-300 transition disabled:bg-gray-100 disabled:cursor-not-allowed"
          >
            Previous
          </button>
          <span className="text-sm text-gray-700">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-gray-200 w-24 text-gray-700 font-medium rounded-lg hover:bg-gray-300 transition disabled:bg-gray-100 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-xl rounded-lg shadow-lg p-6">
            <h2 className="text-lg font-semibold text-[#222e47] mb-6 text-center">
              Create New Termination
            </h2>

            <div className="grid grid-cols-2 gap-6">
              {/* Employee Dropdown */}
              <div className="flex flex-col">
                <label className="font-medium text-sm mb-2">Employee</label>
                <select
                  className="border border-gray-300 rounded-lg p-2 text-sm focus:ring-indigo-500 focus:border-indigo-500"
                  value={selectedUser}
                  onChange={(e) => setSelectedUser(e.target.value)}
                >
                  <option value="">Select Employee</option>
                  {loadingUsers ? (
                    <option value="">Loading...</option>
                  ) : (
                    users.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    ))
                  )}
                </select>
              </div>

              {/* Termination Type */}
              <div className="flex flex-col">
                <label className="font-medium text-sm mb-2">Termination Type</label>
                <select
                  className="border border-gray-300 rounded-lg p-2 text-sm focus:ring-indigo-500 focus:border-indigo-500"
                  value={terminationType}
                  onChange={(e) => setTerminationType(e.target.value)}
                >
                  <option value="">Select Type</option>
                  <option value="Retirement">Retirement</option>
                  <option value="Resignation">Resignation</option>
                  <option value="Termination">Termination</option>
                </select>
              </div>

              {/* Termination Date */}
              <div className="flex flex-col">
                <label className="font-medium text-sm mb-2">Termination Date</label>
                <input
                  type="date"
                  className="border border-gray-300 rounded-lg p-2 text-sm focus:ring-indigo-500 focus:border-indigo-500"
                  value={terminationDate}
                  onChange={(e) => setTerminationDate(e.target.value)}
                />
              </div>
            </div>

            {/* Description */}
            <div className="mt-4">
              <label className="font-medium text-sm mb-2">Description</label>
              <textarea
                rows="4"
                className="w-full border border-gray-300 rounded-lg p-2 text-sm focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Enter Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>

            {/* Modal Buttons */}
            <div className="flex justify-end items-center mt-6 gap-4">
              <button
                onClick={handleCloseModal}
                className="px-4 py-2 rounded-lg border border-gray-300 text-sm font-medium text-gray-600 bg-gray-100 hover:bg-gray-300 transition"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="px-4 py-2 rounded-lg bg-[#222e47] text-white text-sm font-medium hover:bg-[#111a2c] transition"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Create"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Termination;
