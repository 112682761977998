// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import {
//   FaUsers,
//   FaHeadset,
//   FaRegCalendarCheck,
//   FaComments,
//   FaCog,
//   FaHome,
//   FaDatabase,
//   FaBars, // Hamburger Icon
//   FaPowerOff
// } from "react-icons/fa";
// import "./Navbar.css"; // Optional: Add your CSS styles here
// import logo from "../images/logo.jpg";
// import profile from "../images/profile.jpg";


// const Navbar = () => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar toggle

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar open/close
//   };

//   return (
//     <div>
//       <div
//         style={{
//           backgroundColor: "white",
//           // width: "97", // Full width
//           padding: "10px 20px", // Padding for better appearance
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Shadow effect
//           // position: "fixed", // Fix the logo container at the top
//           // borderBottomLeftRadius: 50,
//           // borderBottomRightRadius: 50,
          
//           display: "flex",
//           justifyContent: "space-between",
//           gap:400,
//           alignItems: "center",
//           // zIndex: 1, // Ensure the navbar stays on top
//         }}
//       >
//         {/* Hamburger Icon */}
//         <FaBars
//             style={{ fontSize: "1.5rem", cursor: "pointer", color:'#3e2b4a'}}
//             onClick={toggleSidebar} // Toggle sidebar on click
//           />
       
        
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-around",
//           }}
//         >
//           <img
//             src={profile}
//             alt="Profile Pic"
//             width={50}
//             height={50}
//             style={{ borderRadius: "100%", backgroundColor: "black", marginRight: 10, objectFit:"cover", objectPosition:'left' }}
//           />
//           <span> Your Name</span>
//         </div>
//       </div>
//       {/* Sidebar */}
//       <div
//         className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}
//       >
//         {/* <div></div>  */}
//         <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
//         <img src={logo} alt="JSONS Logo" width={100} />
//         </div>
//         <ul>
//           <li>
//             <Link to="/">
//               <FaHome style={{ fontSize: "1.5rem" }} />
//               <span>Dashboard</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/hrm">
//               <FaDatabase style={{ fontSize: "1.5rem" }} />
//               <span>HRM System</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/user-management">
//               <FaUsers style={{ fontSize: "1.5rem" }} />
//               <span>User Management</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/support">
//               <FaHeadset style={{ fontSize: "1.5rem" }} />
//               <span>Support System</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/zoom">
//               <FaRegCalendarCheck style={{ fontSize: "1.5rem" }} />
//               <span>Zoom Meeting</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/messenger">
//               <FaComments style={{ fontSize: "1.5rem" }} />
//               <span>Messenger</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/settings">
//               <FaCog style={{ fontSize: "1.5rem" }} />
//               <span>Settings</span>
//             </Link>
//           </li>
//           <li style={{marginTop:40}}>
//             <Link>
//               <FaPowerOff style={{ fontSize: "1.5rem" }}/>
//               <span>Logout</span>
//             </Link>
//           </li>

//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Navbar;







//----------------------NEW NAVBAR Fixed Position------------###############################################
// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import {
//   FaUsers,
//   FaHeadset,
//   FaRegCalendarCheck,
//   FaComments,
//   FaCog,
//   FaHome,
//   FaDatabase,
//   FaBars, // Hamburger Icon
//   FaPowerOff,
//   FaCheck
// } from "react-icons/fa";
// import "./Navbar.css"; // Optional: Add your CSS styles here
// import logo from "../images/logo.jpg";
// import profile from "../images/profile.jpg";

// const Navbar = () => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar toggle
//   const [isNavbarFixed, setIsNavbarFixed] = useState(false); // State to determine if the navbar should be fixed

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar open/close
//   };

//   const handleScroll = () => {
//     if (window.scrollY > 0) {
//       setIsNavbarFixed(true);
//     } else {
//       setIsNavbarFixed(false);
//     }
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <div>
//       <div className={`navbar-container ${isNavbarFixed ? "fixed" : ""}`}>
//         {/* Hamburger Icon */}
//         <FaBars
//           className="hamburger-icon"
//           onClick={toggleSidebar} // Toggle sidebar on click
//         />
//         <div className="profile-container">
//           <img
//             src={profile}
//             alt="Profile Pic"
//             width={50}
//             height={50}
//             className="profile-pic"
//           />
//           <span>Your Name</span>
//         </div>
//       </div>

//       {/* Sidebar */}
//       <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
//         <div className="logo-container">
//           <img src={logo} alt="JSONS Logo" width={100} />
//         </div>
//         <ul>
//           <li>
//             <Link to="/">
//               <FaHome style={{ fontSize: "1.5rem" }} />
//               <span>Dashboard</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/hrm">
//               <FaDatabase style={{ fontSize: "1.5rem" }} />
//               <span>HRM System</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/user-management">
//               <FaUsers style={{ fontSize: "1.5rem" }} />
//               <span>User Management</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/support">
//               <FaHeadset style={{ fontSize: "1.5rem" }} />
//               <span>Support System</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/zoom">
//               <FaRegCalendarCheck style={{ fontSize: "1.5rem" }} />
//               <span>Zoom Meeting</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/messenger">
//               <FaComments style={{ fontSize: "1.5rem" }} />
//               <span>Messenger</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/settings">
//               <FaCog style={{ fontSize: "1.5rem" }} />
//               <span>Settings</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/tcpa">
//               <FaCheck style={{ fontSize: "1.5rem" }} />
//               <span>TCPA Check</span>
//             </Link>
//           </li>
//           <li style={{ marginTop: 40 }}>
//             <Link>
//               <FaPowerOff style={{ fontSize: "1.5rem" }} />
//               <span>Logout</span>
//             </Link>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Navbar;











// Code given below is for top navbar



// import React from "react";
// import { Link } from "react-router-dom";
// import {
//   FaUsers,
//   FaHeadset,
//   FaRegCalendarCheck,
//   FaComments,
//   FaCog,
//   FaHome,
//   FaDatabase,
//   FaPowerOff,
// } from "react-icons/fa";
// import "./Navbar.css"; // Optional: Add your CSS styles here
// import logo from "../images/logo.jpg";
// import { FaAngleDown } from "react-icons/fa6";

// const Navbar = () => {
//   return (
//     <div>
//       <div
//         style={{
//           backgroundColor: "white",
//           padding: "10px 20px",
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Shadow effect
//           display: "flex",
//           justifyContent: "space-around",
//           alignItems: "center",
//         }}
//       >
//         {/* Logo */}
//         <img src={logo} alt="JSONS Logo" width={90} />

//         {/* Navigation Links */}
//         <div className="nav-links">
//           <Link to="/" className="nav-link">
//             <FaHome style={{ fontSize: "1.5rem" }} />
//             <span style={{marginRight:5}}>Dashboard</span><FaAngleDown/>
//           </Link>
//           <Link to="/hrm" className="nav-link">
//             <FaDatabase style={{ fontSize: "1.5rem" }} />
//             <span>HRM System</span>
//           </Link>
//           <Link to="/user-management" className="nav-link">
//             <FaUsers style={{ fontSize: "1.5rem" }} />
//             <span>User Management</span>
//           </Link>
//           <Link to="/support" className="nav-link">
//             <FaHeadset style={{ fontSize: "1.5rem" }} />
//             <span>Support System</span>
//           </Link>
//           <Link to="/zoom" className="nav-link">
//             <FaRegCalendarCheck style={{ fontSize: "1.5rem" }} />
//             <span>Zoom Meeting</span>
//           </Link>
//           <Link to="/messenger" className="nav-link">
//             <FaComments style={{ fontSize: "1.5rem" }} />
//             <span>Messenger</span>
//           </Link>
//           <Link to="/settings" className="nav-link">
//             <FaCog style={{ fontSize: "1.5rem" }} />
//             <span>Settings</span>
//           </Link>
//         </div>

//         {/* Profile Section */}
        // <div style={{ display: "flex", alignItems: "center", fontSize:'1.5rem', color:'#222e47', cursor:"pointer"}}>
        //   <FaPowerOff/>
        // </div>
//       </div>
//     </div>
//   );
// };

// export default Navbar;











// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import {
//   FaUsers,
//   FaHeadset,
//   FaRegCalendarCheck,
//   FaComments,
//   FaCog,
//   FaHome,
//   FaDatabase,
//   FaBars, // Hamburger Icon
//   FaPowerOff
// } from "react-icons/fa";
// import "./Navbar.css"; // Optional: Add your CSS styles here
// import logo from "../images/logo.jpg";

// const Navbar = () => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar toggle

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar open/close
//   };

//   return (
//     <div>
//       {/* Hamburger Icon */}
//       <div className={`hamburger ${isSidebarOpen ? 'active' : ''}`} onClick={toggleSidebar}>
//         <div className="line"></div>
//         <div className="line"></div>
//         <div className="line"></div>
//       </div>

//       {/* Sidebar */}
//       <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
//         <img src={logo} alt="JSONS Logo" width={100} />
//         <ul>
//           <li>
//             <Link to="/">
//               <FaHome style={{ fontSize: "1.5rem" }} />
//               <span>Dashboard</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/hrm">
//               <FaDatabase style={{ fontSize: "1.5rem" }} />
//               <span>HRM System</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/user-management">
//               <FaUsers style={{ fontSize: "1.5rem" }} />
//               <span>User Management</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/support">
//               <FaHeadset style={{ fontSize: "1.5rem" }} />
//               <span>Support System</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/zoom">
//               <FaRegCalendarCheck style={{ fontSize: "1.5rem" }} />
//               <span>Zoom Meeting</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/messenger">
//               <FaComments style={{ fontSize: "1.5rem" }} />
//               <span>Messenger</span>
//             </Link>
//           </li>
//           <li>
//             <Link to="/settings">
//               <FaCog style={{ fontSize: "1.5rem" }} />
//               <span>Settings</span>
//             </Link>
//           </li>
//           <li style={{ marginTop: 40 }}>
//             <Link>
//               <FaPowerOff style={{ fontSize: "1.5rem" }} />
//               <span>Logout</span>
//             </Link>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Navbar;






import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FaUsers,
  FaHeadset,
  FaRegCalendarCheck,
  FaComments,
  FaCog,
  FaHome,
  FaDatabase,
  FaBars,
  FaPowerOff,
  FaCheck,
} from "react-icons/fa";
import logo from "../images/logo.jpg";
import profile from "../images/profile.jpg";

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsNavbarFixed(true);
    } else {
      setIsNavbarFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {/* Top Navbar */}
      <div
        className={`w-full bg-white p-4 shadow-md flex justify-between items-center transition-all z-10 ${
          isNavbarFixed ? "fixed top-0 left-0 right-0" : ""
        }`}
      >
        {/* Hamburger Icon */}
        <FaBars
          className="text-xl cursor-pointer text-[#222e47] z-20"
          onClick={toggleSidebar}
        />

        {/* Profile Section */}
        <div className="flex items-center space-x-2">
          <img
            src={profile}
            alt="Profile Pic"
            className="w-12 h-12 rounded-full object-cover"
          />
          <span className="hidden md:block">Your Name</span>
        </div>
      </div>

      {/* Sidebar */}
      <div
        className={`bg-white fixed left-0 top-0 h-full overflow-hidden transition-all shadow-md pt-20 ${
          isSidebarOpen ? "w-48" : "w-0"
        }`}
      >
        {/* Logo */}
        <div className="flex justify-center">
          <img src={logo} alt="JSONS Logo" className="w-24" />
        </div>

        {/* Menu Items */}
        <ul className="list-none p-0 m-0 mt-4">
          <li className="p-3 hover:shadow-lg rounded-full">
            <Link to="/" className="flex items-center text-[#222e47]">
              <FaHome className="text-lg" />
              <span className="ml-2">Dashboard</span>
            </Link>
          </li>
          <li className="p-3 hover:shadow-lg rounded-full">
            <Link to="/hrm" className="flex items-center text-[#222e47]">
              <FaDatabase className="text-lg" />
              <span className="ml-2">HRM System</span>
            </Link>
          </li>
          <li className="p-3 hover:shadow-lg rounded-full">
            <Link to="/user-management" className="flex items-center text-[#222e47]">
              <FaUsers className="text-lg" />
              <span className="ml-2">User Management</span>
            </Link>
          </li>
          <li className="p-3 hover:shadow-lg rounded-full">
            <Link to="/support" className="flex items-center text-[#222e47]">
              <FaHeadset className="text-lg" />
              <span className="ml-2">Support System</span>
            </Link>
          </li>
          <li className="p-3 hover:shadow-lg rounded-full">
            <Link to="/zoom" className="flex items-center text-[#222e47]">
              <FaRegCalendarCheck className="text-lg" />
              <span className="ml-2">Zoom Meeting</span>
            </Link>
          </li>
          <li className="p-3 hover:shadow-lg rounded-full">
            <Link to="/messenger" className="flex items-center text-[#222e47]">
              <FaComments className="text-lg" />
              <span className="ml-2">Messenger</span>
            </Link>
          </li>
          <li className="p-3 hover:shadow-lg rounded-full">
            <Link to="/settings" className="flex items-center text-[#222e47]">
              <FaCog className="text-lg" />
              <span className="ml-2">Settings</span>
            </Link>
          </li>
          <li className="p-3 hover:shadow-lg rounded-full">
            <Link to="/tcpa" className="flex items-center text-[#222e47]">
              <FaCheck className="text-lg" />
              <span className="ml-2">TCPA Check</span>
            </Link>
          </li>
          <li className="p-3 mt-10 hover:shadow-lg rounded-full">
            <Link to="/" className="flex items-center text-[#222e47]">
              <FaPowerOff className="text-lg" />
              <span className="ml-2">Logout</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
