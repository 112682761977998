import React, { useState, useEffect } from 'react';
import './AssignAgent.css';
import Navbar from './Navbar';
import TeamManagementLinks from './TeamManagementLinks';

const AssignAgent = () => {
  const [agents, setAgents] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(""); // Single selection
  const [selectedTeam, setSelectedTeam] = useState("");

  // Dummy data to simulate API response
  const dummyAgents = [
    { id: 1, name: 'Usama Maqaddar (Voice) - Team: No Team' },
    { id: 2, name: 'Ayaaz ul Haq (Voice) - Team: No Team' },
    { id: 3, name: 'Adnan Ismail (Voice) - Team: No Team' },
    { id: 4, name: 'Ali (Avatar) - Team: No Team' },
    { id: 5, name: 'Sulaiman Safi (Avatar) - Team: ÜñíţÖÞþÅßĻÈ' },
    { id: 6, name: 'Muhammad Atamash (Avatar) - Team: No Team' },
    { id: 7, name: 'ASAD ULLAH (Avatar) - Team: ÜñíţÖÞþÅßĻÈ' },
    { id: 8, name: 'Hammad Khan (Avatar) - Team: No Team' },
    { id: 9, name: 'Naveed Ahmed (Avatar) - Team: No Team' },
    { id: 10, name: 'Asad Bashir (Avatar) - Team: No Team' }
  ];

  const dummyTeams = [
    { id: 1, name: 'ÜñíţÖÞþÅßĻÈ' },
    { id: 2, name: 'Team Alpha' },
    { id: 3, name: 'Team Bravo' }
  ];

  // Simulate API call to load data
  useEffect(() => {
    setAgents(dummyAgents);
    setTeams(dummyTeams);
  }, []);

  const handleAssign = () => {
    console.log('Assigned Agent:', selectedAgent);
    console.log('Assigned to Team:', selectedTeam);
    // Perform further actions here, e.g., send data to API
  };

  return (
    <>
    <Navbar/>
    <TeamManagementLinks/>
    <div className="assign-agent-container">
      <h2>Assign Agent to Team</h2>
      <form className="assign-agent-form">
        <label htmlFor="agent">Select Team Agent</label>
        <select
          id="agent"
          value={selectedAgent}
          onChange={(e) => setSelectedAgent(e.target.value)}
        >
          <option value="" disabled>Select an agent</option>
          {agents.map((agent) => (
            <option key={agent.id} value={agent.name}>
              {agent.name}
            </option>
          ))}
        </select>

        <label htmlFor="team">Assign to Team</label>
        <select
          id="team"
          value={selectedTeam}
          onChange={(e) => setSelectedTeam(e.target.value)}
        >
          <option value="" disabled>Select a team</option>
          {teams.map((team) => (
            <option key={team.id} value={team.name}>
              {team.name}
            </option>
          ))}
        </select>

        <button type="button" onClick={handleAssign} className="assign-button">
          Assign to Team
        </button>
      </form>
    </div>
    </>
  );
};

export default AssignAgent;
