import React from 'react'
import './QA.css'
import { Link } from "react-router-dom";
import { FaTowerBroadcast } from "react-icons/fa6";

const QALinks = () => {
  return (
    <div className='divlink'>
        <Link to='/assignlead' className="link">
          <FaTowerBroadcast /> <span>Assign Xfers</span>
        </Link>
        <Link to='/mylead' className="link">
          <FaTowerBroadcast /> <span>My Xfers</span>
        </Link>
        <Link to='/agentstats' className="link">
          <FaTowerBroadcast /> <span>Agent Stats</span>
        </Link>
        <Link to='/reqalead' className="link">
          <FaTowerBroadcast /> <span>Re QA Xfers</span>
        </Link>
    </div>
  )
}

export default QALinks