import React, { useState } from "react";
import { FaSort } from "react-icons/fa";
import Navbar from "./Navbar";
import Leaderboardbar from "./Leaderboardbar";

const Leaderboard = () => {
  const [tableData, setTableData] = useState([
    { position: 1, teamName: "Team Alpha", numberOfXfers: 50, attemptsPerAgent: 5, neededXfers: 10, hc: 20, totalSales: 100 },
    { position: 2, teamName: "Team Bravo", numberOfXfers: 45, attemptsPerAgent: 4.5, neededXfers: 8, hc: 18, totalSales: 90 },
    { position: 3, teamName: "Team Charlie", numberOfXfers: 30, attemptsPerAgent: 3, neededXfers: 6, hc: 15, totalSales: 60 },
    { position: 4, teamName: "Team Danny", numberOfXfers: 23, attemptsPerAgent: 2.5, neededXfers: 5, hc: 12, totalSales: 30 },
  ]);

  const agentData = [
    { position: 1, agentName: "John Doe", team: "Team Alpha", numberOfXfers: 12, sales: 8, approvedXfers: 10, totalSales: 18 },
    { position: 2, agentName: "Jane Smith", team: "Team Bravo", numberOfXfers: 10, sales: 7, approvedXfers: 8, totalSales: 15 },
    { position: 3, agentName: "Alice Johnson", team: "Team Charlie", numberOfXfers: 15, sales: 10, approvedXfers: 13, totalSales: 23 },
    { position: 4, agentName: "Bob Brown", team: "Team Alpha", numberOfXfers: 14, sales: 9, approvedXfers: 11, totalSales: 20 },
    { position: 5, agentName: "Charlie Davis", team: "Team Danny", numberOfXfers: 9, sales: 5, approvedXfers: 7, totalSales: 12 },
  ];

  const [selectedTeams, setSelectedTeams] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleCheckboxChange = (teamName) => {
    setSelectedTeams((prevSelectedTeams) =>
      prevSelectedTeams.includes(teamName)
        ? prevSelectedTeams.filter((team) => team !== teamName)
        : [...prevSelectedTeams, teamName]
    );
  };

  const filteredAgents = agentData.filter((agent) => selectedTeams.includes(agent.team));

  const sortedAgents = [...filteredAgents].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div>
      <Navbar />
      <div className="p-5">
        <h2 className="text-2xl font-bold mb-4">Leaderboard</h2>
        <Leaderboardbar/>
        <div className="overflow-x-auto shadow rounded-lg">
          <table className="table-auto w-full text-center border border-gray-200">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="p-3">Select</th>
                <th className="p-3">Position</th>
                <th className="p-3">Team</th>
                <th className="p-3">HC</th>
                <th className="p-3">Number of Xfers</th>
                <th className="p-3">Attempts Per Agent</th>
                <th className="p-3">Needed Xfers</th>
                <th className="p-3">Total Sales</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {tableData.map((row, index) => (
                <tr key={index} className="border-b hover:bg-gray-100">
                  <td className="p-3">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={selectedTeams.includes(row.teamName)}
                      onChange={() => handleCheckboxChange(row.teamName)}
                    />
                  </td>
                  <td className="p-3">{row.position}</td>
                  <td className="p-3">{row.teamName}</td>
                  <td className="p-3">{row.hc}</td>
                  <td className="p-3">{row.numberOfXfers}</td>
                  <td className="p-3">{row.attemptsPerAgent}</td>
                  <td className="p-3">{row.neededXfers}</td>
                  <td className="p-3">{row.totalSales}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {selectedTeams.length > 0 && (
          <div className="mt-6">
            <h3 className="text-xl font-semibold mb-4">Team Agents</h3>
            <div className="overflow-x-auto shadow rounded-lg">
              <table className="table-auto w-full text-center border border-gray-200">
                <thead className="bg-gray-800 text-white">
                  <tr>
                    <th className="p-3 cursor-pointer" onClick={() => handleSort("position")}>
                      Position <FaSort className="inline-block ml-2 text-xs" />
                    </th>
                    <th className="p-3 cursor-pointer" onClick={() => handleSort("agentName")}>
                      Agent Name <FaSort className="inline-block ml-2 text-xs" />
                    </th>
                    <th className="p-3 cursor-pointer" onClick={() => handleSort("team")}>
                      Team <FaSort className="inline-block ml-2 text-xs" />
                    </th>
                    <th className="p-3 cursor-pointer" onClick={() => handleSort("numberOfXfers")}>
                      Number of Xfers <FaSort className="inline-block ml-2 text-xs" />
                    </th>
                    <th className="p-3 cursor-pointer" onClick={() => handleSort("sales")}>
                      Sales <FaSort className="inline-block ml-2 text-xs" />
                    </th>
                    <th className="p-3 cursor-pointer" onClick={() => handleSort("approvedXfers")}>
                      Approved Xfers <FaSort className="inline-block ml-2 text-xs" />
                    </th>
                    <th className="p-3 cursor-pointer" onClick={() => handleSort("totalSales")}>
                      Total Sales <FaSort className="inline-block ml-2 text-xs" />
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {sortedAgents.map((agent, index) => (
                    <tr key={index} className="border-b hover:bg-gray-100">
                      <td className="p-3">{agent.position}</td>
                      <td className="p-3">{agent.agentName}</td>
                      <td className="p-3">{agent.team}</td>
                      <td className="p-3">{agent.numberOfXfers}</td>
                      <td className="p-3">{agent.sales}</td>
                      <td className="p-3">{agent.approvedXfers}</td>
                      <td className="p-3">{agent.totalSales}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Leaderboard;
