import React, { useState } from "react";
import AvatarLinks from "./AvatarLinks";
import Navbar from "./Navbar";
import bgImage from "../images/bg1.jpg"

const LeadForm = () => {
  const [formData, setFormData] = useState({
    agentUser: "",
    leadId: "",
    dialerId: "",
    age: "",
    smoker: "",
    closer: "",
    center: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Navbar />
      <div className="p-12 bg-cover bg-center bg-no-repeat min-h-screen" style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="mt-[-50px]">
          <AvatarLinks />
        </div>
        <div className="flex justify-center">
          <div className="w-full max-w-4xl bg-black bg-opacity-80 p-12 rounded-lg mt-8">
            <div className="text-white text-center font-bold text-2xl py-4 border border-white rounded-tl-full rounded-br-full">
              NUCLEO
            </div>
            <div className="flex flex-col gap-6 mt-6 md:flex-row">
              <div className="flex flex-col w-full md:w-1/3">
                <label className="text-white font-semibold mb-2">Lead ID / Phone No.</label>
                <input
                  type="text"
                  name="leadId"
                  value={formData.leadId}
                  onChange={handleChange}
                  placeholder="Enter Lead ID or Phone Number"
                  className="p-2 rounded border border-gray-300 bg-gray-100 text-sm"
                />
              </div>
              <div className="flex flex-col w-full md:w-1/3">
                <label className="text-white font-semibold mb-2">Select Agent User</label>
                <select
                  name="agentUser"
                  value={formData.agentUser}
                  onChange={handleChange}
                  className="p-2 rounded border border-gray-300 bg-gray-100 text-sm"
                >
                  <option value="">Select</option>
                  <option value="Agent1">Agent 1</option>
                  <option value="Agent2">Agent 2</option>
                </select>
              </div>
              <div className="flex flex-col w-full md:w-1/3">
                <label className="text-white font-semibold mb-2">Dialer ID</label>
                <input
                  type="text"
                  name="dialerId"
                  value={formData.dialerId}
                  onChange={handleChange}
                  placeholder="Enter Dialer ID"
                  className="p-2 rounded border border-gray-300 bg-gray-100 text-sm"
                />
              </div>
            </div>

            <div className="flex flex-col gap-6 mt-6 md:flex-row">
              <div className="flex flex-col w-full md:w-1/3">
                <label className="text-white font-semibold mb-2">Smoker</label>
                <select
                  name="smoker"
                  value={formData.smoker}
                  onChange={handleChange}
                  className="p-2 rounded border border-gray-300 bg-gray-100 text-sm"
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="flex flex-col w-full md:w-1/3">
                <label className="text-white font-semibold mb-2">Age</label>
                <input
                  type="text"
                  name="age"
                  value={formData.age}
                  onChange={handleChange}
                  placeholder="Enter Age"
                  className="p-2 rounded border border-gray-300 bg-gray-100 text-sm"
                />
              </div>
              <div className="flex flex-col w-full md:w-1/3">
                <label className="text-white font-semibold mb-2">Closer</label>
                <select
                  name="closer"
                  value={formData.closer}
                  onChange={handleChange}
                  className="p-2 rounded border border-gray-300 bg-gray-100 text-sm"
                >
                  <option value="">Select</option>
                  <option value="Closer1">Closer 1</option>
                  <option value="Closer2">Closer 2</option>
                </select>
              </div>
            </div>

            <div className="flex flex-col gap-6 mt-6 md:flex-row">
              <div className="flex flex-col w-full">
                <label className="text-white font-semibold mb-2">Center</label>
                <select
                  name="center"
                  value={formData.center}
                  onChange={handleChange}
                  className="p-2 rounded border border-gray-300 bg-gray-100 text-sm"
                >
                  <option value="">Select</option>
                  <option value="Center1">Center 1</option>
                  <option value="Center2">Center 2</option>
                </select>
              </div>
            </div>
            <div className="text-center mt-8">
              <button className="bg-transparent text-white border border-white rounded-full px-16 py-4 text-lg hover:border-2 hover:shadow-lg transition-all">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadForm;
