import React, { useState } from 'react';
import Navbar from './Navbar';
import QALinks from './QALinks';
import './AssignLead.css';

const AssignLead = () => {
  // Initial data for the table
  const initialData = [
    { userId: 1, qaUser: 'John Doe', pendingLeads: 5, approvedLeads: 10, rejectedLeads: 3, onReview: 2, numRecordings: 20 },
    { userId: 2, qaUser: 'Jane Smith', pendingLeads: 8, approvedLeads: 15, rejectedLeads: 4, onReview: 1, numRecordings: 18 },
    { userId: 3, qaUser: 'Alice Brown', pendingLeads: 4, approvedLeads: 12, rejectedLeads: 2, onReview: 3, numRecordings: 22 },
    { userId: 4, qaUser: 'Bob White', pendingLeads: 9, approvedLeads: 5, rejectedLeads: 6, onReview: 0, numRecordings: 30 },
  ];

  // Initial data for QA Person options in the dropdown
  const qaPersons = [
    'John Doe',
    'Alice Brown',
    'Jane Smith',
    'Bob White',
  ];

  const [data] = useState(initialData);
  const [qaPerson, setQaPerson] = useState(qaPersons[0]);
  const [startingId, setStartingId] = useState('');
  const [endingId, setEndingId] = useState('');
  const [assignToPerson, setAssignToPerson] = useState(qaPersons[0]);
  const [removeFromPerson, setRemoveFromPerson] = useState(qaPersons[0]);
  const [leadsToReassign, setLeadsToReassign] = useState('');

  // Calculate totals for each column
  const totals = data.reduce(
    (acc, row) => {
      acc.pendingLeads += row.pendingLeads;
      acc.approvedLeads += row.approvedLeads;
      acc.rejectedLeads += row.rejectedLeads;
      acc.onReview += row.onReview;
      acc.numRecordings += row.numRecordings;
      return acc;
    },
    { pendingLeads: 0, approvedLeads: 0, rejectedLeads: 0, onReview: 0, numRecordings: 0 }
  );

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Logic to handle assign leads form submission
    console.log('Assign Leads:', { qaPerson, startingId, endingId });
  };

  const handleReassignSubmit = (e) => {
    e.preventDefault();
    // Logic to handle reassign leads form submission
    console.log('Reassign Leads:', { assignToPerson, removeFromPerson, leadsToReassign });
  };

  return (
    <div>
      <Navbar />
      <QALinks />
      <div className="assign-lead-content">
        <h1>Assign Lead</h1>
        <table className="assign-lead-table">
          <thead>
            <tr>
              <th>User ID</th>
              <th>QA User</th>
              <th>Pending Leads</th>
              <th>Approved Leads</th>
              <th>Rejected Leads</th>
              <th>On Review</th>
              <th>No. of Recordings</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr key={row.userId}>
                <td>{row.userId}</td>
                <td>{row.qaUser}</td>
                <td>{row.pendingLeads}</td>
                <td>{row.approvedLeads}</td>
                <td>{row.rejectedLeads}</td>
                <td>{row.onReview}</td>
                <td>{row.numRecordings}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="2" style={{ fontWeight: 'bold' }}>Total</td>
              <td>{totals.pendingLeads}</td>
              <td>{totals.approvedLeads}</td>
              <td>{totals.rejectedLeads}</td>
              <td>{totals.onReview}</td>
              <td>{totals.numRecordings}</td>
            </tr>
          </tfoot>
        </table>

        {/* Assign Leads Form */}
        <form className="assign-lead-form" onSubmit={handleFormSubmit}>
          <h2>ID Range</h2>
          <div className="id-range">
            <p>Starting ID: 163385</p>
            <p>Ending ID: 163398</p>
            <p>Unassigned Leads: 13</p>
          </div>
          <h2>Assign Leads</h2>

          <div className='formrow'>
          <label>
            Select QA Person:
            <select value={qaPerson} onChange={(e) => setQaPerson(e.target.value)}>
              {qaPersons.map((person, index) => (
                <option key={index} value={person}>{person}</option>
              ))}
            </select>
          </label>

          <label>
            Enter Starting ID:
            <input
              type="text"
              value={startingId}
              onChange={(e) => setStartingId(e.target.value)}
              placeholder="Enter Starting ID"
            />
          </label>

          <label>
            Enter Ending ID:
            <input
              type="text"
              value={endingId}
              onChange={(e) => setEndingId(e.target.value)}
              placeholder="Enter Ending ID"
            />
          </label>
          </div>

          <button type="submit">Assign Leads</button>
        </form>

        {/* Reassign Leads Form */}
        <form className="reassign-lead-form" onSubmit={handleReassignSubmit}>
          <h2>Reassign Leads</h2>

          <div className='formrow'>
          <label>
            Select Person to Assign Leads:
            <select value={assignToPerson} onChange={(e) => setAssignToPerson(e.target.value)}>
              {qaPersons.map((person, index) => (
                <option key={index} value={person}>{person}</option>
              ))}
            </select>
          </label>

          <label>
            Select Person to Remove Leads From:
            <select value={removeFromPerson} onChange={(e) => setRemoveFromPerson(e.target.value)}>
              {qaPersons.map((person, index) => (
                <option key={index} value={person}>{person}</option>
              ))}
            </select>
          </label>

          <label>
            Number of Leads to Reassign:
            <input
              type="text"
              value={leadsToReassign}
              onChange={(e) => setLeadsToReassign(e.target.value)}
              placeholder="Enter number of leads to reassign"
            />
          </label>
          </div>

          <button type="submit">Reassign Leads</button>
        </form>
      </div>
    </div>
  );
};

export default AssignLead;
