// AvatarVoice.js

import React, { useRef, useState } from "react";
import "./AvatarVoice.css";

// Import all sound files
import intro from "./Voices/Intro.mp3";
import greetings1 from "./Voices/Greetings-1.mp3";
import planAge from "./Voices/Plan + Age.mp3";
import name from "./Voices/Name.mp3";
import plan2 from "./Voices/Plan-2.mp3";
import age from "./Voices/Age.mp3";
import dob from "./Voices/DOB.mp3";
import state from "./Voices/State.mp3";
import health from "./Voices/Health.mp3";
import banking from "./Voices/Banking.mp3";
import transfer from "./Voices/Transfer.mp3";
import disclaimer1 from "./Voices/Disclaimer-1.mp3";
import disclaimer2 from "./Voices/Disclaimer-2.mp3";
import disclaimRebut from "./Voices/Disclaim Rebut.mp3";
import followUp from "./Voices/Follow Up.mp3";
import wholeLifePlcy from "./Voices/Whole Life Plcy.mp3";

import doingGreat from "./Voices/Doing Great.mp3";
import gladToHear from "./Voices/Glad to hear.mp3";
import ohSorry from "./Voices/Oh Sorry.mp3";
import fantastic from "./Voices/Fantastic.mp3";
import great from "./Voices/Great.mp3";
import yes from "./Voices/Yes.mp3";
import no from "./Voices/No.mp3";
import okay from "./Voices/Okay.mp3";
import noProblem from "./Voices/No problem.mp3";
import holdForMe from "./Voices/Hold for me.mp3";
import transferDetail from "./Voices/Transfer Detail.mp3";
import cost from "./Voices/Cost.mp3";
import cost2 from "./Voices/Cost-2.mp3";
import rateAbove70 from "./Voices/Rate Above 70+.mp3";
import rateBelow70 from "./Voices/Rate Below 70-.mp3";
import patience from "./Voices/Patience.mp3";

import areYouThere from "./Voices/Are you there.mp3";
import anyoneOnline from "./Voices/Anyone online.mp3";
import isThatOkay from "./Voices/Is that okay.mp3";
import hearMe from "./Voices/Hear me.mp3";
import whatWasThat from "./Voices/What was that.mp3";
import haveCoverage from "./Voices/Have Coverage.mp3";
import addCoverage from "./Voices/Add Coverage.mp3";
import callBack from "./Voices/Call Back.mp3";
import zipCode from "./Voices/Zip Code.mp3";
import location from "./Voices/Location.mp3";
import agentsName from "./Voices/Agent's Name.mp3";
import companyName from "./Voices/Company's Name.mp3";
import isThatAYes from "./Voices/Is that a yes.mp3";
import howMuchCoverage from "./Voices/Hw Mch Coverage.mp3";
import howMuchCover2 from "./Voices/Hw Mch Cover-2.mp3";
import callYouBack from "./Voices/Call you back.mp3";

import notInterested1 from "./Voices/Not Interested-1.mp3";
import notInterested2 from "./Voices/Not Interested-2.mp3";
import busy1 from "./Voices/Busy-1.mp3";
import busy2 from "./Voices/Busy-2.mp3";
import busy3 from "./Voices/Busy-3.mp3";
import bankRebuttal from "./Voices/Bank Rebuttal.mp3";
import healthDuration from "./Voices/Health Duration.mp3";
import debitCardLink from "./Voices/Debit Card Link.mp3";
import sendInMail from "./Voices/Send in mail.mp3";
import dnc from "./Voices/DNC.mp3";
import dobRebuttal from "./Voices/DOB Rebuttal.mp3";
import weather from "./Voices/Weather.mp3";
import corona from "./Voices/Corona.mp3";
import tobacco from "./Voices/Tobacco.mp3";
import direcExprCard from "./Voices/Direc Expr Card.mp3";
import realPerson from "./Voices/Real Person.mp3";
import VoiceBar from "./VoiceBar";

const AvatarVoice = () => {
  const [isPaused, setIsPaused] = useState(false);
  const audioRef = useRef(new Audio());

  const playVoice = (audioFile) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    audioRef.current = new Audio(audioFile);
    audioRef.current.play();
    setIsPaused(false);
  };

  const pauseVoice = () => {
    if (audioRef.current && !isPaused) {
      audioRef.current.pause();
      setIsPaused(true);
    }
  };

  const resumeVoice = () => {
    if (audioRef.current && isPaused) {
      audioRef.current.play();
      setIsPaused(false);
    }
  };

  const stopVoice = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  const buttonDataColumns = [
    [
      { label: "Intro", color: "#b75c30", audioSrc: intro },
      { label: "Greetings-1", color: "#b75c30", audioSrc: greetings1 },
      { label: "Plan + Age", color: "#b75c30", audioSrc: planAge },
      { label: "Name", color: "#b75c30", audioSrc: name },
      { label: "Plan-2", color: "#b75c30", audioSrc: plan2 },
      { label: "Age", color: "#b75c30", audioSrc: age },
      { label: "DOB", color: "#b75c30", audioSrc: dob },
      { label: "State", color: "#b75c30", audioSrc: state },
      { label: "Health", color: "#b75c30", audioSrc: health },
      { label: "Banking", color: "#b75c30", audioSrc: banking },
      { label: "Transfer", color: "#b75c30", audioSrc: transfer },
      { label: "Disclaimer-1", color: "#b75c30", audioSrc: disclaimer1 },
      { label: "Disclaimer-2", color: "#b75c30", audioSrc: disclaimer2 },
      { label: "Disclaim Rebut", color: "#b75c30", audioSrc: disclaimRebut },
      { label: "Follow Up", color: "#b75c30", audioSrc: followUp },
      { label: "Whole Life Plcy", color: "#b75c30", audioSrc: wholeLifePlcy },
    ],
    [
      { label: "Doing Great", color: "#226465", audioSrc: doingGreat },
      { label: "Glad to hear", color: "#226465", audioSrc: gladToHear },
      { label: "Oh Sorry", color: "#226465", audioSrc: ohSorry },
      { label: "Fantastic", color: "#226465", audioSrc: fantastic },
      { label: "Great", color: "#226465", audioSrc: great },
      { label: "Yes", color: "#226465", audioSrc: yes },
      { label: "No", color: "#226465", audioSrc: no },
      { label: "Okay", color: "#226465", audioSrc: okay },
      { label: "No problem", color: "#226465", audioSrc: noProblem },
      { label: "Hold for me", color: "#226465", audioSrc: holdForMe },
      { label: "Transfer Detail", color: "#226465", audioSrc: transferDetail },
      { label: "Cost", color: "#226465", audioSrc: cost },
      { label: "Cost-2", color: "#226465", audioSrc: cost2 },
      { label: "Rate Above 70+", color: "#226465", audioSrc: rateAbove70 },
      { label: "Rate Below 70-", color: "#226465", audioSrc: rateBelow70 },
      { label: "Patience", color: "#226465", audioSrc: patience },
    ],
    [
      { label: "Are you there?", color: "#333d45", audioSrc: areYouThere },
      { label: "Anyone online?", color: "#333d45", audioSrc: anyoneOnline },
      { label: "Is that okay?", color: "#333d45", audioSrc: isThatOkay },
      { label: "Hear me?", color: "#333d45", audioSrc: hearMe },
      { label: "What was that?", color: "#333d45", audioSrc: whatWasThat },
      { label: "Have Coverage?", color: "#333d45", audioSrc: haveCoverage },
      { label: "Add Coverage?", color: "#333d45", audioSrc: addCoverage },
      { label: "Call Back?", color: "#333d45", audioSrc: callBack },
      { label: "Zip Code?", color: "#333d45", audioSrc: zipCode },
      { label: "Location", color: "#333d45", audioSrc: location },
      { label: "Agent's Name", color: "#333d45", audioSrc: agentsName },
      { label: "Company's Name", color: "#333d45", audioSrc: companyName },
      { label: "Is that a yes?", color: "#333d45", audioSrc: isThatAYes },
      { label: "Hw Mch Coverage", color: "#333d45", audioSrc: howMuchCoverage },
      { label: "Hw Mch Cover-2", color: "#333d45", audioSrc: howMuchCover2 },
      { label: "Call you back", color: "#333d45", audioSrc: callYouBack },
    ],
    [
      { label: "Not Interested-1", color: "#08a472", audioSrc: notInterested1 },
      { label: "Not Interested-2", color: "#08a472", audioSrc: notInterested2 },
      { label: "Busy-1", color: "#08a472", audioSrc: busy1 },
      { label: "Busy-2", color: "#08a472", audioSrc: busy2 },
      { label: "Busy-3", color: "#08a472", audioSrc: busy3 },
      { label: "Bank Rebuttal", color: "#08a472", audioSrc: bankRebuttal },
      { label: "Health Duration", color: "#08a472", audioSrc: healthDuration },
      { label: "Debit Card Link", color: "#08a472", audioSrc: debitCardLink },
      { label: "Send in mail", color: "#08a472", audioSrc: sendInMail },
      { label: "DNC", color: "red", audioSrc: dnc },
      { label: "DOB Rebuttal", color: "#08a472", audioSrc: dobRebuttal },
      { label: "Weather", color: "#08a472", audioSrc: weather },
      { label: "Corona", color: "#08a472", audioSrc: corona },
      { label: "Tobacco", color: "#08a472", audioSrc: tobacco },
      { label: "Direc Expr Card", color: "#08a472", audioSrc: direcExprCard },
      { label: "Real Person", color: "#08a472", audioSrc: realPerson },
    ],
  ];

  return (
    <div className="outerdiv">
      {/* <div style={{ zIndex: 2 }}>
        <VoiceBar />
      </div> */}
      <div className="avatar-voice-container">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <button
            className="stop-button"
            onClick={resumeVoice}
            style={{ backgroundColor: "green" }}
          >
            RESUME
          </button>
          <button className="stop-button" onClick={pauseVoice}>
            PAUSE
          </button>
          <button
            className="stop-button"
            onClick={stopVoice}
            style={{ backgroundColor: "red" }}
          >
            STOP
          </button>
        </div>

        <div className="button-grid">
          {buttonDataColumns.map((column, colIndex) => (
            <div key={colIndex} className="button-column">
              {column.map((button, index) => (
                <button
                  key={index}
                  className="voice-button"
                  style={{ backgroundColor: button.color, fontSize:'small'}}
                  onClick={() => playVoice(button.audioSrc)}
                >
                  {button.label}
                </button>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AvatarVoice;
