import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import TeamManagementLinks from './TeamManagementLinks';
import './AgentsReport.css';

const generateDummyData = () => {
  // Generate dummy agent data with 30 days of random numbers
  const agents = [
    { name: 'Usama Maqoddar' },
    { name: 'Ayaaz ul Haq' },
    { name: 'Adnan Ismail' },
    { name: 'Ali' },
    { name: 'Suliman Safi' },
    { name: 'Muhammad Altamash' },
    { name: 'ASAD ULLAH' },
    { name: 'Hammad Khan' },
    { name: 'Naveed Ahmed' },
    { name: 'Asad Bahadur' },
  ];

  return agents.map(agent => ({
    name: agent.name,
    dates: Array.from({ length: 30 }, () => Math.floor(Math.random() * 10)), // Random data between 0 and 9
  }));
};

const generateDateHeaders = () => {
  // Generate the last 30 days as headers
  const dates = [];
  const today = new Date();

  for (let i = 0; i < 30; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    dates.unshift(date.toISOString().split('T')[0]); // Format as YYYY-MM-DD
  }

  return dates;
};

const AgentsReport = () => {
  const [agentsData, setAgentsData] = useState([]);
  const [dateHeaders, setDateHeaders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);

  useEffect(() => {
    setAgentsData(generateDummyData());
    setDateHeaders(generateDateHeaders());
  }, []);

  // Filtered data based on search term
  const filteredData = agentsData.filter(agent =>
    agent.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handleEntriesChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when entries per page changes
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <Navbar />
      <TeamManagementLinks />
      <div className="agents-report-container">
        <h2>Agents Report</h2>
        <div className="controls">
          <div>
            <label style={{width:150}}>
              <select value={entriesPerPage} onChange={handleEntriesChange}>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </select>
              entries per page
            </label>
          </div>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div className="table-container">
          <table className="responsive-table">
            <thead>
              <tr>
                <th>Agent Name</th>
                {dateHeaders.map((date, index) => (
                  <th key={index}>{date}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentEntries.map((agent, index) => (
                <tr key={index}>
                  <td>{agent.name}</td>
                  {agent.dates.map((value, i) => (
                    <td key={i}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(number => (
            <button
              key={number}
              onClick={() => handlePageChange(number)}
              className={number === currentPage ? 'active' : ''}
            >
              {number}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AgentsReport;
