import React, { useRef, useState } from 'react';
import './Selvey.css';

import Hello from './Sylvie Wills 101/Hello.mp3';
import Greetings from './Sylvie Wills 101/Greetings.mp3';
import Pitch1 from './Sylvie Wills 101/Pitch-1.mp3';
import Pitch2 from './Sylvie Wills 101/Pitch-2.mp3';
import CallBackPitch from './Sylvie Wills 101/Call_back Pitch.mp3';
import Age1 from './Sylvie Wills 101/Age-1.mp3';
import Age2 from './Sylvie Wills 101/Age-2.mp3';
import Plan from './Sylvie Wills 101/Plan.mp3';
import PlanDetails from './Sylvie Wills 101/Plan_Details.mp3';
import Beneficiary from './Sylvie Wills 101/Beneficiary.mp3';
import Health from './Sylvie Wills 101/Health.mp3';
import Smoker from './Sylvie Wills 101/Smoker.mp3';
import TransferDetail1 from './Sylvie Wills 101/Transfer_Detail-1.mp3';
import ThanksForAsking from './Sylvie Wills 101/Thanks_for_asking.mp3';
import GladToHear from './Sylvie Wills 101/Glad_to_hear.mp3';
import SorryToHear from './Sylvie Wills 101/Sorry_to_hear.mp3';
import Okay from './Sylvie Wills 101/Okay.mp3';
import Great from './Sylvie Wills 101/Great.mp3';
import Yes from './Sylvie Wills 101/Yes.mp3';
import No from './Sylvie Wills 101/No.mp3';
import NoProblem from './Sylvie Wills 101/No_problem.mp3';
import HearMe from './Sylvie Wills 101/hear_me.mp3';
import Repeat from './Sylvie Wills 101/Repeat.mp3';
import CanISaySomething from './Sylvie Wills 101/Can_i_say_something.mp3';
import IsThatOkay from './Sylvie Wills 101/Is_that_okay.mp3';
import TransferHoldButton from './Sylvie Wills 101/Transfer_hold.mp3';
import Weather from './Sylvie Wills 101/Weather.mp3';
import CallAbout from './Sylvie Wills 101/Call_about.mp3';
import Cost from './Sylvie Wills 101/Cost.mp3';
import AgeRebuttal from './Sylvie Wills 101/Age_rebuttal.mp3';
import NotInterested1 from './Sylvie Wills 101/Not_Interested-1.mp3';
import NotInterested2 from './Sylvie Wills 101/Not_interested-2.mp3';
import AlreadyHavePlan1 from './Sylvie Wills 101/Already_have_plan-1.mp3';
import AlreadyHavePlan2 from './Sylvie Wills 101/Already_have_plan-2.mp3';
import Affordability from './Sylvie Wills 101/Affordability.mp3';
import Discount from './Sylvie Wills 101/Discount.mp3';
import SmokeDiscount from './Sylvie Wills 101/Smoke Discount.mp3';
import AccountDiscount from './Sylvie Wills 101/Account_Discount.mp3';
import AddCoverage from './Sylvie Wills 101/Add_coverage.mp3';
import HealthRebuttal from './Sylvie Wills 101/Health_Rebuttal.mp3';
import DNC from './Sylvie Wills 101/DNC.mp3';
import Location from './Sylvie Wills 101/Location.mp3';
import AgentsName from './Sylvie Wills 101/Agents Name.mp3';
import CompanyName from './Sylvie Wills 101/Company_Name.mp3';
import InfoForm from './Sylvie Wills 101/Info_Form.mp3';
import RealPerson from './Sylvie Wills 101/Real_Person.mp3';
import Rescue from './Sylvie Wills 101/Rescue.mp3';
import Busy from './Sylvie Wills 101/Busy.mp3';
import CalledBefore from './Sylvie Wills 101/Called_Before.mp3';
import SetCallBack from './Sylvie Wills 101/Set_Call_back.mp3';
import FewSeconds from './Sylvie Wills 101/Few_seconds.mp3';
import SendInMail from './Sylvie Wills 101/Send_in_mail.mp3';
import Laugh from './Sylvie Wills 101/Laugh.mp3';
import Goodbye from './Sylvie Wills 101/Good_bye.mp3';
import HoldForMe from './Sylvie Wills 101/Hold_for_me.mp3'
import trnsfrdtl2 from './Sylvie Wills 101/Transfer_Detail-2.mp3'


const Selvey = () => {
  const [isPaused, setIsPaused] = useState(false);
  const audioRef = useRef(new Audio());

  const playVoice = (audioFile) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    audioRef.current = new Audio(audioFile);
    audioRef.current.play();
    setIsPaused(false);
  };

  const pauseVoice = () => {
    if (audioRef.current && !isPaused) {
      audioRef.current.pause();
      setIsPaused(true);
    }
  };

  const resumeVoice = () => {
    if (audioRef.current && isPaused) {
      audioRef.current.play();
      setIsPaused(false);
    }
  };

  const stopVoice = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  const buttonDataColumns = [
    [
      { label: 'Hello', color: '#222e47', audioSrc: Hello },
      { label: 'Greetings', color: '#222e47', audioSrc: Greetings },
      { label: 'Pitch 1', color: '#222e47', audioSrc: Pitch1 },
      { label: 'Pitch 2', color: '#222e47', audioSrc: Pitch2 },
      { label: 'Call Back Pitch', color: '#222e47', audioSrc: CallBackPitch },
      { label: 'Age-1', color: '#222e47', audioSrc: Age1 },
      { label: 'Age-2', color: '#222e47', audioSrc: Age2 },
      { label: 'Plan', color: '#222e47', audioSrc: Plan },
      { label: 'Plan Details', color: '#222e47', audioSrc: PlanDetails },
      { label: 'Beneficiary', color: '#222e47', audioSrc: Beneficiary },
      { label: 'Health', color: '#222e47', audioSrc: Health },
      { label: 'Smoker', color: '#222e47', audioSrc: Smoker },
      { label: 'Transfer Detail-1', color: '#222e47', audioSrc: TransferDetail1 },
      { label: 'Transfer Detail-2', color: '#222e47', audioSrc: trnsfrdtl2 },
    ],
    [
      { label: 'Thanks 4 Asking', color: '#226465', audioSrc: ThanksForAsking },
      { label: 'Glad to hear', color: '#226465', audioSrc: GladToHear },
      { label: 'Sorry to Hear', color: '#226465', audioSrc: SorryToHear },
      { label: 'Okay', color: '#226465', audioSrc: Okay },
      { label: 'Great', color: '#226465', audioSrc: Great },
      { label: 'Yes', color: '#226465', audioSrc: Yes },
      { label: 'No', color: '#226465', audioSrc: No },
      { label: 'No Problem', color: '#226465', audioSrc: NoProblem },
      { label: 'Hear Me?', color: '#226465', audioSrc: HearMe },
      { label: 'Repeat', color: '#226465', audioSrc: Repeat },
      { label: 'Can I say smthn', color: '#226465', audioSrc: CanISaySomething },
      { label: 'Is that okay?', color: '#226465', audioSrc: IsThatOkay },
      { label: 'Transfer Hold', color: '#226465', audioSrc: TransferHoldButton },
      { label: 'Weather', color: '#226465', audioSrc: Weather },
    ],
    [
      { label: 'Call About', color: '#333d45', audioSrc: CallAbout },
      { label: 'Cost', color: '#333d45', audioSrc: Cost },
      { label: 'Age Rebuttal', color: '#333d45', audioSrc: AgeRebuttal },
      { label: 'Not Interested 1', color: '#333d45', audioSrc: NotInterested1 },
      { label: 'Not Interested 2', color: '#333d45', audioSrc: NotInterested2 },
      { label: 'Alrdy Hv Plan-1', color: '#333d45', audioSrc: AlreadyHavePlan1 },
      { label: 'Alrdy Hv Plan-2', color: '#333d45', audioSrc: AlreadyHavePlan2 },
      { label: 'Affordability', color: '#333d45', audioSrc: Affordability },
      { label: 'Discount', color: '#333d45', audioSrc: Discount },
      { label: 'Smoke Discount', color: '#333d45', audioSrc: SmokeDiscount },
      { label: 'Account Discount', color: '#333d45', audioSrc: AccountDiscount },
      { label: 'Add Coverage', color: '#333d45', audioSrc: AddCoverage },
      { label: 'Health Rebuttal', color: '#333d45', audioSrc: HealthRebuttal },
      { label: 'DNC', color: 'red', audioSrc: DNC },
    ],
    [
      { label: 'Location', color: '#08a472', audioSrc: Location },
      { label: 'Agent\'s Name', color: '#08a472', audioSrc: AgentsName },
      { label: 'Company\'s Name', color: '#08a472', audioSrc: CompanyName },
      { label: 'Info From', color: '#08a472', audioSrc: InfoForm },
      { label: 'Real Person', color: '#08a472', audioSrc: RealPerson },
      { label: 'Rescue', color: '#08a472', audioSrc: Rescue },
      { label: 'Busy', color: '#08a472', audioSrc: Busy },
      { label: 'Called Before', color: '#08a472', audioSrc: CalledBefore },
      { label: 'Set Call Back?', color: '#08a472', audioSrc: SetCallBack },
      { label: 'Few Seconds', color: '#08a472', audioSrc: FewSeconds },
      { label: 'Send In Mail', color: '#08a472', audioSrc: SendInMail },
      { label: 'Hold for me', color: '#08a472', audioSrc: HoldForMe },
      { label: 'Laugh', color: '#08a472', audioSrc: Laugh },
      { label: 'Goodbye', color: '#08a472', audioSrc: Goodbye },
    ],
  ];

  return (
      <div className="outerdiv">
        <div style={{zIndex:2}}>
      <div className="avatar-voice-container">
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
          <button className="stop-button" onClick={resumeVoice} style={{ backgroundColor: 'green' }}>
            RESUME
          </button>
          <button className="stop-button" onClick={pauseVoice}>
            PAUSE
          </button>
          <button className="stop-button" onClick={stopVoice} style={{ backgroundColor: 'red' }}>
            STOP
          </button>
        </div>

        <div className="button-grid">
          {buttonDataColumns.map((column, colIndex) => (
            <div key={colIndex} className="button-column">
              {column.map((button, index) => (
                <button
                  key={index}
                  className="voice-button"
                  style={{ backgroundColor: button.color }}
                  onClick={() => playVoice(button.audioSrc)}
                >
                  {button.label}
                </button>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
};

export default Selvey;
